export default function FormCard(props) {
  const cardClass = `relative card border border-emerald-400 shadow-md ${props.className}`;
  let paddingSize = "bg-white px-10 py-8";
  let h3Class = "text-xl font-semibold leading-none text-gray-900 mb-6"
  if (props.small) {
    paddingSize = "bg-white px-6 py-4";
    h3Class = "text-xl font-semibold leading-none text-gray-900 mb-3"
  }
  return (
    <div className={cardClass}>
      {(props.loading) ? (<div className="absolute top-0 left-0 right-0">
        <div className="barLoader"></div>
      </div>) : (<></>)}
      <div className={paddingSize}>
        <h3 className={h3Class}>
          {props.cardName}
        </h3>
        {props.children}
      </div>
    </div>
  )
}