import { ChatBubbleOvalLeftEllipsisIcon, ClipboardDocumentListIcon, TrashIcon } from "@heroicons/react/24/solid";
import React from "react";

function CropDetails(props) {
  const { cropTask } = props;
  return (
    <div>
      <p>The crop is here</p>
    </div>
  )
}
function CropToolItems(props) {
  const { cropTask } = props;
  return (
    <div
      className="absolute top-0 right-0 translate-x-full px-2 py-0.5"
    >
      <div className="flex items-center justify-end gap-2">
        <button><TrashIcon className="w-4 h-4 text-blue-500" /></button>
        <button><ChatBubbleOvalLeftEllipsisIcon className="w-4 h-4 text-blue-500" /></button>
        <button><ClipboardDocumentListIcon className="w-4 h-4 text-blue-500" /></button>
      </div>
    </div>
  )
}

function CropCell(props) {
  const { row, cropTask, cropIndex, rowIndex, currentY } = props;
  return (
    <div
      datatype="CROP_CELL"
      dataid={cropTask.crop}
      dataindex={cropIndex}
      datarow={rowIndex}
      className={`absolute cursor-pointer`}
      style={{
        height: `${row.height}px`,
        width: `${cropTask.transform.width}px`,
        transform: `translate(${cropTask.transform.x}px, ${currentY}px)`,
        pointerEvents: 'all'
      }}
    >
      <div
        datatype="CROP_CELL"
        dataid={cropTask.crop}
        dataindex={cropIndex}
        datarow={rowIndex}
        className={`group absolute top-0 left-0 whitespace-nowrap hover:${cropTask.colours.hover} ${cropTask.colours.bg} text-white rounded-sm cursor-pointer`}
        style={{
          height: `22px`,
          width: `${cropTask.transform.width}px`,
          pointerEvents: 'all'
        }}
      >
        <div 
          className="group-hover:opacity-100 opacity-0 absolute w-1 h-1/2 top-1/4 cursor-ew-resize border-solid border left-1 border-white border-b-0 border-t-0 z-20"
          datatype="CROP_CELL_LEFT"
          dataid={cropTask.crop} 
          dataindex={cropIndex}
          datarow={rowIndex}
        />
        <div 
          className="absolute flex h-full top-0 items-center justify-start text-sm pl-4"
          datatype="CROP_CELL"
          dataid={cropTask.crop} 
          dataindex={cropIndex}
          datarow={rowIndex}
        >
          {cropTask.title}
        </div>
        <div
          className="group-hover:opacity-100 opacity-0 absolute w-1 h-1/2 top-1/4 cursor-ew-resize border-solid border right-1 border-white border-b-0 border-t-0 z-20"
          datatype="CROP_CELL_RIGHT"
          dataid={cropTask.crop} 
          dataindex={cropIndex}
          datarow={rowIndex}
        />
      </div>
      {row.open ? (<CropDetails cropTask={cropTask} />) : (<></>)}
      {row.open ? (<CropToolItems cropTask={cropTask} />) : (<></>)}
    </div>
  )
}

function TableRows(props) {
  const { rows, croppingLocations } = props;
  return rows.map((row, i) => {
    let bed = croppingLocations[row.bed];
    let bedName = `${bed.parent.name}${bed.name}`;
    let border = "border-b border-b-gray-300";
    if (row.streams > 1 && row.stream !== 0) {
      bedName = '';
    } else if (row.streams > 1 && row.stream <= row.streams) {
      border = "";
    }
    return (
      <div 
        className="flex min-w-full items-stretch whitespace-nowrap" 
        key={bed._id + "_" + row.stream}
        style={{height:`${row.height}px`}}
      >
        <div className={`flex-1 ${row.hovered ? "bg-emerald-300" : "bg-slate-100"} ${border} whitespace-nowrap overflow-hidden flex items-center px-2 h-full w-[140px] shrink-0`}>
          <div className="flex font-bold whitespace-nowrap h-full text-sm">{bedName}</div>
        </div>
      </div>
    )
  })
}

function Highlights(props) {
  const { rows, timelineHeight } = props;
  const h = rows.map((row, i) => {
    let border = "border-b border-b-gray-100";
    if (row.streams > 1 && row.stream < row.streams -1) {
      border = "";
    }
    return (
      <div 
        className={`w-full ${border} relative ${row.hovered ? "bg-emerald-300" : ""}`} 
        data-row={i}
        key={`TIMELINE_ROW_HOVER_${i}`}
        style={{height:`${row.height}px`}}
      />);
  })

  return (
    <div className="w-full overflow-hidden" style={{height:`${timelineHeight}px`}}>
      <div style={{transform: "translateY(0px)"}}>
        {h}
      </div>
    </div>
  )
}

function TopHeaderLabels(props) {
  const { calendar, timelineWidth } = props;
  return calendar.map((month, i) => {
    let width = Math.floor(timelineWidth / 52) * month.length;
    return (
      <div 
        key={`HEADER_TOP_LABEL_${i}`}
        style={{width : `${width}px`}}
        className="h-full text-center inline-flex justify-center items-center cursor-pointer bg-gray-200 border-b border-r border-b-gray-400 border-r-gray-400"
      >
        <div className="sticky left-4 text-center text-xs font-bold">{month[0].date.format('MMM YYYY')}</div>
      </div>
    )
  });
}

function WeekHeaderLabels(props) {
  const { calendar, timelineWidth } = props;
  return calendar.map((month, i) => {
    let width = Math.floor(timelineWidth / 52);
    return month.map(w => {
      return (<div 
        key={`HEADER_WEEK_LABEL_${w.week}`}
        style={{width : `${width}px`}}
        className="h-full text-center inline-flex justify-center items-center cursor-pointer bg-gray-100 border-b border-r border-b-gray-300 border-r-gray-300"
      >
        <div className="sticky left-4 text-center text-xs">{w.week}</div>
      </div>);
    });
  });
}

function MakePTags() {
  let tags = [];
  let colours = ['red', 'orange','amber','yellow','lime','green','emerald','teal','cyan','sky','blue','indigo','violet','purple','fuchsia','pink','rose'];
  let lightShade = true;
  let colourIndex = 0;
  for (let i=0;i<34;i++) {
    if (!colours[colourIndex]) {
      lightShade = !lightShade;
      colourIndex = 0;
    }
    let b = `<p className="bg-${colours[colourIndex]}-${lightShade ? "400" : "600"} hover:bg-${colours[colourIndex]}-${lightShade ? "300" : "500"}"></p>`;
    tags.push(b);
    colourIndex++;
  }
  let text = tags.join("\r\n");
  console.log(text);
}

export { CropCell, TableRows, Highlights, TopHeaderLabels, WeekHeaderLabels, MakePTags };