import React, { useState, useEffect } from "react";
import { useFarmStore } from "../../dataStore/FarmStore";
import FormCard from "../../components/formElements/formCard";
import { FormField, FormSelect } from "../../components/formElements/formField";

export default function SalesWholesalePage() {
  const farm = useFarmStore((state) => state.farm);
  const updateFarm = useFarmStore((state) => state.updateFarm);
  const saveFarm = useFarmStore((state) => state.saveFarm);
  const [view, setView] = useState("ORDERS");

  function orderView() {
    return (<p>orders</p>)
  }

  function accountsView() {
    return (<p>accounts</p>)
  }

  function settingsView() {
    return (<p>settings</p>)
  }

  function getView() {
    switch (view) {
      case "ORDERS":
        return orderView();
      case "ACCOUNTS":
        return accountsView();
      case "SETTINGS":
        return settingsView();
      default:
        return orderView();
    }
  }

  return (
    <div className="absolute inset-0">
      <div className="grid grid-rows-planning-contain h-full">
        <div className="border-b-black border border-b-solid">
          <div className="flex gap-4 align-middle">
            <div className="px-3 py-2">
              <h1 className="text-3xl text-blue-600">Sales - Wholesale</h1>
            </div>
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("ORDERS")}}
              >
                Fresh list/Orders
              </button>
            </div>
            
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("ACCOUNTS")}}
              >
                Accounts
              </button>
            </div>
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("SETTINGS")}}
              >
                Settings & Accounting
              </button>
            </div>
          </div>
        </div>
        <div className="bg-transparent relative px-12 py-8 overflow-scroll">
          {getView()}
        </div>
      </div>
    </div>
  );
}
