import React, { useState, useEffect, useRef } from "react";
import { useCropProfileStore } from "../../dataStore/ProfileStore";
import { useCroppingLocationStore } from "../../dataStore/CroppingLocationStore";
import { useCropStore } from "../../dataStore/CropStore";
import NewCropModal from "../../components/planning/newCropModal";
import { CropCell, TableRows, Highlights, TopHeaderLabels, WeekHeaderLabels, MakePTags } from "./planningComponents";
import dayjs from "dayjs";
import WeekOfYear from "dayjs/plugin/weekOfYear";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import _, { first } from "lodash";
dayjs.extend(WeekOfYear);

export default function PlanningPage() {
  //UI loading states
  const [loaded, setLoaded] = useState(false);
  const [tasksDone, setTasksDone] = useState(false);
  
  //DataStore States
  const profiles = useCropProfileStore((state) => state.profiles);
  const loadProfiles = useCropProfileStore((state) => state.fetch);
  const croppingLocations = useCroppingLocationStore((state) => state.croppingLocations);
  const loadLocations = useCroppingLocationStore((state) => state.fetch);
  const loadCrops = useCropStore((state) => state.fetch);
  const insertCrop = useCropStore((state) => state.insert);
  const crops = useCropStore((state) => state.objects);
  //UI refs
  const headerScroller = useRef();
  const tableScroller = useRef();
  const timelineBody = useRef();
  //Timeline Config
  const [timelineWidth, setTimelineWidth] = useState(2000);
  const [timelineHeight, setTimelineHeight] = useState(0);
  const [calendar, setCalendar] = useState([]);
  const [firstWeek, setFirstWeek] = useState(false);
  const [timelineRange, setTimelineRange] = useState([]);
  
  const [rows, setRows] = useState([]);
  const [rowIndexes, setRowIndexes] = useState(null);
  const [cropTasks, setCropTasks] = useState([]);
  const [profileColours, setProfileColours] = useState(null);
  const [editingTask, setEditingTask] = useState(null);
  const [newTask, setNewTask] = useState(null);
  const [selectedCrop, setSelectedCrop] = useState(false);

  const [addCrop, setAddCrop] = useState(false);

  const [dragging, setDragging] = useState(false);
  const [moveState, setMoveState] = useState({
    type : '',
    crop : '',
    startTransform: {x:0, y:0},
    offsetTransform: {x:0, y:0}
  });

  //LOAD ALL DATA EFFECT
  useEffect(() => {
    async function fetchData() {
      await loadLocations()
      await loadProfiles()
      await loadCrops()
      setLoaded(true)
    }
    if (!loaded) fetchData();
  }, [loaded])

  useEffect(() => {
    if (!loaded || rowIndexes) return;
    let _indexes = {};
    let _rows = [];
    let idx = 0;
    for (let [id, location] of Object.entries(croppingLocations)) {
      if (location.locationType === "BED") {
        _indexes[id] = idx;
        _rows.push({
          hovered: false,
          open: false,
          height: 22,
          streams: 1,
          stream: 0,
          bed: id,
          crops: []
        })
        idx++;
      }
    }

    let profileColours = {};
    let colours = ['red', 'orange','amber','yellow','lime','green','emerald','teal','cyan','sky','blue','indigo','violet','purple','fuchsia','pink','rose'];
    let lightShade = true;
    let colourIndex = 0;
    for (let [id, profile] of Object.entries(profiles)) {
      if (!colours[colourIndex]) {
        lightShade = !lightShade;
        colourIndex = 0;
      }
      let b = `bg-${colours[colourIndex]}-${lightShade ? "400" : "600"}`;
      let h = `bg-${colours[colourIndex]}-${lightShade ? "300" : "500"}`;
      profileColours[id] = {bg : b, hover : h};
      colourIndex++;
    }
    setProfileColours(profileColours);
    setTimelineHeight(_rows.length * 22);
    setRowIndexes(_indexes);
    setRows(_rows);
    calculateCalendar();
  }, [loaded, croppingLocations]);

  useEffect(() => {
    if (rowIndexes && firstWeek && !tasksDone) {
      calculateCrops(rowIndexes);
      setTasksDone(true);
    }
  }, [rowIndexes, firstWeek, tasksDone]);

  function calculateCalendar() {
    let d = dayjs().subtract(8, 'week');
    let monday = null;
    while (!monday) {
      if (d.day() === 1) {monday = d;break;}
      d = d.add(1, 'day');
    }
    setFirstWeek(monday.week());
    let cal = {};
    for (let i=0;i<52;i++) {
      let m = d.format("MM YY");
      let w = d.week();
      if (cal[m]) cal[m].push({week: w, date: d});
      else cal[m] = [{week: w, date: d}];
      d = d.add(1, 'week');
    }
    let _c = [];
    for (let [index, weeks] of Object.entries(cal)) _c.push(weeks);
    setCalendar(_c);
  }

  function calculateCrops(_indexes) {
    let nextRows = rows.slice();
    let weekWidth = Math.floor(timelineWidth / 52);
    for (let [id, crop] of Object.entries(crops)) {
      let rowIndex = _indexes[crop.location._id];
      let start = dayjs(crop.timestamps.startedOn);
      let end = dayjs(crop.timestamps.endDate);
      let startWeek = start.week();
      let endWeek = end.week();
      let weeks = end.diff(start, "weeks");
      let taskObj = {
        crop : crop._id,
        title : `${crop.species.commonName}   ${crop.profile.variationName}`,
        rowIndex : rowIndex,
        stream: 0,
        colours : profileColours[crop.profile._id],
        transform : {
          x : (startWeek-firstWeek) * weekWidth,
          y : rowIndex * 22,
          width : (weeks)*weekWidth
        },
        dateStart : start,
        dateEnd : end,
        weekStart : startWeek,
        weekEnd : endWeek,
        weeks : weeks
      }

      nextRows[rowIndex].crops.push(taskObj);  
    }
    setRows(nextRows);
  }

  function cellMouseDown(e) {
    e.preventDefault();
    e.stopPropagation();

    let id = e.target.attributes.getNamedItem('dataid')?.value;
    let index = e.target.attributes.getNamedItem('dataindex')?.value;
    let rowIndex = e.target.attributes.getNamedItem('datarow')?.value;
    if (!id) return;
    let task = rows[rowIndex].crops[index];

    if (e.altKey) {
      let nextRows = rows.slice();
      let newCrop = JSON.parse(JSON.stringify(crops[task.crop]));
      newCrop._id = `${task.crop}_CLONE`;
      newCrop.id = `${task.crop}_CLONE`;
      insertCrop(newCrop._id, newCrop);
      let newTask = JSON.parse(JSON.stringify(task));
      newTask.crop = newCrop._id;
      nextRows[rowIndex].crops.push(newTask);
      setRows(nextRows);
      setMoveState({
        type : "CROP_CELL",
        crop : newCrop._id,
        index : nextRows[rowIndex].crops.length-1,
        rowIndex: rowIndex,
        startTransform: {x:task.transform.x, y:task.transform.y},
        offsetTransform: {x:e.clientX, y:e.clientY}
      });
    } else {
      setMoveState({
        type : "CROP_CELL",
        crop : id,
        index : index,
        rowIndex : rowIndex,
        startTransform: {x:task.transform.x, y:task.transform.y},
        offsetTransform: {x:e.clientX, y:e.clientY}
      });
    }
  }

  function cellMouseUp(e) {
    let _m = moveState;

    let nextRows = rows.slice();
    let row = rows[moveState.rowIndex];
    let movedCrop = row.crops[moveState.index];
    let y = RowIndextoY(moveState.rowIndex);
    nextRows[moveState.rowIndex].crops[moveState.index].transform.y = y;
    let overlapped = false;

    row.crops.forEach(crop => {
      if (crop.crop !== movedCrop.crop) {
        if (Math.abs(crop.transform.x - movedCrop.transform.x) < crop.transform.width) {
          overlapped = true;
        }
      }
    })

    if (overlapped) {
      if (row.streams === 1) {
        //add a new stream and move the crop
        nextRows[moveState.rowIndex].crops.splice(moveState.index, 1);
        nextRows[moveState.rowIndex].streams = 2;
        nextRows = [
          ...nextRows.slice(0, moveState.rowIndex+1),
          {
            hovered: false,
            open: false,
            height: 22,
            streams: 2,
            stream: 1,
            bed: row.bed,
            crops: [movedCrop]
          },
          ...nextRows.slice(moveState.rowIndex+1, nextRows.length)
        ]
        
      } else {
        
      }
    }

    //clear any empty streams

    setRows(nextRows);
    setMoveState({type : "", crop: null, startTransform: {x:0,y:0}});
  }

  function YtoRowIndex(y) {
    let rI = -1;
    let cY = 0;
    rows.forEach((row, i) => {
      if (y >= cY && y <= cY + row.height) {
        rI = i;
      }

      cY += row.height;
    })
    return Math.min(Math.max(rI, 0), rows.length);
  }
  function RowIndextoY(index) {
    let cY = 0;
    for (let i=0;i!==index;i++) {
      cY += rows[index].height;
    }
    console.log(cY);
    return cY;
  }

  function cellMouseMove(e) {
    let nextRows = toggleCropCells(null);
    if (!nextRows) nextRows = rows.slice();
    let _m = moveState;
    let rowIndex = _m.rowIndex;
    let cropIndex = _m.index;
    let nX = _m.startTransform.x - (_m.offsetTransform.x - e.clientX);
    let nY = _m.startTransform.y - (_m.offsetTransform.y - e.clientY);

    let row = YtoRowIndex(nY);
    let week = Math.round(nX / Math.floor(timelineWidth / 52));
    let x = week * Math.floor(timelineWidth / 52);
    week = week + firstWeek;

    let rowEdit = nextRows[rowIndex];
    let cropEdit = rowEdit.crops[cropIndex];
    cropEdit.dateStart = dayjs().set('week', week);
    cropEdit.dateEnd = dayjs().set('week', week).add(cropEdit.weeks);
    cropEdit.weekStart = week;
    cropEdit.weekEnd = week + cropEdit.weeks;
    cropEdit.rowIndex = row;
    cropEdit.transform.x = x;

    if (rowIndex !== row) {
      nextRows[row].crops.push(cropEdit);
      rowEdit.crops.splice(cropIndex, 1);
      setMoveState({
        ...moveState,
        index : nextRows[row].crops.length-1,
        rowIndex : row
      });
    } else {
      rowEdit.crops[cropIndex] = cropEdit;
    }
    nextRows[rowIndex] = rowEdit;
    if (!dragging) setDragging(true);
    setRows(nextRows);
  }

  function cellSizeMouseDown(e, direction) {
    e.preventDefault();
    e.stopPropagation();

    let id = e.target.attributes.getNamedItem('dataid')?.value;
    let index = e.target.attributes.getNamedItem('dataindex')?.value;
    let rowIndex = e.target.attributes.getNamedItem('datarow')?.value;
    if (!id) return;
    let task = rows[rowIndex].crops[index];

    setMoveState({
      type : "CROP_CELL_MOVE",
      direction : direction,
      crop : id,
      index : index,
      rowIndex : rowIndex,
      startTransform: {x:task.transform.x, y:task.transform.y,width:task.transform.width},
      offsetTransform: {x:e.clientX, y:e.clientY}
    });
  }

  function cellSizeMouseMove(e) {
    let _m = moveState;
    let edit = rows[moveState.rowIndex].crops[moveState.index];
    let nextRows = rows.slice();
    let weekWidth = Math.floor(timelineWidth / 52);

    let nX = _m.startTransform.x - (_m.offsetTransform.x - e.clientX);
    if (_m.direction === "RIGHT")
      nX = _m.startTransform.x + _m.startTransform.width - (_m.offsetTransform.x - e.clientX);
    let nW = Math.round(nX / weekWidth) + firstWeek;
    if (_m.direction === "LEFT") {
      edit.transform.x = (nW-firstWeek) * weekWidth;
      edit.transform.width = (edit.weekEnd - nW)*weekWidth;
      edit.startWeek = nW;
    } else {
      edit.transform.width = (nW - edit.weekStart)*weekWidth;
      edit.endWeek = nW;
    }
    
    nextRows[moveState.rowIndex].crops[moveState.index] = edit;
    setRows(nextRows);
  }

  function cropCells() {
    let currentY = 0;

    let cropElements = [];
    rows.forEach((row, rowIndex) => {
      row.crops.forEach((cropTask, cropIndex) => {
        cropElements.push((
          <CropCell
            key={cropTask.crop + "_" + row.stream}
            row={row}
            cropTask={cropTask}
            rowIndex={rowIndex}
            cropIndex={cropIndex}
            currentY={currentY}
          />
        ));
      })

      currentY += row.height;
    })

    return cropElements;
  }

  function onBodyScroll(e) {
    headerScroller.current.scrollLeft = e.target.scrollLeft;
    tableScroller.current.scrollTop = e.target.scrollTop;
  }
  function mouseover(e) {
    
  }
  function mousemove(e) {
    if (moveState.crop && moveState.type === "CROP_CELL") cellMouseMove(e);
    if (moveState.crop && moveState.type === "CROP_CELL_MOVE") cellSizeMouseMove(e);

    let timelineTop = timelineBody.current.getBoundingClientRect().top;
    let timelineScroll = timelineBody.current.scrollTop;
    let rowY = Math.floor((e.clientY - timelineTop) + timelineScroll);
    let rowI = 0;
    let cY = 0;
    rows.forEach((row, i) => {
      if (rowY >= cY && rowY <= (cY + row.height)) {rowI = i;}
      cY += row.height;
    })
    if (rowI >= 0 && !rows[rowI].hovered) {
      const nextRows = rows.map((row, i) => {
        if (rowI === i) {
          return { ...row, hovered : true };
        }
        return { ...row, hovered : false };
      });
      setRows(nextRows);
    }
  }
  function mouseup(e) {
    if (moveState.crop) {
      cellMouseUp(e);
      e.preventDefault();
      e.stopPropagation();
    }
  }
  function mousedown(e) {
    let type = e.target.attributes?.getNamedItem('datatype')?.value;
    if (type === "CROP_CELL") cellMouseDown(e);
    if (type === "CROP_CELL_LEFT") cellSizeMouseDown(e, "LEFT");
    if (type === "CROP_CELL_RIGHT") cellSizeMouseDown(e, "RIGHT");
  }
  function mouseclick(e) {
    if (dragging) {
      setDragging(false);
      return;
    }
    let type = e.target.attributes?.getNamedItem('datatype')?.value;
    if (type === "CROP_CELL") toggleCropCells(e);

    if (!type) {
      let bounds = timelineBody.current.getBoundingClientRect()
      let top = bounds.top;
      let left = bounds.left;
      let scrollTop = timelineBody.current.scrollTop;
      let scrollLeft = timelineBody.current.scrollLeft;
      let row = Math.floor(((e.clientY - top) + scrollTop)/22);
      let week = Math.floor(((e.clientX - left) + scrollLeft)/ Math.floor(timelineWidth/52));
    }
  }

  function toggleCropCells(e) {
    if (!e && !selectedCrop) return;
    let nextRows = rows.map(row => {
      return { ...row, height : 22 * row.streams, open: false };
    });

    if (!e) {
      setSelectedCrop(false); 
      return nextRows;
    }
    e.preventDefault();
    e.stopPropagation();
    let id = e.target.attributes.getNamedItem('dataid')?.value;
    let rowIndex = e.target.attributes.getNamedItem('datarow')?.value;
    if (!id) return;
    if (selectedCrop !== id) {
      nextRows[rowIndex].open = true;
      nextRows[rowIndex].height = 80;
      setSelectedCrop(id);
    } else {
      setSelectedCrop(false);
    }
    setRows(nextRows);
  }

  function lineAt(ctx, x) {
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, 20);
    ctx.stroke();
  }

  function background() {
    const canvas = document.createElement("canvas");
    canvas.width = Math.floor(timelineWidth / 52);
    canvas.height = 20;
    const ctx = canvas.getContext("2d");
    ctx.shadowColor = "rgba(128,128,128,0.5)";
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 0.5;
    ctx.lineWidth = 1;
    ctx.lineCap = "square";
    ctx.strokeStyle = "#eaeaea";
    ctx.translate(0.5, 0.5);
  
    [0].forEach(column => {
      lineAt(ctx, Math.floor( column * (timelineWidth / 52 ) ) );
    });
  
    const dataURL = canvas.toDataURL();
    return `url("${dataURL}")`;
  }

  if (!loaded || !rows) return (<p>loading</p>)
  return (
    <div className="absolute inset-0">
      {addCrop ? (<NewCropModal />) : (<></>)}
      <div className="grid grid-rows-planning-contain h-full">
        <div className="border-b-black border border-b-solid">
          <div className="flex items-center">
            <div className="px-3 py-3 flex-1">
              <h1 className="text-3xl text-blue-600">Crop Planning</h1>
            </div>
            <div>
              <button
                type="button"
                onClick={() => {setAddCrop(true)}}
                className="py-3 px-3"
              >
                <PlusCircleIcon className="text-blue-500 w-6 h-6 inline-block"/>
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white relative">
          <div className="absolute inset-0 px-5 py-6">
            <div className="relative w-full h-full flex" onMouseOver={mouseover} onMouseMove={mousemove} onMouseDown={mousedown} onMouseUp={mouseup} onClick={mouseclick}>
              <div className="w-[240px] overflow-x-auto flex flex-col">
                <div className="flex items-stretch overflow-hidden h-[50px] bg-gray-200 border-b border-b-gray-300">
                  <div className="flex-1 whitespace-nowrap overflow-hidden items-center flex h-full px-2">
                    Beds
                  </div>
                </div>
                <div className="flex flex-1 overflow-y-hidden w-full">
                  <div className="w-full overflow-y-hidden border-b border-b-gray-300" ref={tableScroller}>
                    <div className="p-0" style={{height : `${timelineHeight}px`}}>
                      <TableRows rows={rows} croppingLocations={croppingLocations} />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="z-10 bg-gray-400 w-[4px] cursor-col-resize absolute left-[240px] h-full"></div>
              
              <div className="flex-1 ml-1 flex flex-col overflow-x-auto">
                <div className="relative">
                  <div className="relative overflow-hidden" ref={headerScroller}>
                    <div style={{width : `${timelineWidth}px`}}>
                      <div className="whitespace-nowrap h-[25px]">
                        <TopHeaderLabels calendar={calendar} timelineWidth={timelineWidth} />
                      </div>
                      <div className="whitespace-nowrap h-[25px]">
                        <WeekHeaderLabels calendar={calendar} timelineWidth={timelineWidth} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative flex-1 overflow-auto" ref={timelineBody} onScroll={onBodyScroll}>
                  <div className="relative" style={{width:`${timelineWidth}px`, height:`${timelineHeight}px`}}>
                    <div
                      className="absolute w-full h-full overflow-hidden bg-repeat" 
                      style={{
                        backgroundImage: background(),
                        backgroundPositionX: '-1px'
                      }}
                    />
                    
                    <Highlights rows={rows} timelineHeight={timelineHeight} />
                    

                    <div className="absolute inset-0 pointer-events-none z-10">
                      {cropCells()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
