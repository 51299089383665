import React, { useState } from "react";
import { useOneOffStore } from "../../../dataStore/OneOffStore";
import { useCSATierStore } from "../../../dataStore/CSATierStore";
import Select from 'react-select'
import { ArrowPathIcon, CheckBadgeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import swal from "sweetalert";

export default function OneOffCard(props) {
  const {id, member, activePeriod, activePeriodId} = props;
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const tiers = useCSATierStore((state) => state.objects);
  const updateMember = useOneOffStore((state) => state.update);
  const saveMember = useOneOffStore((state) => state.save);
  const deleteMember = useOneOffStore((state) => state.delete);

  function toggleEditing(e) {
    setEditing(!editing);
  }

  async function saveChanges() {
    if (saving) return;
    setSaving(true);
    await saveMember(id);
    setSaving(false);
    setEditing(false);
  }

  if (editing) {
    return (
      <tr className="bg-gray-100 !border-t-2 !border-t-emerald-500 justify-start align-top" key={id}>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <h2 className="font-bold mb-2">{member.name} - Details</h2>
          <input
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Name"
            value={member.name}
            onChange={e => updateMember(id, {name : e.target.value})}
            disabled={saving}
          />
          <input
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Email"
            value={member.email}
            onChange={e => updateMember(id, {email : e.target.value})}
            disabled={saving}
          />
          <input
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Phone"
            value={member.phone}
            onChange={e => updateMember(id, {phone : e.target.value})}
            disabled={saving}
          />
          <textarea
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Address"
            value={member.address}
            onChange={e => updateMember(id, {address : e.target.value})}
            disabled={saving}
          />
          <textarea
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Delivery Instructions"
            value={member.subscription.delivery_instructions}
            onChange={e => updateMember(id, { subscription : { ...member.subscription, delivery_instructions : e.target.value }})}
            disabled={saving}
          />
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800" colSpan={2}>
          <EditSubscription 
            member={member} 
            activePeriodId={activePeriodId} 
            tiers={tiers}
            updateMember={updateMember}
            saving={saving}
          />
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 flex flex-col justify-center gap-3">
          <button 
            className="flex justify-center border border-blue-500 rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
            onClick={saveChanges}
          >
            {!saving ? "Save" : (
              <ArrowPathIcon width={24} height={24} className="text-blue-500 animate-spin-slow" />
            )}
          </button>
        </td>
      </tr>
    )
  }

  return (
    <tr className="hover:bg-gray-100 cursor-pointer" key={id} onClick={toggleEditing}>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <h3 className="text-md font-bold">{member.name}</h3>
        <p>{member.email}</p>
        <p>{member.phone}</p>
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <SubscriptionCard member={member} activePeriodId={activePeriodId} />
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <ActionCard member={member} activePeriodId={activePeriodId} />
      </td>
    </tr>
  )
}

function ActionCard(props) {
  const { member, activePeriodId } = props;
  const updateMember = useOneOffStore((state) => state.update);
  const saveMember = useOneOffStore((state) => state.save);
  const deleteMember = useOneOffStore((state) => state.delete)

  const [settingStatus, setSettingStatus] = useState(false);
  const [makingMember, setMakiningMember] = useState(false);
  const [deleting, setDeleting] = useState(false);

  function startDelete(e) {
    e.stopPropagation();
    if (deleting) return;

    swal({
      title: "Are you sure?",
      text: "Once deleted you will loose access to the customer",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willCancel) => {
      if (willCancel) {
        setDeleting(true);
        await deleteMember(member._id);
        swal("The OneOff subscription has been deleted", {
          icon: "success",
        });
        setDeleting(false);
      }
    });
    

  }

  async function changeStatus(e, status) {
    e.stopPropagation();
    if (settingStatus) return;

    setSettingStatus(true);
    await updateMember(member._id, {status : {...member.status, delivered : status}});
    await saveMember(member._id);

    setSettingStatus(false);
  }

  return (
    <div className="flex gap-3 items-start align-middle">
      {!member.status.delivered ? (
        <button 
          className="border border-blue-500 rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
          onClick={e => changeStatus(e, true)}
        >
          {!settingStatus ? (
            <span className="flex gap-2 items-center">
              <span className="text-inherit">Deliver</span>
              <CheckBadgeIcon width={24} height={24} />
            </span>
          ) : (
            <span className="text-blue-500">Setting Status...</span>
          )}
        </button>
      ) : (
        <button 
          className="border border-blue-500 bg-blue-500 text-white rounded px-2 py-1 hover:border-blue-600 hover:bg-blue-600"
          onClick={e => changeStatus(e, false)}
        >
          {!settingStatus ? (
            <span className="flex gap-2 items-center">
              <span className="text-inherit">Delivering</span>
              <CheckCircleIcon width={24} height={24} />
            </span>
          ) : (
            <span className="text-blue-500">Setting Status...</span>
          )}
        </button>
      )}

      {member.status.delivered ? (
        <button 
        className="border border-blue-500 rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
        
        >
          {!makingMember ? (
            <span className="text-inherit">Create Member</span>
          ) : (
            <span className="text-blue-500">Creating Member...</span>
          )}
        </button>
      ) : (<></>)}
      
      <button 
        className="border border-blue-500 rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
        onClick={startDelete}
      >
        {!deleting ? (
          <span className="text-inherit"><TrashIcon className="text-inherit" width="20" height="20" /></span>
        ) : (
          <span className="text-blue-500">Deleting...</span>
        )}
      </button>
    </div>
  )
}

function EditSubscription(props) {
  const {member, activePeriodId, tiers, updateMember, saving} = props;

  const deliveryRuns = [{val:'WED 1'},{val:'WED 2'},{val:'WED 3'},{val:'WED 4'},{val:'WED 5'},{val:'WED 6'}];
  const deliveryRunLabels = {'WED 1':'WED 1', 'WED 2':'WED 2', 'WED 3':'WED 3', 'WED 4':'WED 4', 'WED 5':'WED 5', 'WED 6':'WED 6'};
  const paymentMethods = [{val:'GC'}, {val:'BANK'}];
  const paymentMethodLabels = {'GC':'GoCardless','BANK':'Bank Transfer'}
  const activePillClass = "text-[10px] text-white rounded py-0 px-1 bg-blue-500";
  const inactivePillClass = "text-[10px] text-blue-500 rounded py-0 px-1 bg-white";

  function _dsObjToSelectArr(obj, labelKey) {
    let optionArray = [];
    for (let [optionId, option] of Object.entries(obj)) {
      optionArray.push(option)
    }
    return optionArray;
  }
  function boolObj(bool) { return {val : bool}}

  function setTier(tier) {
    updateMember(member._id, { csa_tier : tier, subscription : { ...member.subscription, box_size : tier.tierCode }})
  }

  function toggleStatus(setting) {
    let newStatus = { ...member.status };
    newStatus[setting] = !newStatus[setting];
    updateMember(member._id, { status : newStatus });
  }

  return (
    <div className="relative grid grid-cols-2 gap-3">
      <div>
        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Share Size</label>
          <Select 
            value={member.csa_tier}
            options={_dsObjToSelectArr(tiers, "tierName")} 
            getOptionLabel={el => el.tierName}
            getOptionValue={el => el._id}
            onChange={setTier}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Delivered</label>
          <Select 
            value={boolObj(member.subscription?.delivered)}
            options={[{ val : true }, { val : false }]} 
            getOptionLabel={el => ((el.val === true) ? "YES" : "NO")}
            getOptionValue={el => { return el.val }}
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, delivered : val.val}})}}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Delivery Run</label>
          <Select 
            value={boolObj(member.subscription?.delivery_run)}
            options={deliveryRuns} 
            getOptionLabel={el => deliveryRunLabels[el.val] }
            getOptionValue={el => el.val }
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, delivery_run : val.val}})}}
            isDisabled={saving}
          />
        </div>
      </div>

      <div>
        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Delivery Area</label>
          <Select 
            value={boolObj(member.subscription?.delivery_area)}
            options={[{ val : "WAIRARAPA", l : "Wairarapa - WED" }, { val : "WELLINGTON", l : "Wellington - WED" }, { val : "LOWER_HUTT", l : "Lower Hutt - WED"}]} 
            getOptionLabel={el => { return el.val }}
            getOptionValue={el => { return el.val }}
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, delivery_area : val.val}})}}
            isDisabled={saving}
          />
        </div>
      </div>
    </div>
  )
}

function SubscriptionCard(props) {
  const {member, activePeriodId} = props;

  return (
    <div className="relative self-center">
      <div className="grid grid-cols-2">
        <div className="flex gap-3 align-top">
          <div>
            <div className="rounded-full w-3 h-3 bg-green-600 mt-2" />
          </div>
          <div>
            <h3 className="text-md font-bold">{member.csa_tier.tierName}</h3>
            <p>{member.subscription.delivered ? `DELIVERED ${member.subscription.delivery_area}` : "PICKUP"} {member.subscription.salad_extra ? "+ SALAD" : ""}</p>
          </div>
        </div>
      </div>
    </div>
  )
}