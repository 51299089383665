import React, { useState, useCallback, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { InlineInput } from "../../components/formElements/inlineInput";
import { useAlmanacEntryStore } from "../../dataStore/AlmanacStore";

export default function ManageTaskPage() {

  const taskTree = {
    "UNASSIGNED" : [
      {
        title : 'WEED B4',
        tags : [],
        users : [],
        timing : []
      }
    ],
    "SHELDON" : [],
    "ELLE" : [],
    "SASKIA" : []
  }

  function TaskTree() {
    let tree = [];
    for (let [laneName, laneTasks] of Object.entries(taskTree)) {
      tree.push(taskList(laneName, laneTasks));
    }
    return tree;
  }

  function taskList(listTitle, list) {
    const taskCards = list.map((item, index) => {
      return (
        <>
          {taskCard(item, index)}
        </>
      )
    })
    return (
      <div className="rounded-xl bg-gray-200 w-[400px]">
        <div className="px-5 py-1">
          <p className="font-bold text-sm mt-2 my-1">{listTitle}</p>
        </div>
        {taskCards}
      </div>
    )
  }

  function taskCard(task, index) {
    return (
      <div className="px-3 py-1 flex flex-col gap-2 w-full" key={index}>
        <div className="bg-white flex flex-col gap-1 rounded shadow-list-item w-full px-1 py-1">
          <p className="font-bold text-sm my-0 mx-2 text-slate-800">Weeding</p>
          <div className="px-2 my-0">
            <p className="text-sm mx-0 my-0">Hoe - B4</p>
            <p className="text-sm mx-0 my-0">Hoe - B4</p>
            <p className="text-sm mx-0 my-0">Hoe - B4</p>
            <p className="text-sm mx-0 my-0">Hoe - B4</p>
          </div>
          <div className="flex items-center gap-2">
            {taskCardTag()}
            {taskCardTag()}
            {taskCardTag()}
          </div>
        </div>
      </div>
    )
  }

  function taskCardTag(task, tag) {
    return (
      <span className="text-[10px] bg-orange-500 text-white rounded-full px-2 py-0 cursor-pointer">WEEDING</span>
    )
  }

  function taskCardUser(task, user) {

  }

  function taskCardOptions(task, options) {

  }


  return (
    <div className="absolute inset-0">
      <svg className="absolute z-0 inset-0 w-full h-full" data-testid="rf__background">
        <pattern id="pattern-1undefined" x="0.5" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternTransform="translate(-0.5,-0.5)">
          <circle cx="0.5" cy="0.5" r="0.5" fill="#91919a"></circle>
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1undefined)"></rect>
      </svg>

      <div className="relative grid grid-rows-task-main-view z-10 h-full">
        <div className="w-full flex items-center px-6 gap-3">
          <h1 className="text-3xl text-blue-600 mr-3">Farm Tasks</h1>
          <button className="rounded-full bg-blue-500 hover:bg-blue-600 text-white text-xs px-2 py-1">Assignment</button>
          <button className="rounded-full bg-slate-500 hover:bg-blue-600 text-white text-xs px-2 py-1">Task Type</button>
          <button className="rounded-full bg-slate-500 hover:bg-blue-600 text-white text-xs px-2 py-1">This Week</button>
          <button className="rounded-full bg-slate-500 hover:bg-blue-600 text-white text-xs px-2 py-1">New Task</button>
        </div>
        
        <div className="relative w-full h-full overflow-scroll px-6 py-3">
          <div className="flex gap-4">
            {TaskTree()}
          </div>
        </div>
      </div>

      
    </div>
  );
}
