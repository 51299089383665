import { create } from 'zustand'
import { produce } from 'immer';
import _ from 'lodash';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/cropProfile`;
const cookies = new Cookies();

function newCropProfile() {
  return {
    species : null,
    variationName : "New Profile",

    establishmentType : "DIRECT",
    cropType : "CROP",

    directSow : {
      seeder : "JANG",
      seederPlate : "A-6",
      seederGears : "F12",
      seederOther : "",
      cover : "SHADE",
      desiredGrams : 12
    },

    transplant : {
      cellTray : null,
      seedPerCell : 1,
      oversow : 0,
      cover : "SHADE"
    },

    spacing : {
      rows : 3,
      onRowSpacing : 30,
      betweenRowSpacing : 30,
      plantsPerMeter : 9,
    },

    harvest : {
      harvestUnit : "BUNCH",
      harvestPeriod : 14,
      harvestFrequency : 7,
      unitsPerLength : 1,
      yeildLength : 1
    },

    timing : {
      DTG : 7,
      DIC : 30,
      DTM : 56,
      DIF : 63,
      DOM : 14,
    },

    establishmentPeriod : new Array(24).fill(false),

    tasks : []
  }
}

const useCropProfileStore = create((set, get) => ({
  profiles : {},
  loaded : false,
  addProfile : async (values) => {
    const newProfile = { ...newCropProfile(), ...values };
    return await get().createProfile(newProfile);
  },
  updateProfile : (id, value) => {
    set(
      produce((state) => {
        state.profiles[id] = { ...get().profiles[id], ...value }
      })
    )
  },
  deleteProfile : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "DELETE"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    let cL = _.cloneDeep(get().profiles);
    delete cL[id];
    set({
      profiles : { ...cL }
    })
  },
  saveProfile : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().profiles[id]),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  fetch : async () => {
    if (get().loaded) return true
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);

    set({
      profiles : id_linked,
      loaded : true
    });
  },
  createProfile : async (profile) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(profile),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    let cL = get().profiles;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      profiles : { ...cL }
    })
    return data._id;
  }
}))

export {useCropProfileStore};