import React, { useEffect } from "react";

export default function DashPage() {
  return (
    <div className="px-12 py-8">
      <h1 className="font-bold text-4xl text-blue-600">Home</h1>
      <p className="bg-red-400 hover:bg-red-300"></p>
      <p className="bg-orange-400 hover:bg-orange-300"></p>
      <p className="bg-amber-400 hover:bg-amber-300"></p>
      <p className="bg-yellow-400 hover:bg-yellow-300"></p>
      <p className="bg-lime-400 hover:bg-lime-300"></p>
      <p className="bg-green-400 hover:bg-green-300"></p>
      <p className="bg-emerald-400 hover:bg-emerald-300"></p>
      <p className="bg-teal-400 hover:bg-teal-300"></p>
      <p className="bg-cyan-400 hover:bg-cyan-300"></p>
      <p className="bg-sky-400 hover:bg-sky-300"></p>
      <p className="bg-blue-400 hover:bg-blue-300"></p>
      <p className="bg-indigo-400 hover:bg-indigo-300"></p>
      <p className="bg-violet-400 hover:bg-violet-300"></p>
      <p className="bg-purple-400 hover:bg-purple-300"></p>
      <p className="bg-fuchsia-400 hover:bg-fuchsia-300"></p>
      <p className="bg-pink-400 hover:bg-pink-300"></p>
      <p className="bg-rose-400 hover:bg-rose-300"></p>
      <p className="bg-red-600 hover:bg-red-500"></p>
      <p className="bg-orange-600 hover:bg-orange-500"></p>
      <p className="bg-amber-600 hover:bg-amber-500"></p>
      <p className="bg-yellow-600 hover:bg-yellow-500"></p>
      <p className="bg-lime-600 hover:bg-lime-500"></p>
      <p className="bg-green-600 hover:bg-green-500"></p>
      <p className="bg-emerald-600 hover:bg-emerald-500"></p>
      <p className="bg-teal-600 hover:bg-teal-500"></p>
      <p className="bg-cyan-600 hover:bg-cyan-500"></p>
      <p className="bg-sky-600 hover:bg-sky-500"></p>
      <p className="bg-blue-600 hover:bg-blue-500"></p>
      <p className="bg-indigo-600 hover:bg-indigo-500"></p>
      <p className="bg-violet-600 hover:bg-violet-500"></p>
      <p className="bg-purple-600 hover:bg-purple-500"></p>
      <p className="bg-fuchsia-600 hover:bg-fuchsia-500"></p>
      <p className="bg-pink-600 hover:bg-pink-500"></p>
      <p className="bg-rose-600 hover:bg-rose-500"></p>
      <p>...</p>
    </div>
  );
}
