import React, { useState, useEffect, useRef } from "react";
import { useCroppingLocationStore } from "../../dataStore/CroppingLocationStore";
import FormCard from "../../components/formElements/formCard";
import FormField from "../../components/formElements/formField";
import { InlineInput } from "../../components/formElements/inlineInput";
import { CheckIcon, PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = "pk.eyJ1Ijoic2hlbGRvbmxldmV0IiwiYSI6ImNsanhta2FjODF3MzMzZXFnMjltYWJyaGYifQ.Cp7H0A1KRRgYdjg7yM3CNw";

function FieldSelectionList(props) {
  const locations = useCroppingLocationStore((state) => state.croppingLocations);
  const [fields, setFields] = useState(null);

  useEffect(() => {
    if (!locations) return;
    if (fields) return;
    let _fields = [];
    for (let [locationId, location] of Object.entries(locations)) {
      if (location.locationType === "FIELD") _fields.push(location);
    }
    setFields(_fields);
  }, [locations])

  if (!fields) return (<></>);

  return (
    <div className="">
      {fields.map(field => {
        return (
          <div className="" key={field._id}>
            <p>{field.name}</p>
          </div>
        )
      })}
    </div>
  )
}

function BlockSelectionList(props) {
  return (<></>)
}

function BedSelectionList(props) {
  return (<></>)
}

function Field(props) {
  const [editing, setEditing] = useState(false); 
  const locations = useCroppingLocationStore((state) => state.croppingLocations);
  const field = locations[props.field._id];
  const addBlock = useCroppingLocationStore((state) => state.addBlock);
  const updateField = useCroppingLocationStore((state) => state.updateField);
  const deleteLocation = useCroppingLocationStore(state => state.deleteLocation);
  const saveLocation = useCroppingLocationStore(state => state.saveLocation)

  function blockList() {
    let i = -1;
    return  Object.keys(locations).map((nodeId) => {
      let node = locations[nodeId];
      if (node.locationType === "BLOCK" && node.parent === props.id) {
        i++;
        return (<Block 
          field={props.field} 
          block={node} 
          id={node._id} 
          index={i} 
          key={node._id}
          editing={editing}
        />)
      }
    });
  }

  function deleteBlock(blockId) {
    let ids = [];
    Object.keys(locations).map((nodeId) => {
      let node = locations[nodeId];
      if (node.locationType === "BED" && node.parent === blockId) {
        ids.push(nodeId);
      }
    });
    ids.forEach(deleteLocation);
    deleteLocation(blockId);
  }

  function deleteField() {
    let ids = [];
    Object.keys(locations).map((nodeId) => {
      let node = locations[nodeId];
      if (node.locationType === "BLOCK" && node.parent === props.id) {
        ids.push(nodeId);
      }
    });
    ids.forEach(deleteBlock);
    deleteLocation(field._id);
  }

  async function save() {
    let ids = [];
    Object.keys(locations).map((nodeId) => {
      let node = locations[nodeId];
      if (node.locationType === "BLOCK" && node.parent === props.id) {
        ids.push(nodeId);
        Object.keys(locations).map((_nodeId) => {
          let _node = locations[_nodeId];
          if (_node.locationType === "BED" && _node.parent === nodeId) {
            ids.push(_nodeId);
          }
        });
      }
    });
    ids.forEach(saveLocation);
    await saveLocation(field._id);
    setEditing(false);
  }

  return (
    <FormCard cardName={`Field: ${props.field.name}`}>
      <div className="absolute flex gap-2 top-6 right-6">
        {(!editing) ? (
          <button
            type="button"
            onClick={() => {setEditing(true)}}
          >
            <PencilIcon className="text-blue-500 w-4 h-4 inline-block"/>
          </button>
        ) : (
          <button
            type="button"
            onClick={save}
          >
            <CheckIcon className="text-blue-500 w-4 h-4 inline-block"/>
          </button>
        )}
        
        <button
          type="button"
          onClick={deleteField}
        >
          <TrashIcon className="text-blue-500 w-4 h-4 inline-block"/>
        </button>
      </div>
      {(editing) ? (<FormField
        name="FIELD_NAME"
        title="Field Name"
        placeholder="Field Name"
        value={field.name}
        hint={false}
        onChange={(e) => {updateField(props.field._id, { name : e.target.value })}}
      />) : <></>}

      {blockList()}

      <div className="!mt-3 flex justify-end">
        <button 
          className="flex items-center gap-2 text-xs bg-sky-500 hover:bg-sky-700 text-white rounded-xl pl-2 px-3 py-1"
          onClick={() => {addBlock(field._id)}}
          type="button"
        >
          <PlusCircleIcon className="w-4 h-4 inline-block"></PlusCircleIcon>
          Add Block
        </button>
      </div>
    </FormCard>
  );
}


function Block(props) {
  const [editing, setEditing] = useState(false); 
  const locations = useCroppingLocationStore((state) => state.croppingLocations);
  const block = locations[props.block._id];
  const addBed = useCroppingLocationStore((state) => state.addBed);
  const updateField = useCroppingLocationStore((state) => state.updateField);
  const deleteLocation = useCroppingLocationStore(state => state.deleteLocation);

  function bedList() {
    return Object.keys(locations).map((nodeId) => {
      let node = locations[nodeId];
      if (node.locationType === "BED" && node.parent === props.id) {
        return (
          <Bed
            field={props.field}
            block={block}
            bed={node}
            id={node._id}
            key={node._id}
            editing={props.editing}
          />
        )
      }
    });
  }

  function deleteBlock() {
    let ids = [];
    Object.keys(locations).map((nodeId) => {
      let node = locations[nodeId];
      if (node.locationType === "BED" && node.parent === props.id) {
        ids.push(nodeId);
      }
    });
    ids.forEach(deleteLocation);
    deleteLocation(props.id);
  }

  return (
    <div>
      <div className="flex">
        {(props.editing) ? (
          <>
          <span className="text-sm">Block: </span>
          <InlineInput
            name={`BLOCK_${block._id}_NAME`}
            placeholder="Block Name"
            value={block.name}
            label={false}
            onChange={(e) => {updateField(block._id, { name : e.target.value })}}
          />
          </>
        ) : (
          <>
            <p className="text-sm font-bold flex-1">Block: {block.name}</p>
            <p className="font-bold text-sm">
              <a className="text-blue-500 hover:text-blue-700" href="#" onClick={deleteBlock}>Delete Block</a>
            </p>
          </>
        )}
        
      </div>
      
      <div className="bg-slate-200 rounded-md px-2 py-1 my-1">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead>
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Width</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Length</th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Action</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {bedList()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="!mt-3 flex justify-end">
          <button 
            className="flex items-center gap-2 text-xs bg-sky-500 hover:bg-sky-700 text-white rounded-xl pl-2 px-3 py-1"
            onClick={() => {addBed(block._id)}}
            type="button"
          >
            <PlusCircleIcon className="w-4 h-4 inline-block"></PlusCircleIcon>
            Add Bed
          </button>
        </div>
      </div>
    </div>    
  )
}
function Bed(props) {
  const updateField = useCroppingLocationStore((state) => state.updateField);
  const deleteLocation = useCroppingLocationStore(state => state.deleteLocation);

  return (
    <tr>
      <td className="px-6 py-1 whitespace-nowrap text-xs font-medium text-gray-800 dark:text-gray-200">
        {(props.editing) ? (
          <InlineInput
          name="Name"
          placeholder=""
          value={props.bed.name}
          label={false}
          onChange={(e) => {updateField(props.id, { name : e.target.value })}}
        />
        ) : (<>{props.bed.name}</>)}
      </td>
      <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-800 dark:text-gray-200">{props.bed.size.width}{props.bed.size.sizeUnit}</td>
      <td className="px-6 py-1 whitespace-nowrap text-xs text-gray-800 dark:text-gray-200">{props.bed.size.length}{props.bed.size.sizeUnit}</td>
      <td className="px-6 py-1 whitespace-nowrap text-right text-xs font-medium">
        <a className="text-blue-500 hover:text-blue-700" href="#" onClick={() => {deleteLocation(props.id)}}>Delete</a>
      </td>
    </tr>
  )
}

function AMap(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(5);

  function bedGeometry(num) {
    let base = [
      [0, 0],
      [0, 2],
      [0.075, 2],
      [0.075, 0],
      [0,0]
    ];
    let features = [];

    for (var x=0;x<num;x++) {
      let newCoord = base.map(c => {
        return [c[0]+(0.075*x), c[1]]
      })
      let newFeature = {
        'type' : 'Feature',
        'properties' : {
          'crop' : 'carrot'
        },
        'geometry': {
          'type': 'Polygon',
          'coordinates': [newCoord]
        }
      }
      features.push(newFeature);
    }
    console.log(features);
    return features;
  }

  function addPoly() {
    map.current.addSource('maine', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features' : bedGeometry(10)
      }
    });
       
    map.current.addLayer({
      'id': 'maine',
      'type': 'fill',
      'source': 'maine', // reference the data source
      'layout': {},
      'paint': {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.5
      }
    });
    
    // map.current.addLayer({
    //   'id': 'outline',
    //   'type': 'line',
    //   'source': 'maine',
    //   'layout': {},
    //   'paint': {
    //     'line-color': '#000',
    //     'line-width': 3
    //   }
    // });

    map.current.addLayer({
      'id': 'labels',
      'type': 'symbol',
      'source': 'maine',
      'layout': {
          'text-field': [
            "format",
            ['get', 'crop'],
            {
              // "text-font": ["literal", ["PT Mono"]],
              "text-color": "#fff",
            },
          ],
          'text-variable-anchor': ['center'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'text-rotate' : 90
      }
    });
  }

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/sheldonlevet/cljxm8tw4000901pq19uocwwy',
      center: [lng, lat],
      zoom: zoom
    });
    map.current.on('load', addPoly);
  }, [map]);

  return (
    <div ref={mapContainer} className="h-[400px]"></div>
  )
}

export default function FarmLayoutPage() {
  const [view, setView] = useState("LIST");
  const loadLocations = useCroppingLocationStore((state) => state.fetch);
  const locations = useCroppingLocationStore((state) => state.croppingLocations);
  const addField = useCroppingLocationStore((state) => state.addField);
  const [loaded, setLoaded] = useState(false);

  const [selectedField, setSelectedField] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(false);
  const [selectedBed, setSelectedBed] = useState(false);

  useEffect(async () => {
    if (loaded) return;
    await loadLocations()
    setLoaded(true)
  }, [loaded])

  function fieldList() {
    return Object.keys(locations).map(nodeId => {
      let node = locations[nodeId];
      if (node.locationType === "FIELD") {
        return (
          <Field field={node} id={nodeId} key={nodeId} />
        )
      }
    })
  }

  if (!loaded) {
    return (<p>loading...</p>)
  }

  return (
    <div className="absolute inset-0">
      <svg className="absolute z-0 inset-0 w-full h-full" data-testid="rf__background">
        <pattern id="pattern-1undefined" x="0.5" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternTransform="translate(-0.5,-0.5)">
          <circle cx="0.5" cy="0.5" r="0.5" fill="#91919a"></circle>
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1undefined)"></rect>
      </svg>
      <div className="relative z-10 grid grid-rows-task-main-view h-full">
        <div className="w-full flex items-center px-6 gap-3">
          <h1 className="text-3xl text-blue-600 mr-3">Farm Layout</h1>
          <button className="rounded-full bg-blue-500 hover:bg-blue-600 text-white text-xs px-2 py-1">List</button>
          <button className="rounded-full bg-slate-500 hover:bg-blue-600 text-white text-xs px-2 py-1">Map</button>
        </div>
        
        <div className="bg-transparent relative px-12 py-8 overflow-scroll">
          <div className="relative flex items-start justify-start content-start">
            <div className="flex-1">
              <p>Fields</p>
              <FieldSelectionList 
                setField={setSelectedField} 
                selectedField={selectedField}
              />
            </div>
            <div className="flex-1">
              <p>Blocks</p>
              <BlockSelectionList 
                setBlock={setSelectedBlock} 
                selectedBlock={selectedBlock} 
              />
            </div>
            <div className="flex-1">
              <p>Beds</p>
              <BedSelectionList
                setBed={setSelectedBed}
                selectedBed={selectedBed} 
              />
            </div>
          </div>
          {(view === "MAP") ? <AMap /> : <></>}
          <div className="w-full lg:w-1/2 4xl:w-1/3">
            <form action="#">
              <div className="space-y-10">
                {fieldList()}

                <div className="!mt-3 flex justify-end">
                  <button 
                    className="flex items-center gap-2 text-xs bg-sky-500 hover:bg-sky-700 text-white rounded-xl pl-2 px-3 py-1"
                    onClick={addField} 
                    type="button"
                  >
                    <PlusCircleIcon className="w-4 h-4 inline-block"></PlusCircleIcon>
                    Add Field
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
