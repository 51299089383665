import { create } from 'zustand'
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/auth`;
const cookies = new Cookies();

const useAuthStore = create((set, get) => ({
  user: {first_name:'',last_name:'',email:'',password:''},
  users: [],
  permissions: null,
  token: null,
  loggedIn: false,
  profile : async () => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/me?access_token=${token}`, {
        method: "GET"
    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    set({user : data.user});
    return data.user;
  },
  fetchFarmers : async () => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/users?access_token=${token}`, {
        method: "GET"
    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    set({users : data});
    return data;
  },
  login : async (user) => {
    const response = await fetch(apiEndpoint + "/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    });
  
    if (!response.ok) {
        const message = `An error occured: ${response.statusText}`;
        set({user : null, loggedIn : false});
        return false;
    }
  
    const data = await response.json();
    cookies.set('token', data.token, { path: '/' });
    set({
        token : data.token,
        user : data.user,
        loggedIn : true
    })
    return data;
  },
  registerFarmer : async (user) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/register/farmer?access_token=${token}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    });
  
    if (!response.ok) {
        const message = `An error occured: ${response.statusText}`;
        window.alert(message);
        return false;
    }
  
    const data = await response.json();
    return (data.user._id) ? true : false;
  },
  register : async (user) => {
    const response = await fetch(apiEndpoint + '/register', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    });
  
    if (!response.ok) {
        const message = `An error occured: ${response.statusText}`;
        window.alert(message);
        return false;
    }
  
    const data = await response.json();
    return data;
  },
  logout : async () => {
    const response = await fetch(this.apiEndpoint + '/logout', {
        method: "GET"
    });
    
    if (!response.ok) {
        const message = `An error occured: ${response.statusText}`;
        window.alert(message);
        return;
    }
    set({
        token : null,
        user : null,
        loggedIn : false
    })
    return true;
  }
}))

export {useAuthStore};