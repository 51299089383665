import { create } from 'zustand'
import { produce } from 'immer';
import _ from 'lodash';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/csa/oneoffs`;
const cookies = new Cookies();

function newObject() {
  return {
    email: "",
    name: "",
    address : "",
    phone : "",
    csa_tier : false,
    subscription : {
      box_size : "SMALL",
      delivered : false,
      delivery_instructions : "",
      delivery_area : "WAIRARAPA",
      salad_extra : false,
      is_discounted : false,
      payment_terms : "WEEKLY",
    }
  }
}

const useOneOffStore = create((set, get) => ({
  objects : {},
  member : null,
  loaded : false,
  add : async (values) => {
    const _obj = { ...newObject(), ...values };
    return await get().create(_obj);
  },
  update : (id, value) => {
    set(
      produce((state) => {
        state.objects[id] = { ...get().objects[id], ...value }
      })
    )
  },
  delete : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "DELETE"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    let cL = _.cloneDeep(get().objects);
    delete cL[id];
    set({
      objects : { ...cL }
    })
  },
  save : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().objects[id]),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  invite : async (memberId, oldPeriod, newPeriod) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${memberId}/invite?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPeriod : oldPeriod,
          newPeriod : newPeriod
        })
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const member = await response.json();
    set(
      produce((state) => {
        state.objects[memberId] = { ...member };
      })
    )
  },
  fetch : async () => {
    if (get().loaded) return true
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);

    set({
      objects : id_linked,
      loaded : true
    });
  },
  register : async (object) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/register?access_token=${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(object),
    }).catch(err => {

    });
    if (!response.ok) {
      return false;
    }
    const data = await response.json();
    let cL = get().objects;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      objects : { ...cL }
    })
    return data._id;
  },
  create : async (object) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(object),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    let cL = get().objects;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      objects : { ...cL }
    })
    return data._id;
  }
}))

export {useOneOffStore};