import { create } from 'zustand'
import { produce } from 'immer';
import _ from 'lodash';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/cellTrays`;
const cookies = new Cookies();

function newCellTray() {
  return {
    trayName : "New Cell Tray",
    cellCount : 144,

    dimensions : {
      width : '250',
      length : '500',
      depth : '30',
      area : '0.125'
    }
  }
}

const useCellTrayStore = create((set, get) => ({
  cellTrays : {},
  loaded : false,
  addCellTray : async () => {
    const newCellTray = newCellTray();
    return await get().createCellTray(newCellTray);
  },
  updateCellTray : (id, value) => {
    set(
      produce((state) => {
        state.cellTrays[id] = { ...get().cellTrays[id], ...value }
      })
    )
  },
  deleteCellTray : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "DELETE"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    let cL = _.cloneDeep(get().cellTrays);
    delete cL[id];
    set({
      cellTrays : { ...cL }
    })
  },
  saveCellTray : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().cellTrays[id]),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  fetch : async () => {
    if (get().loaded) return true
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);
    set({
      cellTrays : id_linked,
      loaded : true
    });
  },
  createCellTray : async (cellTray) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cellTray),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    let cL = get().cellTrays;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      cellTrays : { ...cL }
    })
    return data._id;
  }
}))

export {useCellTrayStore};
