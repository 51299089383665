import { create } from 'zustand'
import { produce } from 'immer';
import _ from 'lodash';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/csa/tiers`;
const cookies = new Cookies();

function newObject() {
  return {
    tierName : "SMALL",
    tierCode : "SM_BX",
    tierDescription : "...",
    
    price : {
      basePrice : 35,
      deliveryPrice : 7
    }
  }
}

const useCSATierStore = create((set, get) => ({
  objects : {},
  loaded : false,
  add : async (values) => {
    const _obj = { ...newObject(), ...values };
    return await get().create(_obj);
  },
  update : (id, value) => {
    set(
      produce((state) => {
        state.objects[id] = { ...get().objects[id], ...value }
      })
    )
  },
  delete : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "DELETE"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    let cL = _.cloneDeep(get().objects);
    delete cL[id];
    set({
      objects : { ...cL }
    })
  },
  save : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().objects[id]),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  fetchCSA : async (farm) => {
    if (get().loaded) return true
    if (typeof farm === "object") farm = farm._id;
    const response = await fetch(`${apiEndpoint}/farm/${farm}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);

    set({
      objects : id_linked,
      loaded : true
    });
  },
  fetch : async () => {
    if (get().loaded) return true
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);

    set({
      objects : id_linked,
      loaded : true
    });
  },
  create : async (object) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(object),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    let cL = get().objects;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      objects : { ...cL }
    })
    return data._id;
  }
}))

export {useCSATierStore};