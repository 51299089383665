import React, { useState } from "react";

export default function BannerPage(props) {

    return (
      <div className="bg-emerald-500 flex flex-col items-center justify-center align-middle px-10">
        <div className="w-full">
          <h1 className="text-white font-bold text-4xl uppercase mb-6">Ordering our vege</h1>    
          <p className="text-white mb-6">We no longer offer a direct farm CSA. Please find our vege in the seasonal Little Farms boxes</p>
          <a href="https://wearelittlefarms.com/collections/farm-box-weekly-subscriptions">
            <button
              className="inline-flex justify-center items-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 w-1/3 2xl:w-1/4 text-white bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus:ring-blue-600"
            >
              Visit Little Farms
            </button>
          </a>
        </div>
      </div>
    );
}
