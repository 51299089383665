import React, { useState, useCallback, useEffect } from "react";
import {useCropProfileStore} from "../../dataStore/ProfileStore";
import {useSpeciesStore} from "../../dataStore/SpeciesStore";
import { useCellTrayStore } from "../../dataStore/CellTrays";
import { ProfileForm, ProfileHeader } from "../../components/profiles/profileForm";
import ProfileTimeline from "../../components/profiles/profileTimeline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { InlineInput } from "../../components/formElements/inlineInput";
import FormInput, { FormSelect } from "../../components/formElements/formField";
import { useNavigate, useParams } from "react-router-dom";
import ProfileImportModal from "../../components/profiles/profileImport";

export default function ProfilesPage(props) {
  const loadProfiles = useCropProfileStore((state) => state.fetch);
  const loadSpecies = useSpeciesStore((state) => state.fetch);
  const loadCellTrays = useCellTrayStore((state) => state.fetch);
  
  const profiles = useCropProfileStore((state) => state.profiles);
  const [filteredProfiles, setFilteredProfiles] = useState({});
  const species = useSpeciesStore((state) => state.species);
  const cellTrays = useCellTrayStore((state) => state.cellTrays);
  
  const addProfile = useCropProfileStore((state) => state.addProfile);
  const updateProfile = useCropProfileStore(state => state.updateProfile);
  const saveProfile = useCropProfileStore((state) => state.saveProfile);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [importing, setImporting] = useState(false);
  const [filter, setFilter] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (loaded) return;
      await loadProfiles()
      await loadSpecies();
      await loadCellTrays();
      setFilteredProfiles(profiles);
      if (params.profileId !== "all") {
        if (profiles[params.profileId])
          setSelectedProfileId(params.profileId);
      }
      setLoaded(true)
    }
    fetchData();
  }, [loaded, params, profiles])

  async function createProfile() {
    let newId = await addProfile();
    window.history.replaceState(null, "New Page Title", `/app/planning/crops/${newId}`);
    setSelectedProfileId(newId);
  }

  useEffect(() => {
    if (filter.length === 0) {
      setFilteredProfiles(profiles);
    } else {
      let nFA = [];
      for (let [profileId, profile] of Object.entries(profiles)) {
        if (profile.species) {
          if (profile.species.commonName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            nFA.push(profile);
          }
          if (profile.variationName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            nFA.push(profile);
          }
        }
      }
      let nFO = {};
      nFA.forEach(e => nFO[e._id] = e);
      setFilteredProfiles(nFO);
    }
  }, [profiles, filter]);

  function listTools() {
    return (
      <div className="w-full flex gap-x-3 items-center">
        <div className="flex-1">
          <InlineInput 
            className="input input-sm" 
            placeholder="Search"
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        </div>
        <div className="">
          <button
            type="button"
            className="w-[30px] h-[30px] rounded bg-blue-500 hover:bg-blue-600 cursor-pointer flex items-center justify-center"
            onClick={createProfile}
          >
            <PlusIcon className="text-white w-4 h-4 inline-block"/>
          </button>
        </div>
      </div>
      
    )
  }

  function selectProfile(id) {
    window.history.replaceState(null, "New Page Title", `/app/planning/crops/${id}`);
    setSelectedProfileId(id);
  }

  function varityList(profiles) {
    return profiles.map((profile) => {
      let className;
      if (selectedProfileId === profile._id) {
        className = "rounded px-3 py-1 bg-sky-600 cursor-pointer text-white";
      } else {
        className = "rounded px-3 py-1 hover:bg-sky-400 cursor-pointer text-slate-900 hover:text-white";
      }
      return (
        <div className={className} key={profile._id} onClick={() => {selectProfile(profile._id)}}>
          <p className="text-xs">{profile.variationName}</p>
        </div>
      )
    })
  }

  function profileList() {
    if (!loaded) return;

    let speciesList = {
      "NEW" : []
    };
    let list = [];
    let newFound = false;
    for (let [profileId, profile] of Object.entries(filteredProfiles)) {
      if (!profile.species) {
        speciesList["NEW"].push(profile);
        newFound = true;
      } else {
        if (speciesList[profile.species._id]) {
          speciesList[profile.species._id].push(profile);
        } else {
          speciesList[profile.species._id] = [profile];
        }
      }
    }

    if (!newFound) delete speciesList["NEW"];
    
    for (let [speciesId, profiles] of Object.entries(speciesList)) {
      let speciesTitle;
      if (speciesId === "NEW") {
        speciesTitle = "New Profiles";
      } else {
        speciesTitle = species[speciesId]?.commonName || "Error";
      }
      list.push(
        <div className="px-3 mb-0 pb-2" key={speciesId}>
          <div className="py-0">
            <p className="font-bold text-sm text-sky-600">{speciesTitle}</p>
          </div>
          <div>
            {varityList(profiles)}
          </div>
        </div>
      );
    }
    return list;
  }

  if (selectedProfileId && !profiles[selectedProfileId]) {
    window.history.replaceState(null, "New Page Title", `/app/planning/crops/all`);
    setSelectedProfileId(false);
    return (<></>)
  }

  return (
    <div className="absolute inset-0">
      {(importing) ? (<ProfileImportModal />) : (<></>)}
      <svg className="absolute z-0 inset-0 w-full h-full" data-testid="rf__background">
        <pattern id="pattern-1undefined" x="0.5" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternTransform="translate(-0.5,-0.5)">
          <circle cx="0.5" cy="0.5" r="0.5" fill="#91919a"></circle>
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1undefined)"></rect>
      </svg>
      <div className="flex h-full relative z-10">
        <div className="flex-0 h-full min-w-[300px] bg-white border-r">
          <div className="relative h-full grid grid-cols-1 grid-rows-profilelist grid-flow-row gap-y-2">
            <div className="row-span-1 px-3 py-3">
              <h1 className="text-3xl text-blue-600">Crop Profiles</h1>
              {listTools()}
            </div>

            <div className="row-span-full row-start-2 overflow-scroll">
              {profileList()}
            </div>

            <div className="absolute bottom-0 left-0 right-0 text-center">
              <button onClick={() => setImporting(true)}>Import</button>
            </div>
          </div>
        </div>
        {(selectedProfileId) ? (
          <div className="flex-1 w-full h-full grid grid-rows-profile-form-contain">
            <ProfileHeader 
              profile={profiles[selectedProfileId]} 
              updateProfile={updateProfile}
              saveProfile={saveProfile}
            />
            <div className="flex">
              <div className="flex-1 w-full h-full bg-transparent overflow-scroll">
                <ProfileForm profile={profiles[selectedProfileId]} />
              </div>
              <div className="flex min-w-[450px] h-full bg-transparent overflow-scroll">
                <ProfileTimeline profile={profiles[selectedProfileId]} />
              </div>
            </div>
          </div>
        ) : (
          <div className="relative block h-full mx-auto">
            <div className="relative bg-blue-500 text-white rounded-2xl px-6 py-4 h-min mx-auto top-1/2 -translate-y-1/2">
              <h1 className="font-bold">No Profile Selected</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
