import { create } from 'zustand'
import { produce } from 'immer';
import _ from 'lodash';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/crops`;
const cookies = new Cookies();

function newObject() {
  return {
    location : null,
    species : null,
    profile : null,
    establishmentType : "DIRECT",
    cropType : "CROP",

    croppingLength : {
      rows : 5,
      length : 20
    },
    directSow : null,
    transplant : null,
    spacing : null,
    harvest : null,
    timing : null,
    tasks : [],
    timestamps : {
      startedDate : null,
      endDate : null,
      lastUpdate : null,
      createdAt : null
    },
    status : 'CREATED',
    state : {
      started : false,
      planted : false,
      seeded : false,
      growing : false,
      harvesting : false,
      harvested : false,
      needsAction : false,
      terminated : false,
      active : true
    }
  }
}

const useCropStore = create((set, get) => ({
  objects : {},
  loaded : false,
  add : async (values) => {
    const _obj = { ...newObject(), ...values };
    return await get().create(_obj);
  },
  insert : async (id, value) => {
    set(
      produce((state) => {
        state.objects = { ...get().objects, [id] : value }
      })
    )
  },
  update : (id, value) => {
    set(
      produce((state) => {
        state.objects[id] = { ...get().objects[id], ...value }
      })
    )
  },
  delete : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "DELETE"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    let cL = _.cloneDeep(get().objects);
    delete cL[id];
    set({
      objects : { ...cL }
    })
  },
  save : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().objects[id]),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  fetch : async () => {
    if (get().loaded) return true
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);

    set({
      objects : id_linked,
      loaded : true
    });
  },
  create : async (object) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(object),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    let cL = get().objects;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      objects : { ...cL }
    })
    return data._id;
  }
}))

export {useCropStore};