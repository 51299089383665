import React, { useState, useCallback, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { InlineInput } from "../../components/formElements/inlineInput";
import { useAlmanacEntryStore } from "../../dataStore/AlmanacStore";

export default function ManageAlmanacPage() {
  const loadEntries = useAlmanacEntryStore((state) => state.fetch);
  const entries = useAlmanacEntryStore((state) => state.almanacEntries);
  //const species = useSpeciesStore((state) => state.species);
  const addEntry = useAlmanacEntryStore((state) => state.addEntry);
  const [selectedEntryId, setSelectedEntryId] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(async () => {
    if (loaded) return;
    await loadEntries()
    setLoaded(true)
  }, [loaded])

  function createEntry() {
    addEntry();
  }

  function entry() {
    return ( <div className="mx-5 my-5 bg-white">
      <h1 className="font-serif text-xl">z{entries[selectedEntryId].title}</h1>
    </div>)
  }

  function listTools() {
    return (
      <div className="w-full flex gap-5">
        <div className="flex flex-1">
          <InlineInput 
            className="input input-sm" 
            placeholder="Search"
          />
        </div>
        <div className="flex gap-2 top-6 right-6">
          <button
            type="button"
            onClick={createEntry}
          >
            <PlusIcon className="text-blue-500 w-4 h-4 inline-block"/>
          </button>
        </div>
      </div>
      
    )
  }

  function entryList() {
    if (!loaded) return;

    let list = [];
    for (let [entryId, entry] of Object.entries(entries)) {
      list.push(
        <div className="px-3 mb-2 pb-2" key={entryId} onClick={() => {setSelectedEntryId(entryId)}}>
          <div className="py-1">
            <p className="font-bold text-sm text-sky-600">{entry.title}</p>
          </div>
        </div>
      );
    }
    return list;
  }

  return (
    <div className="absolute inset-0">
      <svg className="absolute z-0 inset-0 w-full h-full" data-testid="rf__background">
        <pattern id="pattern-1undefined" x="0.5" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternTransform="translate(-0.5,-0.5)">
          <circle cx="0.5" cy="0.5" r="0.5" fill="#91919a"></circle>
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1undefined)"></rect>
      </svg>
      <div className="flex h-full relative z-10">
        <div className="flex-0 h-full min-w-[400px] bg-white border-r">
          <div className="h-full grid grid-cols-1 grid-rows-profilelist grid-flow-row">
            <div className="row-span-1 px-3 py-3">
              <h1 className="text-3xl text-blue-600">Farm Almanac</h1>
              {listTools()}
            </div>

            <div className="row-span[9] row-start-2">
              {entryList()}
            </div>
          </div>
        </div>
        
        {(selectedEntryId) ? (
          <>
            <div className="flex-1 w-full bg-transparent bg-white h-full mx-10 my-10">
              {entry()}
            </div>
          </>
        ) : (
          <div className="relative bg-blue-500 text-white rounded-2xl px-6 py-4 h-min mx-auto top-1/2 -translate-y-1/2">
            <h1 className="font-bold">No Almanac Entry Selected</h1>
          </div>
        )}
      </div>
    </div>
  );
}
