import React, { useState, useEffect } from "react";
import { FormField, FormSelect } from "../../../components/formElements/formField";
import { useCSAMemberStore } from "../../../dataStore/CSAMemberStore";
import { useCSATierStore } from "../../../dataStore/CSATierStore";
import { useCSAPeriodStore } from "../../../dataStore/CSAPeriodStore";
import CSVReader from "../../../components/csvUploader";

export default function CSAMemberImport() {
  const periods = useCSAPeriodStore((state) => state.objects);
  const fetchPeriods = useCSAPeriodStore((state) => state.fetch);
  const tiers = useCSATierStore((state) => state.objects);
  const fetchTiers = useCSATierStore((state) => state.fetch);

  const [loaded, setLoaded] = useState(true);
  const create = useCSAMemberStore(state => state.add);
  const [view, setView] = useState("UPLOAD");
  const [csvData, setCSVData] = useState([]);
  const [csvHeader, setCSVHeader] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(false);

  useEffect(() => {
    async function fetchData() {
      fetchPeriods();
      fetchTiers();

      setLoaded(true);
    }

    if (!loaded) fetchData()
  })

  function updateData(results) {
    const header = results.data.shift(1)
    const data = results.data;
    setCSVHeader(header);
    setCSVData(data);
    parseCSVData(header, data);
  }

  function saveMembers() {
    members.forEach(async member => {
      await create(member);
    })
  }

  function parseCSVData(header, data) {
    let _members = [];
    data.forEach((row, rowIndex) => {
      let newMember = {
        email: "",
        name: "",
        address : "",
        phone : "",
        csa_tier : false,
        csa_period : selectedPeriod,
        subscription : {
          box_size : "SMALL",
          delivered : false,
          salad_extra : false,
          is_discounted : false,
          payment_terms : "WEEKLY",
          payment_method : "GC",
        },
        gocardless : {
          mandate_id : "",
          customer_id : "",
          subscription_id : ""
        }
      }
      row.forEach((val, idx) => {
        const HEADER = header[idx];
        switch (HEADER) {
          case "NAME":
            newMember.name = val.trim();
            break;
          case "EMAIL_ADDRESS":
            newMember.email = val.trim();
            break;
          case "PHONE":
            newMember.phone = val.trim();
            break;
          case "ADDRESS":
            newMember.address = val.trim();
            break;
          case "PAYMENT_TERMS":
            newMember.subscription.payment_terms = val.trim().toUpperCase();
            break;
          case "DELIVERED":
            newMember.subscription.delivered = (parseInt(val) === 1);
            break;
          case "SALAD_EXTRA":
            newMember.subscription.salad_extra = (parseInt(val) === 1);
            break;
          case "DISCOUNT":
            newMember.subscription.is_discounted = (parseInt(val) === 1);
            break;
        }
      })
      const TIER_INDEX = header.indexOf("TIER");
      for (let [tierId, tier] of Object.entries(tiers)) {
        if (tier.tierCode === row[TIER_INDEX].toUpperCase()) {
          newMember.csa_tier = tier;
        }
      }

      _members.push(newMember);
    })

    setMembers(_members);
    setView("TABLE");
  }

  function tableHeader() {
    let rows = csvHeader.map(el => {
      return (<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{el}</th>)           
    })
    rows.push((<th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Action</th>));
    return rows;
  }

  function tableRow(member) {
    let rows = csvHeader.map(header => {
      let content = "";
      switch (header) {
        case "NAME":
          content = member.name;
          break;
        case "EMAIL_ADDRESS":
          content = member.email;
          break;
        case "PHONE":
          content = member.phone;
          break;
        case "ADDRESS":
          content = member.address;
          break;
        case "PAYMENT_TERMS":
          content = member.subscription.payment_terms;
          break;
        case "DELIVERED":
          content = member.subscription.delivered ? "TRUE" : "FALSE";
          break;
        case "SALAD_EXTRA":
          content = member.subscription.salad_extra ? "TRUE" : "FALSE";
          break;
        case "DISCOUNT":
          content = member.subscription.is_discounted ? "TRUE" : "FALSE";
          break;
        case "TIER":
          content = member.csa_tier.tierName;
          break;
      }
      return (<td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{content}</td>);
    })

    return (
      <tr className="hover:bg-gray-100">
        {rows}
        <td className="px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
          <a className="text-blue-500 hover:text-blue-700" href="#">Delete</a>
        </td>
      </tr>
    )
  }

  function table() {
    return (
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <button
                type="button"
                className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
                onClick={() => {saveMembers()}}
              >
                Add Members
              </button>
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr>
                    {tableHeader()}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {members.map(el => { return tableRow(el) })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
    
  }

  function periodSelect() {
    let period_options = [];
    for (let [periodId, period] of Object.entries(periods)) {
      period_options.push((
        <option value={periodId} key={periodId}>{period.periodName}</option>
      ))
    }
    return (
      <select 
        className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
        value={selectedPeriod}
        onChange={(e) => setSelectedPeriod(e.target.value)}
      >
        {period_options}
      </select>
    )
  }

  function renderView() {
    switch (view) {
      case "UPLOAD":
        return (
          <div className="w-full md:w-1/2">
            {periodSelect()}
            <CSVReader onFinish={updateData} />
          </div>
        )
        break;
      case "TABLE":
        return (table());
    }
  }

  return (
    <div className="absolute inset-0 z-50 px-20 py-16">
      <div className="fixed inset-0 bg-slate-900 opacity-50 z-40"></div>

      <div className="relative rounded bg-white px-4 py-2 z-50 h-full overflow-x-hidden overflow-y-scroll">
        <h1>Import CSA Members</h1>

        {renderView()}
      </div>      
    </div>
  );
}
