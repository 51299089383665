import React, { useEffect, useState } from "react";
import Select from 'react-select';
import FormCard from "../formElements/formCard";
import FormInput, { FormSelect } from "../formElements/formField";
import { useCropProfileStore } from "../../dataStore/ProfileStore";
import { useSpeciesStore } from "../../dataStore/SpeciesStore";
import { ProfileTimingCard } from "./timeCard";
import EstablishmentCard from "./establishmentCard";
import HarvestCard from "./harvestCard";
import swal from "sweetalert";


export function ProfileHeader(props) {
  const [editing, setEditing] = useState(false);
  const species = useSpeciesStore((state) => state.species);
  const deleteProfile = useCropProfileStore((state) => state.deleteProfile);
  const profile = props.profile;

  function _dsObjToSelectArr(obj) {
    let o = [];
    for (let [i, op] of Object.entries(obj)) {o.push(op)}
    return o;
  }

  useEffect(() => {
    if (profile.species === null) {
      setEditing(true);
    }
  }, [profile]);

  function startDelete() {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this profile!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        await deleteProfile(profile._id);
        swal("Poof! Your profile has been deleted!", {
          icon: "success",
        });
      }
    });
  }
  function startDuplication() {

  }
  function saveProfileName() {
    props.saveProfile(profile._id);
    setEditing(false);
  }

  return (
    <div className="px-3 py-2 flex gap-4 bg-white shadow-md items-center">
    {(editing) ? (
      <>
        <Select 
          className='block min-w-[200px] border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
          value={profile.species}
          options={_dsObjToSelectArr(species)} 
          getOptionLabel={el => el.commonName}
          getOptionValue={el => el}
          onChange={(val) => props.updateProfile(profile._id, { species : val })}
        />
        <FormInput
          name="VARIATION_NAME"
          placeholder="Variation Name"
          value={profile.variationName}
          label={false}
          title="Variation Name"
          onChange={(e) => props.updateProfile(profile._id, {variationName : e.target.value})}
        />
        <button 
          onClick={saveProfileName}
          className='flex text-xs items-center gap-1 text-blue-500 hover:text-blue-600 hover:underline transition-all'
        >
          SAVE
        </button>
      </>
    ) : (
      <div className='cursor-pointer' onClick={(e) => {setEditing(true)}}>
        <h1 className="text-3xl"><b>{profile.species?.commonName}</b><span className="ml-2">- {profile.variationName}</span></h1>
        <p className="text-sm italic">{profile.species?.binomialName}</p>
      </div>
    )}
    
      
      <div className="flex-1"></div>
      
      <div className="flex gap-3 h-7">
        <button 
          type="button" 
          className="py-1.5 px-2.5 inline-flex justify-center items-center gap-2 rounded-md border-2 border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none transition-all text-xs"
          onClick={startDelete}
        >
          Delete
        </button>
        <button 
          type="button" 
          className="py-1.5 px-2.5 inline-flex justify-center items-center gap-2 rounded-md border-2 border-sky-500 font-semibold text-sky-500 hover:bg-sky-600 hover:text-white focus:outline-none transition-all text-xs">
          Duplicate
        </button>
        <button 
          type="button" 
          className="py-1.5 px-2.5 inline-flex justify-center items-center gap-2 rounded-md border-2 border-transparent font-semibold bg-emerald-500 text-white hover:bg-emerald-600 focus:outline-none transition-all text-xs"
          onClick={() => {props.saveProfile(profile._id)}}
        >
          Save
        </button>
      </div>
    </div>
  )
  
}

export function ProfileForm(props) {
  const profile = props.profile;
  const updateProfile = useCropProfileStore(state => state.updateProfile);

  function onUpdate(value) {
    updateProfile(profile._id, value);
  }

  return (
    <div className="relative px-6 py-4">
      <div className="overflow-scroll pt-5">
        <ProfileTimingCard profile={profile} />
        <FormCard className="mb-2" cardName="Establishment" small>
          <EstablishmentCard 
            profile={profile}
            onUpdate={onUpdate} 
          />
        </FormCard>

        <FormCard className="mb-2" cardName="Harvest" small>
          <HarvestCard 
            profile={profile}
            onUpdate={onUpdate}
          />
        </FormCard>

        <FormCard cardName="Nutrient Management" small>
          <p className="text-sm">...</p>
        </FormCard>

        <FormCard cardName="Pest Management" small>
          <p className="text-sm">...</p>
        </FormCard>

      </div>
    </div>
  )
}