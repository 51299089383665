import React, { useState } from "react";

export function InlineSelectInput(props) {
  const [value, setValue] = useState(props.value);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState(props.options);
  const [option, setOption] = useState(null);

  function flatten() {
    let list = [];
    for (let [optionId, option] of Object.entries(options)) {list.push(option)}
    return list;
  }
  function bulge(arr) {
    let opt = {};
    arr.forEach(a => {opt[a._id] = a});
    return opt;
  }

  function onChange(e) {
    setValue(e.target.value);
    if (e.target.value !== '') {
      let _options = options;
      if (typeof options === "object") {
        _options = flatten();
      }
      let results = _options.filter((option) => {
        return option[props.selector].toLowerCase().startsWith(value.toLowerCase());
      });
      if (typeof options === "object") {
        results = bulge(results);
      }
      setOptions(results);
    } else {
      setOptions(props.options);
    }
    setShowOptions(true);
  }

  function setInputOption(option) {
    setValue(option[props.selector]);
    setOption(option);
    setShowOptions(false);
  }

  function optionList() {
    let itemClass = "hover:bg-blue-700 cursor-pointer hover:text-white";
    if (typeof options === "object") {
      let list = [];
      for (let [optionId, option] of Object.entries(options)) {
        list.push(<li className={itemClass} onClick={() => {setInputOption(option)}} key={optionId}>
          <p>{option[props.selector]}</p>
        </li>)
      }
      return list;
    } else if (typeof options === "array") {
      return options.map(option => {
        return (<li className={itemClass} onClick={() => {setInputOption(option)}} key={option._id}>
          <p>{option[props.selector]}</p>
        </li>)
      })
    }
    
  }

  return (
    <div className="relative">
      { (props.label) ? <label className="inline-block mb-1 text-sm font-medium text-gray-700" htmlFor={props.name}>{props.title}</label> : <></> }
        
      <input
        name={props.name}
        required=""
        type={props.type}
        value={value}
        placeholder={props.placeholder}
        onChange={onChange}
        onFocus={() => {setShowOptions(true)}}
        className="leading-none text-2xl border-b border-b-slate-700 hover:border-b-blue-500 focus:border-b-blue-700 outline-none bg-transparent"
      />

      {(showOptions) ? (
        <div className="absolute w-full bg-white z-40 max-h-40 overflow-scroll shadow-sm">
          <ul>
            {optionList()}
          </ul>
        </div>
      ) : (<></>)}
    </div>
  )
}

export function InlineInput(props) {
  return (
    <div className="my-2 w-full">
      { (props.label) ? <label className="inline-block mb-1 text-sm font-medium text-gray-700" htmlFor={props.name}>{props.title}</label> : <></> }
        
      <input
        name={props.name}
        required=""
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className="leading-none py-1 px-2 border-b w-full border-b-slate-700 hover:border-b-blue-500 focus:border-b-blue-700 outline-none bg-transparent"
      />

    </div>
  )
}