import React, { useState, useEffect } from "react";
import { useAuthStore } from "../../dataStore/auth";

import FormCard from "../../components/formElements/formCard";
import FormField from "../../components/formElements/formField";

export default function UserSettingsPage() {
  const user = useAuthStore((state) => state.user);

  function updateUser(value) {
    return useAuthStore.setState({ ...value });
  }

  return (
    <div className="absolute inset-0">
      <div className="grid grid-rows-planning-contain h-full">
        <div className="border-b-black border border-b-solid">
          <div className="flex">
            <div className="px-3 py-3">
              <h1 className="text-3xl text-blue-600">User Settings</h1>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 relative px-12 py-8">
          <div className="w-full lg:w-1/2 4xl:w-1/3">
            <div className="space-y-10">
              <FormCard cardName="Your Account Settings">
                <div className="grid grid-cols-2 gap-2">
                  <FormField
                    name="FNAME"
                    title="First Name"
                    placeholder=""
                    hint={false}
                    onChange={(e) => {updateUser({first_name:e.target.value})}}
                    value={user.first_name}
                  />
                  <FormField
                    name="LNAME"
                    title="Last Name"
                    placeholder=""
                    hint={false}
                    onChange={(e) => {updateUser({last_name:e.target.value})}}
                    value={user.last_name}
                  />
                </div>
                <FormField
                  name="Email"
                  title="Email"
                  placeholder=""
                  type="email"
                  hint={false}
                  onChange={(e) => {updateUser({email:e.target.value})}}
                  value={user.email}
                />
                <FormField
                  name="PASSWORD"
                  title="Password"
                  placeholder=""
                  type="password"
                  hint={false}
                  onChange={(e) => {updateUser({password:e.target.value})}}
                  value={user.password}
                />
              </FormCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
