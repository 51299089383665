import React, { useState, useEffect } from "react";
import { useCSAPeriodStore } from "../../../dataStore/CSAPeriodStore";
import { useCSATierStore } from "../../../dataStore/CSATierStore";
import { PlusIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import swal from "sweetalert";
import FormCard from "../../../components/formElements/formCard";

function CSAPeriods() {
  const periods = useCSAPeriodStore((state) => state.objects);
  const addPeriod = useCSAPeriodStore((state) => state.add);
  const fetchPeriods = useCSAPeriodStore((state) => state.fetch);
  
  const [loaded, setLoaded] = useState(false);
  const [addingPeriod, setAddingPeriod] = useState(false);
  const [newPeriod, setNewPeriod] = useState({
    periodName : "SPRING 23",
    periodStart : "2023-08-30",
    periodEnd : Date.now(),
    weeks : 13
  });

  useEffect(() => {
    async function loadData() {
      await fetchPeriods();
      setLoaded(true);
    }

    if (!loaded) loadData();
  });

  function updateEndDate(weeks, start) {
    let end = dayjs(start).add(weeks, 'weeks');
    setNewPeriod({ ...newPeriod, periodEnd : end, periodStart : start, weeks : weeks });
  }

  async function createNewPeriod() {
    const period = {
      periodName : newPeriod.periodName,
      periodStart : dayjs(newPeriod.periodStart).toDate(),
      periodEnd : dayjs(newPeriod.periodEnd).toDate(),
      weeks : newPeriod.weeks
    }
    
    await addPeriod(period);
    setAddingPeriod(false);
  }

  function NewPeriodForm() {
    return (
      <div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2 max-w-lg">
          <div>
            <label className='text-xs font-bold pl-1'>Period Name</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={newPeriod?.periodName}
              onChange={(e) => {setNewPeriod({ ...newPeriod, periodName : e.target.value });}}
            />
          </div>
          
          <div>
            <label className='text-xs font-bold pl-1'>Start Date</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={newPeriod?.periodStart}
              type="date"
              onChange={(e) => {updateEndDate(newPeriod.weeks, e.target.value)}}
            />
          </div>

          <div>
            <label className='text-xs font-bold pl-1'>Period Weeks</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={newPeriod?.weeks}
              type="number"
              onChange={(e) => {updateEndDate(e.target.value, newPeriod.periodStart)}}
            />
          </div>

          <div>
            <label className='text-xs font-bold pl-1'>End Date</label>
            <p>{dayjs(newPeriod.periodEnd).format("DD/MM/YYYY")}</p>
          </div>
          <button
            className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={createNewPeriod}
          >Create Period</button>
        </div>
      </div>
    )
  }

  function viewPeriods() {
    let d = [];
    for (let [periodId, period] of Object.entries(periods)) {
      d.push((
        <CSAPeriod period={period} periodId={periodId} />
      ))
    }
    return d;
  }

  return (
    <div>
      <div className="flex gap-4 items-center mb-3">
        <h2 className="text-lg flex-1">CSA Period</h2>
        <button
          type="button"
          className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
          onClick={() => {setAddingPeriod(true)}}
        >
          Add Period
          <PlusIcon className="text-white w-4 h-4 ml-2 inline-block"/>
        </button>
      </div>

      <div>
        {(addingPeriod) ? NewPeriodForm() : (<></>)}
        {viewPeriods()}
      </div>
    </div>
  )
}

function CSAPeriod(props) {
  const savePeriod = useCSAPeriodStore((state) => state.save);
  const deletePeriod = useCSAPeriodStore((state) => state.delete);

  function startDeletePeriod(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this CSA Period!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        await deletePeriod(id);
        swal("Poof! Your period has been deleted!", {
          icon: "success",
        });
      }
    });
  }

  return (
    <FormCard
      cardName={props.period.periodName}
      key={props.periodId}
      className="mb-3"
      small={true}
    >
      <p>{dayjs(props.period.periodStart).format("DD MMM YY")} - {dayjs(props.period.periodEnd).format("DD MMM YY")}</p>
      <p className="text-sm italic">{props.period.weeks} weeks</p>

      <div>
        {props.period.status.active ? (
          <span className="px-2 py-1 rounded text-white bg-emerald-400 text-xs">ACTIVE PERIOD</span>
        ) : (
          <></>
        )}

        {props.period.status.accepting ? (
          <span className="px-2 py-1 rounded text-white bg-emerald-400 text-xs">SIGN UPS ACCEPTED</span>
        ) : (
          <></>
        )}
      </div>

      <p>{props.period.status.accepting}</p>
    </FormCard>
  )
}

function CSATiers() {
  const tiers = useCSATierStore((state) => state.objects);
  const addTier = useCSATierStore((state) => state.add);
  const fetchTiers = useCSATierStore((state) => state.fetch);
  const deleteTier = useCSATierStore((state) => state.delete);

  const [loaded, setLoaded] = useState(false);
  const [addingTier, setAddingTier] = useState(false);
  const [newTier, setNewTier] = useState({
    tierName : "SMALL",
    tierCode : "SM_BX",
    tierDescription : "A small box",
    
    price : {
      basePrice : 35,
      deliveryPrice : 7
    }
  })

  useEffect(() => {
    async function loadData() {
      await fetchTiers();
      setLoaded(true);
    }

    if (!loaded) loadData();
  });

  async function createNewTier() {      
    await addTier(newTier);
    setAddingTier(false);
  }

  function NewTierForm() { 
    return (
      <div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2 max-w-lg">
          <div>
            <label className='text-xs font-bold pl-1'>Tier Name</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={newTier?.tierName}
              onChange={(e) => {setNewTier({ ...newTier, tierName : e.target.value });}}
            />
          </div>
          
          <div>
            <label className='text-xs font-bold pl-1'>Tier Code</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={newTier?.tierCode}
              onChange={(e) => {setNewTier({ ...newTier, tierCode : e.target.value });}}
            />
          </div>

          <div>
            <label className='text-xs font-bold pl-1'>Base Price ($)</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={newTier?.price.basePrice}
              type="number"
              onChange={(e) => {setNewTier({ ...newTier, price : { ...newTier.price, basePrice : e.target.value }});}}
            />
          </div>

          <div>
            <label className='text-xs font-bold pl-1'>Delivery Price ($)</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={newTier?.price.deliveryPrice}
              type="number"
              onChange={(e) => {setNewTier({ ...newTier, price : { ...newTier.price, deliveryPrice : e.target.value }});}}
            />
          </div>
          <button
            className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={createNewTier}
          >Create Tier</button>
        </div>
      </div>
    )
  }

  
  function startDeleteTier(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this CSA Tier!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        await deleteTier(id);
        swal("Poof! Your period has been deleted!", {
          icon: "success",
        });
      }
    });
  }

  function viewTiers() {
    let d = [];
    for (let [tierId, tier] of Object.entries(tiers)) {
      d.push((
        <FormCard 
          cardName={`${tier.tierName} (${tier.tierCode})`}
          className="mb-3"
          small={true}
          key={tierId}
        >
          <p>${tier.price?.basePrice}</p>
          <p>${tier.price?.deliveryPrice} delivery</p>
          <button
            className="rounded text-white text-sm bg-red-500 hover:bg-red-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={(e) => startDeleteTier(tierId)}
          >Delete Tier</button>
        </FormCard>
      ))
    }
    return d;
  }

  return (
    <div>
      <div className="flex gap-4 items-center mb-3">
        <h2 className="text-lg flex-1">CSA Tiers</h2>
        <button
          type="button"
          className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
          onClick={() => {setAddingTier(true)}}
        >
          Add Tier
          <PlusIcon className="text-white w-4 h-4 ml-2 inline-block"/>
        </button>
      </div>

      <div>
        {(addingTier) ? NewTierForm() : (<></>)}
        {viewTiers()}
      </div>
    </div>
  )
}

function CSATier(){

}

export default function CSASettingsPage() {
  const periods = useCSAPeriodStore((state) => state.objects);
  const addPeriod = useCSAPeriodStore((state) => state.add);
  const fetchPeriods = useCSAPeriodStore((state) => state.fetch);
  const deletePeriod = useCSAPeriodStore((state) => state.delete);

  return (
    <div className="">
      <div className="grid grid-cols-2 gap-x-10 items-start">
        {CSAPeriods()}

        {CSATiers()}
      </div>
    </div>
  );
}
