import React from 'react';
import { FlowCard } from './flowCardElements';

export default function FertilisationNode(props) {
  const taskData = props.taskData || {};

  return (
    <FlowCard title="FERTILISATION">
      <div className='grid grid-cols-2 gap-2'>
        <div>
          <label className='text-xs font-bold pl-1'>Type</label>
          <select 
            className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
          >
            <option value="DIRECT">Solid</option>
            <option value="CELL_TRAY">Liquid</option>
          </select>
        </div>
        <div>
          <label className='text-xs font-bold pl-1'>Method</label>
          <select 
            className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
          >
            <option value="DIRECT">Broadcast</option>
            <option value="CELL_TRAY">Foliar</option>
            <option value="CELL_TRAY">Drench/Inject</option>
            <option value="CELL_TRAY">Fertigation</option>
          </select>
        </div>
        <div>
          <label className='text-xs font-bold pl-1'>Row Spacing</label>
          <input className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'></input>
        </div>
        <div>
          <label className='text-xs font-bold pl-1'>Cover</label>
          <input className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'></input>
        </div>
      </div>
    </FlowCard>
  );
}