import React, { useState, useEffect } from "react";
import { useFarmStore } from "../../dataStore/FarmStore";
import { useGoogleStore } from "../../dataStore/GoogleStore";
import FormCard from "../../components/formElements/formCard";
import { FormField, FormSelect } from "../../components/formElements/formField";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

export default function SalesSettingsPage() {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const googleProfile = useGoogleStore((state) => state.profile);
  const fetchProfile = useGoogleStore((state) => state.fetchProfile);
  const beginOAuth = useGoogleStore((state) => state.beginOAuth);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await fetchProfile()

      setLoaded(true);
      setLoading(false);
    }

    if (!loaded && !loading)
      fetchData();
  });

  async function startGoogleConnect() {
    const OAUTH_URI = await beginOAuth();
    window.location = OAUTH_URI;
  }

  return (
    <div className="absolute inset-0">
      <div className="grid grid-rows-planning-contain h-full">
        <div className="border-b-black border border-b-solid">
          <div className="flex">
            <div className="px-3 py-3">
              <h1 className="text-3xl text-blue-600">Sales Settings</h1>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 relative px-12 py-8 overflow-scroll">
          <div className="w-full lg:w-1/2 4xl:w-1/3">
            <form action="#">
              <div className="space-y-10">
                <FormCard cardName="Email Settings">
                  {(!googleProfile) ? (
                    <button
                      className="inline-flex justify-center items-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 w-1/3 2xl:w-1/4 text-white bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus:ring-blue-600"
                      type="submit"
                      disabled={loading}
                      onClick={startGoogleConnect}
                    >
                      Connect your google account
                      <CheckBadgeIcon className="text-white w-5 h-5 ml-4"></CheckBadgeIcon>
                    </button>
                  ) : (
                    <>
                    <p>Your Gmail Account is connected:</p>
                    <p className="font-bold">{googleProfile.name}</p>
                    <p className="italic">{googleProfile.email}</p>
                    </>
                  )}
                </FormCard>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
