import React, { useState, useEffect } from "react";
import { useFarmStore } from "../../dataStore/FarmStore";
import { useAuthStore } from "../../dataStore/auth";
import { useCellTrayStore } from "../../dataStore/CellTrays";
import FormCard from "../../components/formElements/formCard";
import { FormField, FormSelect } from "../../components/formElements/formField";

function NewUserForm() {
  const createFarmUser = useAuthStore((state) => state.registerFarmer);
  const [form, setForm] = useState({
    firstName : "",
    lastName : "",
    email : "",
    password : ""
  });

  function updateForm(value) {
    return setForm((prev) => {
        const _new = { ...prev, ...value };
        return _new;
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    const registerResponse = await createFarmUser(form);
  }

  return (
    <div className="flex flex-col items-center justify-center align-middle px-10">
      <div className="w-full">
        <div className="grid grid-cols-2 gap-2">
          <FormField
            name="FNAME"
            title="First Name"
            placeholder=""
            hint={false}
            onChange={(e) => {updateForm({firstName:e.target.value})}}
          />
          <FormField
            name="LNAME"
            title="Last Name"
            placeholder=""
            hint={false}
            onChange={(e) => {updateForm({lastName:e.target.value})}}
          />
        </div>
        <FormField
          name="Email"
          title="Email"
          placeholder=""
          type="email"
          hint={false}
          onChange={(e) => {updateForm({email:e.target.value})}}
        />
        <FormField
          name="PASSWORD"
          title="Password"
          placeholder=""
          type="password"
          hint={false}
          onChange={(e) => {updateForm({password:e.target.value})}}
        />
        <div className="flex p-0 items-center">
          <button
            className="flex-0 inline-flex justify-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 w-1/3 2xl:w-1/4 text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-600"
            onClick={onSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}

function NewTrayForm() {
  const createCellTray = useCellTrayStore((state) => state.createCellTray);
  const [form, setForm] = useState({
    trayName : "New Cell Tray",
    cellCount : 144
  });

  function updateForm(value) {
    return setForm((prev) => {
        const _new = { ...prev, ...value };
        return _new;
    });
  }

  async function onSubmit(e) {
    e.preventDefault();
    const createResponse = await createCellTray(form);
    alert("tray created");
    setForm({
      trayName : "New Cell Tray",
      cellCount : 144
    });
  }

  return (
    <div className="flex flex-col items-center justify-center align-middle px-10">
      <div className="w-full">
        <div className="grid grid-cols-2 gap-2">
          <FormField
            name="NAME"
            title="Tray Name"
            value={form.trayName}
            placeholder=""
            hint={false}
            onChange={(e) => {updateForm({trayName:e.target.value})}}
          />
          <FormField
            name="COUNT"
            title="Cell Count"
            type="number"
            placeholder=""
            value={form.cellCount}
            hint={false}
            onChange={(e) => {updateForm({cellCount:e.target.value})}}
          />
        </div>
        <div className="flex p-0 items-center">
          <button
            className="flex-0 inline-flex justify-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 w-1/3 2xl:w-1/4 text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-600"
            onClick={onSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}

export default function FarmSettingsPage() {
  const farm = useFarmStore((state) => state.farm);
  const farmLoaded = useFarmStore((state) => state.loaded);
  const fetchFarm = useFarmStore((state) => state.fetch);
  const updateFarm = useFarmStore((state) => state.updateFarm);
  const saveFarm = useFarmStore((state) => state.saveFarm);
  const cellTrays = useCellTrayStore((state) => state.cellTrays);
  const cellTraysLoaded = useCellTrayStore((state) => state.loaded);
  const fetchCellTrays = useCellTrayStore((state) => state.fetch);

  const users = useAuthStore((state) => state.users);
  const fetchFarmers = useAuthStore((state) => state.fetchFarmers);

  useEffect(() => {
    async function fetchData() {
      await fetchFarm();
      await fetchCellTrays();
      await fetchFarmers();
    }
    if (!farmLoaded && !cellTraysLoaded) {
      fetchData();
    }
  }, [farmLoaded, cellTraysLoaded]);

  function viewUsers() {
    return users.map(user => {
      return (
        <div
          key={user._id}
          className="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px rounded-lg hover:text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600">
          <p>{user.first_name} {user.last_name}</p>
          <p>{user.email}</p>
        </div>
      )
    })
  }

  function viewCellTrays() {
    let a = [];
    for (let [trayId, tray] of Object.entries(cellTrays)) {
      a.push((
        <div
          key={trayId}
          className="inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px rounded-lg hover:text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600">
          <p>{tray.trayName} {tray.cellCount}</p>
        </div>
      ))
    }
    return a;
  }

  if (!farmLoaded) {
    return (<p>loading...</p>)
  }

  return (
    <div className="absolute inset-0">
      <div className="grid grid-rows-planning-contain h-full">
        <div className="border-b-black border border-b-solid">
          <div className="flex">
            <div className="px-3 py-3">
              <h1 className="text-3xl text-blue-600">Farm Settings</h1>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 relative px-12 py-8 overflow-scroll">
          <div className="w-full lg:w-1/2 4xl:w-1/3">
            <form action="#">
              <div className="space-y-10">
                <FormCard cardName="Farm Details">
                  <FormField
                    name="FARM_NAME"
                    title="Farm Name"
                    placeholder="Johnny's Apple Farm"
                    value={farm?.details?.name}
                    onChange={(e) => {updateFarm({details : { name : e.target.value }})}}
                    hint={false}
                  />
                </FormCard>

                <FormCard cardName="Farm User Accounts">
                  {viewUsers()}
                  <hr />
                  <NewUserForm />
                </FormCard>

                <FormCard cardName="Units & Timezones">
                  <FormSelect
                    name="FARM_UNITS"
                    title="Farm Units"
                    placeholder="Unit Systems"
                    options={['Metric', 'Imperial']}
                    value={farm.units || "Metric"}
                    hint={true}
                  />
                </FormCard>

                <FormCard cardName="Cell Trays">
                  {viewCellTrays()}
                  <hr />
                  <NewTrayForm />
                </FormCard>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
