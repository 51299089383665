import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { FormField } from "../formElements/formField";
import { useCropProfileStore } from "../../dataStore/ProfileStore";
import { useCroppingLocationStore } from "../../dataStore/CroppingLocationStore";
import { useCropStore } from "../../dataStore/CropStore";
import { MediumFormLoader, SmallSectionLoader } from "../loaders";
import dayjs from "dayjs";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

export default function NewCropModal(props) {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const fetchProfiles = useCropProfileStore((state) => state.fetch);
  const profiles = useCropProfileStore((state) => state.profiles);
  const fetchLocations = useCroppingLocationStore((state) => state.fetch);
  const croppingLocations = useCroppingLocationStore((state) => state.croppingLocations);
  const createCrop = useCropStore((state) => state.add);
  const [beds, setBeds] = useState([]);

  const [newCrop, setNewCrop] = useState({
    location : null,
    species : null,
    profile : null,
    establishmentType : "DIRECT",
    cropType : "CROP",
    croppingLength : {
      rows : 5,
      length : 20
    },
    directSow : null,
    transplant : null,
    spacing : null,
    harvest : null,
    timing : null,
    tasks : [],
    timestamps : {
      startedDate : dayjs(),
      endDate : null,
      lastUpdate : dayjs(),
      createdAt : dayjs()
    },
    status : 'CREATED',
    state : {
      started : false,
      planted : false,
      seeded : false,
      growing : false,
      harvesting : false,
      harvested : false,
      needsAction : false,
      terminated : false,
      active : true
    }
  })

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      await fetchProfiles();
      await fetchLocations();
      let _beds = [];
      for (let [id, location] of Object.entries(croppingLocations)) {
        if (location.locationType === "BED") {
          _beds.push(location);
        }
      }
      setBeds(_beds);
      setLoaded(true);
      setLoading(false);
    }

    if (!loaded) fetchData();
  })

  async function finish() {
    setSaving(true);
    await createCrop(newCrop);

  }

  function _dsObjToSelectArr(obj) {
    let optionArray = [];
    for (let [optionId, option] of Object.entries(obj)) optionArray.push(option)
    return optionArray;
  }

  function setProfile(profile) {
    setNewCrop({ ...newCrop, 
      profile : profile, 
      species : profile.species,
      establishmentType : profile.establishmentType,
      cropType : profile.cropType,
      croppingLength : {
        rows : Number,
        length : Number
      },
      directSow : profile.directSow,
      transplant : profile.transplant,
      spacing : profile.spacing,
      harvest : profile.harvest,
      timing : profile.timing,
      timestamps : {
        startedDate : dayjs(),
        endDate : dayjs().add(profile.timing.DTM+profile.timing.DOM, 'days'),
        lastUpdate : dayjs(),
        createdAt : dayjs()
      }
    })
  }
  function setLocation(location) {
    setNewCrop({ ...newCrop, location : location })
  }

  return (
    <div className="absolute inset-0 z-50 px-20 py-16">
      <div className="fixed inset-0 bg-slate-900 opacity-50 z-40"></div>

      <div className="relative rounded bg-white px-4 py-2 z-50 h-full overflow-x-hidden overflow-y-scroll">
        <div className="grid grid-cols-1 gap-10">

        <div className="w-[700px] relative place-self-center">
          <h1 className="font-bold text-xl my-3">Create New Crop</h1>
          <div className="relative card border border-emerald-400 shadow-md bg-white px-5 py-1">
            {(!loaded || loading) ? (
            <div className="mx-2 my-4">
              <SmallSectionLoader />
            </div>) : (
            <>
            <div>
              <label className="inline-block mb-1 text-sm font-medium text-gray-700">Profile</label>
              <Select 
                value={newCrop.profile}
                options={_dsObjToSelectArr(profiles)} 
                getOptionLabel={el => `${el.species.commonName} ${el.variationName}`}
                getOptionValue={el => el._id}
                onChange={setProfile}
                isDisabled={saving}
              />
              <label className="inline-block mb-1 text-sm font-medium text-gray-700">Location</label>
              <Select 
                value={newCrop.location}
                options={_dsObjToSelectArr(beds)} 
                getOptionLabel={el => `${el.parent.name}${el.name}`}
                getOptionValue={el => el._id}
                onChange={setLocation}
                isDisabled={saving}
              />

              <div className="grid grid-cols-2 gap-5">
                <FormField
                  name="ROWS"
                  title="Rows"
                  placeholder="4"
                  type="number"
                  hint={false}
                  label={true}
                  // onChange={(e) => {updateForm({email : e.target.value})}}
                />
                <FormField
                  name="LENGTH"
                  title="Bed Meters"
                  placeholder="20"
                  type="number"
                  hint={false}
                  label={true}
                  // onChange={(e) => {updateForm({email : e.target.value})}}
                />
              </div>
            </div>
            </>
            )}
          </div>
        </div>

        <div className="w-[700px] relative place-self-center">
          <h1 className="font-serif text-sm ml-6 mb-1 font-bold text-gray-500">CROP TIMING</h1>
          <div className="relative card border border-emerald-400 shadow-md bg-white px-5 py-1">
            {(!newCrop.profile) ? (
            <div className="mx-2 my-4">
              <MediumFormLoader />
            </div>
            ) : (
            <>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p>Establishment Method: {newCrop.establishmentType}</p>
                <p>Establishment Date: {newCrop.timestamps.startedDate.format("DD MMM YYYY")}</p>
                <p>Germination Date: {newCrop.timestamps.startedDate.add(newCrop.timing.DTG, 'days').format("DD MMM YYYY")}</p>
              </div>
              {(newCrop.establishmentType === "TRANSPLANT") ? (
              <div>
                <p>Transplant Date: {newCrop.timestamps.startedDate.add(newCrop.timing.DIC, 'days').format("DD MMM YYYY")}</p>
              </div>
              ) : (<></>)}
              <div>
                <p>Maturity Date: {newCrop.timestamps.startedDate.add(newCrop.timing.DTM, 'days').format("DD MMM YYYY")}</p>
                <p>Harvest Length: {newCrop.timing.DOM} days</p>
                {(newCrop.establishmentType === "TRANSPLANT") ? (
                <p>Termination Date: {newCrop.timestamps.startedDate.add(newCrop.timing.DIF+newCrop.timing.DIC,'days').format("DD MMM YYYY")}</p>
                ) : (
                <p>Termination Date: {newCrop.timestamps.startedDate.add(newCrop.timing.DIF, 'days').format("DD MMM YYYY")}</p>
                )}
              </div>
            </div>
            <div className="mt-6 mb-3 flex items-center gap-3 p-0">
              <div className="flex-1"></div>
              <button
                className="inline-flex justify-center items-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 text-white bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus:ring-blue-600"
                disabled={loading || saving}
                onClick={finish}
              >
                Create Crop
                <CheckBadgeIcon className="text-white w-5 h-5 ml-4"></CheckBadgeIcon>
              </button>
            </div>
            </>)}
          </div>
        </div>
      </div>
      </div>      
    </div>
  );
}
