import React, { useEffect, useState } from "react";

export function ProfileTimingCard(props) {
  const { profile } = props;

  function calLabels() {
    return ["J","F","M","A","M","J","J","A","S","O","N","D"].map((mon, i) => {
      return (<p className="col-span-2 text-sm text-gray-600" key={`CROP_CAL_MON_${i}`}>{mon}</p>)
    })
  }
  function calTiles() {
    return profile.establishmentPeriod.map((b, i) => {
      let bg = b ? "bg-emerald-500" : "bg-emerald-100";
      return (
        <div className={`first:rounded-l first:border-l last:rounded-r last:border-r h-4 col-span-1 border-t border-b border-transparent  ${bg}`} key={`CROP_CAL_BOOL_${i}`}></div>
      )
    })
  }
  function cropTiming() {
    let labels = [];
    let tiles = [];
    let {DIC,DTM,DIF,DOM} = profile.timing;
    let TOTAL_TIME = DIF + DIC;
    if (profile.establishmentType === "TRANSPLANT") {
      labels.push((<p className={`col-span-2 text-sm text-gray-600`} style={{width : `${Math.floor((DIC/TOTAL_TIME)*100)}%`}}>Nursery ({DIC})</p>))
      tiles.push((
        <div 
          className={`first:rounded-l first:border-l last:rounded-r last:border-r h-4 col-span-1 border-t border-b border-transparent bg-emerald-200`} 
          style={{width : `${Math.floor((DIC/TOTAL_TIME)*100)}%`}}
        />
      ))
    }
    labels.push((<p className={`col-span-2 text-sm text-gray-600`} style={{width : `${Math.floor((DTM/TOTAL_TIME)*100)}%`}}>DTM ({DTM})</p>));
    labels.push((<p className={`col-span-2 text-sm text-gray-600`} style={{width : `${Math.floor((DOM/TOTAL_TIME)*100)}%`}}>Harvest ({DOM})</p>));
    tiles.push((
      <div 
        className={`first:rounded-l first:border-l last:rounded-r last:border-r h-4 col-span-1 border-t border-b border-transparent bg-emerald-400`} 
        style={{width : `${Math.floor((DTM/TOTAL_TIME)*100)}%`}}
      />
    ))
    tiles.push((
      <div 
        className={`first:rounded-l first:border-l last:rounded-r last:border-r h-4 col-span-1 border-t border-b border-transparent bg-emerald-600`} 
        style={{width : `${Math.floor((DOM/TOTAL_TIME)*100)}%`}}
      />
    ))

    return (
      <div className="px-0 py-0 items-center mb-5">
        <div className="flex gap-0">
          {labels}
        </div>
        <div className="flex gap-0">
          {tiles}
        </div>

      </div>
    )
  }

  return (
    <>
      <div className="px-0 py-0 items-center mb-2">
        <div className="grid grid-cols-cropTime gap-0">
          {calLabels()}
        </div>
        <div className="group mt-1 grid grid-cols-cropTime gap-0">
          {calTiles()}
        </div>

      </div>
      {cropTiming()}
    </>
  )
  
}