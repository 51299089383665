import React, { useState, useEffect } from "react";

import TaskNode from "../profileFlow/taskNode";
import WeedingNode from "../profileFlow/weedingNode";
import FertilisationNode from "../profileFlow/fertiliseNode";
import CoverNode from "../profileFlow/coverNode";
import { useCropProfileStore } from "../../dataStore/ProfileStore";
import { ArrowDownIcon, ArrowUpIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid';

const DEFAULT_TASK_OBJ = {
  "WEEDING" : {
    taskType : "WEEDING",
    timing : {
      definedAs : "ABSOLUTE",
      days : 21,
      calculatedDay: 21
    },
    implement : "HAND_HOE"
  },
  "COVERS" : {
    taskType : "COVERS",
    timing : {
      definedAs : "ABSOLUTE",
      days : 21,
      calculatedDay: 21
    },
    coverType : "INSECT",
    movementType : "ON"
  },
  "GENERAL_TASK" : {
    taskType : "GENERAL_TASK",
    timing : {
      definedAs : "ABSOLUTE",
      days : 21,
      calculatedDay: 21
    },
    taskTitle : "New Task",
    taskDescription : "..."
  },
  "FERTILISE" : {
    taskType : "FERTILISE",
    timing : {
      definedAs : "ABSOLUTE",
      days : 21,
      calculatedDay: 21
    },
    method : "FOLIAR",
    product : "BAM",
    rate : "1L/m2"
  }
}

export default function ProfileTimeline(props) {
  const profile = props.profile;
  const tasks = profile.tasks;
  const profiles = useCropProfileStore((state) => state.profiles);
  const updateProfile = useCropProfileStore((state) => state.updateProfile);
  const [addMenuStates, setAddMenuStates] = useState(new Array(tasks.length+1).fill(false));

  useEffect(() => {
    if (!tasks[0] || tasks[0]?.taskType !== "HOLD") {
      const newTasks = [
        { taskType : "HOLD" },
        ...tasks.slice()
      ];
      onUpdate({tasks : newTasks});
    }
    return;
}, [profile, tasks]);
  
  function onUpdate(value) {
    updateProfile(profile._id, value);
  }

  function addTask(atIndex, taskType) {
    const newTask = DEFAULT_TASK_OBJ[taskType];
    const newTasks = [
      ...tasks.slice(0, atIndex+1),
      newTask,
      ...tasks.slice(atIndex+1)
    ];
    const newAddMenuStates = [
      ...addMenuStates.slice(0, atIndex+1),
      false,
      ...addMenuStates.slice(atIndex+1)
    ];
    onUpdate({tasks : newTasks});
    setAddMenuStates(newAddMenuStates);
    toggleTaskAddMenu(atIndex);
  }

  function updateTask(atIndex) {
    return (task) => {
      const newTasks = [
        ...tasks.slice(0, atIndex),
        task,
        ...tasks.slice(atIndex+1)
      ];
      onUpdate({tasks : newTasks});
    }
  }
  function deleteTask(atIndex) {
    const newTasks = [
      ...tasks.slice(0, atIndex),
      ...tasks.slice(atIndex+1)
    ];
    onUpdate({tasks : newTasks});
  }
  function moveTask(atIndex, direction) {
    let newIndex = atIndex + direction;
    if (newIndex < 1 || newIndex >= tasks.length) return;
    const newTasks = [
      ...tasks.slice(0, atIndex),
      ...tasks.slice(atIndex)
    ];
    let tmp = tasks[atIndex];
    newTasks[atIndex] = tasks[newIndex];
    newTasks[newIndex] = tmp;
    onUpdate({tasks : newTasks});
  }

  function toggleTaskAddMenu(index) {
    let newMenuStates = new Array(tasks.length+1).fill(false);
    newMenuStates[index] = !addMenuStates[index];
    setAddMenuStates(newMenuStates);
  }

  function taskListTools(index) {
    return (
      <div className='relative w-full flex gap-4'>
        {(index > 1) ? 
          (<button 
            onClick={() => {moveTask(index, -1)}}
            className='flex text-xs items-center gap-1 text-blue-500 hover:text-blue-600 hover:underline transition-all'
          >
            <ArrowUpIcon 
              className="h-4 w-4 text-inherit"
            />
          </button>) : (<div className="w-[16px]" />)
        }
        {(index > 0 && index < tasks.length-1) ? 
          (<button 
            onClick={() => {moveTask(index, 1)}}
            className='flex text-xs items-center gap-1 text-blue-500 hover:text-blue-600 hover:underline transition-all'
          >
            <ArrowDownIcon 
              className="h-4 w-4 text-inherit"
            />
          </button>) : (<div className="w-[16px]" />)
        }
        {(index > 0) ? 
          (<button 
            onClick={() => {deleteTask(index)}}
            className='flex text-xs items-center gap-1 text-blue-500 hover:text-blue-600 hover:underline transition-all'
          >
            <TrashIcon 
              className="h-4 w-4 text-inherit"
            />
          </button>) : (<div className="w-[16px]" />)
        }
        <div className="flex-1" />
        <button 
          onClick={() => {toggleTaskAddMenu(index)}}
          className='flex text-xs items-center gap-1 text-blue-500 hover:text-blue-600 hover:underline transition-all'
        >
          <PlusCircleIcon 
            className="h-4 w-4 text-inherit"
          />
          ADD TASK
        </button>

        {(addMenuStates[index]) ? (
        <div className="absolute w-[150px] right-0">
          <div className="absolute w-full flex flex-col z-20 top-[20px]">
            <a 
              className="inline-flex items-center gap-x-3.5 py-2 px-3 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg hover:text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600" href="#"
              onClick={(e) => {addTask(index, "WEEDING")}}
            >
              Weeding
            </a>
            <a 
              className="inline-flex items-center gap-x-3.5 py-2 px-4 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg hover:text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600" href="#"
              onClick={(e) => {addTask(index, "GENERAL_TASK")}}
            >
              General Task
            </a>
            <a 
              className="inline-flex items-center gap-x-3.5 py-2 px-4 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg hover:text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600" href="#"
              onClick={(e) => {addTask(index, "COVERS")}}
            >
              Change Covers
            </a>
            <a 
              className="inline-flex items-center gap-x-3.5 py-2 px-4 text-xs font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg hover:text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600" href="#"
              onClick={(e) => {addTask(index, "FERTILISE")}}
            >
              Foliar/Fertilise
            </a>
          </div>
        </div>
        ) : (<></>)}
      </div>
    )
  }

  function taskList() {
    return tasks.map((task, index) => {
      let taskEl;
      switch (task.taskType) {
        case "GENERAL_TASK":
          taskEl = (<TaskNode key={index} profile={profile} task={task} updateTask={updateTask(index)} />)
          break;
        case "WEEDING":
          taskEl = (<WeedingNode key={index} profile={profile} task={task} updateTask={updateTask(index)} />)
          break;
        case "FERTILISE":
          taskEl = (<FertilisationNode key={index} profile={profile} task={task} updateTask={updateTask(index)} />)
          break;
        case "COVERS":
          taskEl = (<CoverNode key={index} profile={profile} task={task} updateTask={updateTask(index)} />)
          break;
        default:
          taskEl = (<></>);
          break;
      }
      return (
        <div className="my-2" key={index}>
          {taskEl}
          {taskListTools(index)}
        </div>
      )
    })
  }

  // This following section will display the form that takes input from the user to update the data.
  return (
    <div className="relative h-full w-full px-6 py-4">
      <h2 className="text-emerald-600 text-2xl font-bold mb-3">Task Timeline</h2>
      <>
        {taskList()}
      </>
    </div>
  );
}
