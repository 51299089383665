import React, { useState } from "react";
import Select from 'react-select'
import { useCSAMemberStore } from "../../../dataStore/CSAMemberStore";

function HolidayRow(props) {
  const { member, id } = props;
  const [editing, setEditing] = useState(false);
  const options = [{val : "NORMAL"},{val : "TRANSFER"},{val : "DONATE"},{val : "SKIP"},{val : "NO_RESPONSE"}]
  const updateMember = useCSAMemberStore((state) => state.update);
  const saveMember = useCSAMemberStore((state) => state.save);
  const [saving, setSaving] = useState(false);

  function update(newHoliday) {
    updateMember(id, {holidays : {summer : newHoliday.val}});
  }

  async function saveEdit() {
    setSaving(true);

    await saveMember(id);

    setSaving(false);
    setEditing(false);
  }

  function boolObj(bool) { return {val : bool}}

  return (
    <tr className="hover:bg-gray-100" key={id}>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <h3 className="text-md font-bold">{member.name}</h3>
        <p>{member.phone}</p>
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <div className="flex gap-3 align-top">
          <div>
            <h3 className="text-md font-bold">{member.csa_tier.tierName}</h3>
            <p>{member.subscription.delivered ? "DELIVERED" : "PICKUP"} {member.subscription.salad_extra ? "+ SALAD" : ""}</p>
          </div>
        </div>
      </td>
      {saving ? (
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 flex gap-2 justify-between items-center">
          <p className="font-bold text-blue-500">Saving...</p>
        </td>
      ) : (
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 flex gap-2 justify-between items-center">
          {editing ? (
            <>
              <Select 
                value={boolObj(member.holidays.summer)}
                options={options}
                getOptionLabel={el => el.val}
                getOptionValue={el => el.val }
                onChange={update}
                isDisabled={saving}
              />

              <div className="flex gap-1">
                <button
                  type="button"
                  className="rounded text-white text-sm bg-emerald-500 hover:bg-emerald-600 px-2 py-1 cursor-pointer flex items-center justify-center"
                  onClick={saveEdit}
                >
                  Save
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-md font-bold">{member.holidays?.summer}</h3>
            
              <button
                type="button"
                className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
                onClick={() => {setEditing(true)}}
              >
                Edit
              </button>
            </>
          )}
        </td>
      )}
    </tr>
  )
}

export default function CSAHolidayView(props) {
  const { members } = props;

  function tableRow(id, member) {
    
  }

  let delivered = [];
  for (let [memberId, member] of Object.entries(members)) {
    if (member.status.active && member.status.subscribed && !member.status.renewing && !member.status.invited) {
      delivered.push(<HolidayRow id={memberId} member={member} />);
    }
  }

  function CountTable() {
    let count = [0,0,0,0,0];
    for (let [memberId, member] of Object.entries(members)) {
      if (member.status.active && member.status.subscribed && !member.status.renewing && !member.status.invited) {
        let response = member.holidays?.summer;
        switch (response) {
          case "NORMAL": 
            count[0]++;
            break;
          case "TRANSFER":
            count[1]++;
            break;
          case "DONATE":
            count[2]++;
            break;
          case "SKIP":
            count[3]++;
            break;
          case "NO_RESPONSE":
            count[4]++;
            break;
          default:
            count[4]++;
            break;
        }
      }
    }

    return (
      <div className="grid grid-cols-5 gap-4 rounded-lg bg-slate-200 text-center my-5">
        <div className="py-2 flex flex-col gap-1 justify-center align-middle">
          <h4 className="font-bold text-sm font-sans">NORMAL</h4>
          <h4 className="text-3xl font-sans text-blue-600">{count[0]}</h4>
        </div>
        <div className="py-2 flex flex-col gap-1 justify-center align-middle">
          <h4 className="font-bold text-sm font-sans">TRANSFER</h4>
          <h4 className="text-3xl font-sans text-blue-600">{count[1]}</h4>
        </div>
        <div className="py-2 flex flex-col gap-1 justify-center align-middle">
          <h4 className="font-bold text-sm font-sans">DONATE</h4>
          <h4 className="text-3xl font-sans text-blue-600">{count[2]}</h4>
        </div>
        <div className="py-2 flex flex-col gap-1 justify-center align-middle">
          <h4 className="font-bold text-sm font-sans">SKIP</h4>
          <h4 className="text-3xl font-sans text-blue-600">{count[3]}</h4>
        </div>
        <div className="py-2 flex flex-col gap-1 justify-center align-middle">
          <h4 className="font-bold text-sm font-sans">NO RESPONSE</h4>
          <h4 className="text-3xl font-sans text-blue-600">{count[4]}</h4>
        </div>
      </div>
    )
    
  }

  return (
    <div>
      <h2 className="font-bold text-lg">2023/24 Holiday Period</h2>

      {CountTable()}

      <SummaryTable members={members}></SummaryTable>

      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 mb-44">
                <thead>
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Subscription</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Holiday Preference</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {delivered}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function SummaryTable(props) {
  const { members } = props;
  let _summary = {
    small : {
      pickup: 0,
      delivered: 0,
      total: 0
    },
    large: {
      pickup: 0,
      delivered: 0,
      total: 0
    },
    salad: {
      total: 0,
      extra: 0,
      pickup_extra: 0,
      delivery_extra: 0,
      bagged: 0,
      weight: 0
    }
  }

  for (let [memberId, member] of Object.entries(members)) {
    let holidayState = (member.holidays?.summer === "NORMAL" || member.holidays?.summer === "TRANSFER");
    if (member.status.active && member.status.subscribed && holidayState && !member.status.renewing && !member.status.invited && !member.status.canceled) {
      let s = member.subscription;
      let t = member.csa_tier;
      if (t.tierCode === "SMALL") {
        if (s.delivered) {
          _summary.small.delivered++;
          _summary.salad.bagged++;
        } else {_summary.small.pickup++;}
        _summary.small.total++;
      } else if (t.tierCode === "LARGE") {
        if (s.delivered) {
          _summary.large.delivered++;
          _summary.salad.bagged++;
        } else {_summary.large.pickup++;}
        _summary.large.total++;
      }
      _summary.salad.total++;
      if (s.salad_extra) {
        _summary.salad.extra++;
        if (s.delivered) {
          _summary.salad.bagged++;
          _summary.salad.delivery_extra++;
        } else {_summary.salad.pickup_extra++;}
      }
    }
  }
  _summary.salad.weight = Math.round(((_summary.salad.total + _summary.salad.extra) * 0.17)*100)/100;
      
  return (
    <div className="flex flex-col mb-6">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"></th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">small</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">large</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">total</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">salad extra</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">pickup</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.small.pickup}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.pickup}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.pickup + _summary.small.pickup}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.salad.pickup_extra}</td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">delivery</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.small.delivered}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.delivered}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.delivered + _summary.small.delivered}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.salad.delivery_extra}</td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">totals</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.small.total}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.total}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.total + _summary.small.total}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.salad.extra}</td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">salad</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800" colSpan="3">{_summary.salad.total + _summary.salad.extra} salads - {_summary.salad.weight}kg ({_summary.salad.bagged} bagged)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}