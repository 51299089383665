import { create } from 'zustand'
import { produce } from 'immer';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/gapi`;
const cookies = new Cookies();

const useGoogleStore = create((set, get) => ({
  profile : null,
  loaded : false,
  fetchProfile : async () => {
    if (get().loaded) return true
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/profile?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    console.log(data);
    set({
      profile : data || null,
      loaded : true
    });
    return data;
  },
  beginOAuth : async () => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/connect?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    return data.URI;
  },
  OAuthCodeResponse : async (code) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/connect-user?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code : code
        }),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    console.log(data);
    return data.success;
  }
}))

export {useGoogleStore};