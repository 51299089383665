import React, { useState } from 'react';
import { FlowCard } from './flowCardElements';

export default function WeedingNode(props) {

  return (
    <FlowCard title="WEEDING">
      <div>
        <div className='grid grid-cols-2 gap-2'>
          <div>
            <label className='text-xs font-bold pl-1'>Weeder</label>
            <input className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'></input>
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>Weed Action</label>
            <input className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'></input>
          </div>
        </div>
      </div>
    </FlowCard>
  );
}