import { create } from 'zustand'
import { produce } from 'immer';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/farm`;
const cookies = new Cookies();

const useFarmStore = create((set, get) => ({
  farm : {},
  farms : {},
  loaded : false,
  updateFarm : (value) => {
    set(
      produce((state) => {
        state.farm = { ...get().farm, ...value }
      })
    )
  },
  saveFarm : async () => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().farm),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  fetchCSA : async () => {
    const response = await fetch(`${apiEndpoint}/open`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    set({
      farms : data || null,
      loaded : true
    });
    return data;
  },
  fetch : async () => {
    if (get().loaded) return true
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    set({
      farm : data || null,
      loaded : true
    });
  }
}))

export {useFarmStore};