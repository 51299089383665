import React, { useState } from 'react';

function TaskTiming(props) {
  return (
    <div className='grid grid-cols-1 gap-0 mb-2'>
      <div>
        <label className='text-xs font-bold pl-1'>TASK TIMING</label>
        <div className="flex gap-0">
          <input 
            className='py-2 px-2 flex-1 border-y border-l border-gray-200 rounded-l-md text-xs peer focus:border-emerald-500 focus:ring-emerald-500 outline-none'
          />
          <div 
            className='py-2 px-2 flex-0 border-y border-gray-200 bg-gray-200 text-center text-xs peer-focus:border-emerald-500 group-first:ring-emerald-500'
          >
            DAYS
          </div>
          <select 
            className='py-2 px-2 pr-9 flex-1 border-y border-r border-gray-200 rounded-r-md text-xs peer-focus:border-emerald-500 focus:border-emerald-500 focus:ring-emerald-500 outline-none'
          >
            <option value="DIRECT">Days Since Last Task</option>
            <option value="CELL_TRAY">Days Since Seeding</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export function FlowCard(props) {
  return (
    <div className="relative">
      <div className="relative border border-emerald-500 bg-white pt-0 py-0 px-0">
        <div className='bg-emerald-500 px-3'>
          <label className='text-xs font-bold m-0 text-white leading-[1.5rem]'>{props.title}</label>
        </div>
        <div className="px-3 py-2">
          <TaskTiming task={props.task} updateTask={props.updateTask} />
          {props.children}
        </div>
      </div>
    </div>
  )
}