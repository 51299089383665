import React from 'react';
import Select from 'react-select';
import { useCellTrayStore } from "../../dataStore/CellTrays";

function DirectSowForm(props) {
  const directSow = props.directSow;
  const updateDirectSow = props.updateDirectSow;

  return (
    <>
      <div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div className="col-span-2">
            <label className='text-xs font-bold pl-1'>SEEDER</label>
            <select 
              className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
              value={directSow.seeder}
              onChange={(e) => updateDirectSow({ seeder : e.target.value })}
            >
              <option value="JANG">JANG</option>
              <option value="EARTHWAY">Earthway</option>
              <option value="HAND">Hand</option>
              <option value="OTHER">Other</option>
            </select>
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>SEEDER PLATE</label>
            <select 
              className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
              value={directSow.seederPlate}
              onChange={(e) => updateDirectSow({seederPlate : e.target.value})}
            >
              <option value="A6">A6</option>
              <option value="AA6">AA6</option>
              <option value="C6">C6</option>
              <option value="C12">C12</option>
              <option value="B12">B12</option>
              <option value="BL12">BL12</option>
              <option value="G6">G6</option>
              <option value="G12">G12</option>
              <option value="R12">R12</option>
              <option value="R24">R24</option>
              <option value="Q6">Q6</option>
              <option value="Q12">Q12</option>
            </select>
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>SEEDER GEARS</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={directSow.seederGears}
              onChange={(e) => updateDirectSow({ seederGears : e.target.value })}
            />
          </div>

          <div>
            <label className='text-xs font-bold pl-1'>EXPECTED SEED RATE (g/m)</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={directSow.desiredGrams}
              onChange={(e) => updateDirectSow({ desiredGrams : e.target.value })}
            />
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>COVER</label>
            <select 
              className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
              value={directSow.cover}
              onChange={(e) => updateDirectSow({ cover : e.target.value })}
            >
              <option value="NONE">None</option>
              <option value="SHADE">Shade</option>
              <option value="MIKRO">Mikroclima</option>
              <option value="INSECT">Insect</option>
              <option value="FLEECE">Fleece</option>
              <option value="WEEDMAT">Weedmat</option>
              <option value="TARP">Tarp</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

function CellSowForm(props) {
  const transplant = props.transplantData;
  const updateTransplant = props.updateTransplant;
  const cellTrays = useCellTrayStore((state) => state.cellTrays);

  function _dsObjToSelectArr(obj) {
    let o = [];
    for (let [i, op] of Object.entries(obj)) {o.push(op)}
    return o;
  }

  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
      <div>
        <label className='text-xs font-bold pl-1'>TRAY</label>
        <Select 
          className='block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
          value={transplant.cellTray}
          options={_dsObjToSelectArr(cellTrays, "trayName")} 
          getOptionLabel={el => el.trayName}
          getOptionValue={el => el}
          onChange={(val) => updateTransplant({cellTray : val})}
        />
      </div>
      <div>
        <label className='text-xs font-bold pl-1'>SEEDS PER CELL</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={transplant.seedPerCell}
          onChange={(e) => updateTransplant({ seedPerCell : e.target.value })}
        />
      </div>
      <div>
        <label className='text-xs font-bold pl-1'>OVERSOW</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={transplant.oversow}
          onChange={(e) => updateTransplant({ oversow : e.target.value })}
        />
      </div>
      <div>
        <label className='text-xs font-bold pl-1'>COVER</label>
        <select 
          className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
          value={transplant.cover}
          onChange={(e) => updateTransplant({ cover : e.target.value })}
        >
          <option value="NONE">None</option>
          <option value="SHADE">Shade</option>
          <option value="MIKRO">Mikroclima</option>
          <option value="INSECT">Insect</option>
          <option value="FLEECE">Fleece</option>
          <option value="WEEDMAT">Weedmat</option>
          <option value="TARP">Tarp</option>
        </select>
      </div>
    </div>
  )
}

function SpacingForm(props) {
  const spacing = props.spacing;
  const updateSpacing = props.updateSpacing;

  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
      <div>
        <label className='text-xs font-bold pl-1'>ROWS</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={spacing.rows}
          onChange={(e) => updateSpacing({ rows : e.target.value })}
        />
      </div>
      <div>
        <label className='text-xs font-bold pl-1'>ON ROW SPACING</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={spacing.onRowSpacing}
          onChange={(e) => updateSpacing({ onRowSpacing : e.target.value })}
        />
      </div>
      <div>
        <label className='text-xs font-bold pl-1'>BETWEEN ROW SPACING</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={spacing.betweenRowSpacing}
          onChange={(e) => updateSpacing({ betweenRowSpacing : e.target.value })}
        />
      </div>
    </div>
  )
}

function TimingForm(props) {
  const timing = props.timing;
  const updateTiming = props.updateTiming;
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-2">
      <div>
        <label className='text-xs font-bold pl-1'>GERM TIME (DAYS)</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={timing.DTG}
          onChange={(e) => updateTiming({ DTG : e.target.value })}
        />
      </div>
      {(props.establishmentType === "TRANSPLANT") ? (
      <div>
        <label className='text-xs font-bold pl-1'>DAYS IN CELLS</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={timing.DIC}
          onChange={(e) => updateTiming({ DIC : e.target.value })}
        />
      </div>
      ) : (<></>)}
      <div>
        <label className='text-xs font-bold pl-1'>DAYS TO MATURITY</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={timing.DTM}
          onChange={(e) => updateTiming({ DTM : e.target.value })}
        />
      </div>
    </div>
  )
}

export default function EstablishmentCard(props) {
  const directSowData = props.profile.directSow;
  const transplantData = props.profile.transplant;
  const timingData = props.profile.timing;
  const spacingData = props.profile.spacing;

  function updateDirectSow(value) {
    props.onUpdate({ directSow : { ...directSowData, ...value }})
  }
  function updateTransplant(value) {
    props.onUpdate({ transplant : { ...transplantData, ...value }})
  }
  function updateTiming(value) {
    props.onUpdate({ timing : { ...timingData, ...value }})
  }
  function updateSpacing(value) {
    props.onUpdate({ spacing : { ...spacingData, ...value }});
  }

  return (
    <div>
      <div>
        <label className='text-xs font-bold pl-1'>Establishment Type</label>
        <select 
          value={props.profile.establishmentType}
          onChange={(e) => props.onUpdate({ establishmentType : e.target.value })}
          className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
        >
          <option value="DIRECT">Direct Sow</option>
          <option value="TRANSPLANT">Cell Tray</option>
        </select>
      </div>
      <div className='mt-3'>
        {(props.profile.establishmentType === "DIRECT") ?
          <DirectSowForm 
            directSow={directSowData}
            updateDirectSow={updateDirectSow}
          />
          : 
          <CellSowForm 
            transplantData={transplantData}
            updateTransplant={updateTransplant}
          />
        }
      </div>
      <hr className='mt-2' />
      <div className='mt-2'>
        <TimingForm
          establishmentType={props.profile.establishmentType}
          timing={timingData}
          updateTiming={updateTiming}
        />
      </div>
      <hr className='mt-2' />
      <div className='mt-2'>
        <SpacingForm
          spacing={spacingData}
          updateSpacing={updateSpacing}
        />
      </div>
    </div>
  );
}