import React, { useState, useEffect } from "react";
import { useCSAMemberStore } from "../../../dataStore/CSAMemberStore";
import { useCSATierStore } from "../../../dataStore/CSATierStore";
import { useCSAPeriodStore } from "../../../dataStore/CSAPeriodStore";
import Select from 'react-select'
import dayjs from "dayjs";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

export default function CSAMemberCard(props) {
  const {id, member, activePeriod, activePeriodId} = props;
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const periods = useCSAPeriodStore((state) => state.objects);
  const tiers = useCSATierStore((state) => state.objects);
  const updateMember = useCSAMemberStore((state) => state.update);
  const saveMember = useCSAMemberStore((state) => state.save);

  function toggleEditing(e) {
    setEditing(!editing);
  }

  async function saveChanges() {
    if (saving) return;
    setSaving(true);
    await saveMember(id);
    setSaving(false);
    setEditing(false);
  }

  if (editing) {
    return (
      <tr className="bg-gray-100 !border-t-2 !border-t-emerald-500 justify-start align-top" key={id}>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <h2 className="font-bold mb-2">{member.name} - Details</h2>
          <input
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Name"
            value={member.name}
            onChange={e => updateMember(id, {name : e.target.value})}
            disabled={saving}
          />
          <input
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Email"
            value={member.email}
            onChange={e => updateMember(id, {email : e.target.value})}
            disabled={saving}
          />
          <input
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Phone"
            value={member.phone}
            onChange={e => updateMember(id, {phone : e.target.value})}
            disabled={saving}
          />
          <textarea
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Address"
            value={member.address}
            onChange={e => updateMember(id, {address : e.target.value})}
            disabled={saving}
          />
          <textarea
            type="text"
            className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            placeholder="Member Delivery Instructions"
            value={member.subscription.delivery_instructions}
            onChange={e => updateMember(id, { subscription : { ...member.subscription, delivery_instructions : e.target.value }})}
            disabled={saving}
          />
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800" colSpan={2}>
          <EditSubscription 
            member={member} 
            activePeriodId={activePeriodId} 
            periods={periods}
            tiers={tiers}
            updateMember={updateMember}
            saving={saving}
          />
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 flex flex-col justify-center gap-3">
          <button 
            className="flex justify-center border border-blue-500 rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
            onClick={saveChanges}
          >
            {!saving ? "Save" : (
              <ArrowPathIcon width={24} height={24} className="text-blue-500 animate-spin-slow" />
            )}
          </button>
        </td>
      </tr>
    )
  }

  return (
    <tr className="hover:bg-gray-100 cursor-pointer" key={id} onClick={toggleEditing}>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <h3 className="text-md font-bold">{member.name}</h3>
        <p>{member.email}</p>
        <p>{member.phone}</p>
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <SubscriptionCard member={member} activePeriodId={activePeriodId} />
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <AccessCodeButton code={member.access_code} />
      </td>
      <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
        <InvitationCard member={member} activePeriodId={activePeriodId} />
      </td>
    </tr>
  )
}

function AccessCodeButton(props) {
  const { code } = props;
  const [msg, setMsg] = useState(code);

  function click(e) {
    e.stopPropagation();
    const URI = `${window.location.host}/csa/register/${code}`;
    navigator.clipboard.writeText(URI);
    setMsg("Copied!");
    setTimeout(() => setMsg(code), 1000);
  }

  return (
    <button 
      className="border border-transparent rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
      onClick={click}
    >
      <span className="text-inherit">{msg}</span>
    </button>
  )
}

function InvitationCard(props) {
  const member = props.member;
  const activePeriodId = props.activePeriodId;
  const [sendingInvite, setSendingInvite] = useState(false);
  const invite = useCSAMemberStore((state) => state.invite);

  const isActiveSub = member.csa_period?._id === activePeriodId;
  const isInvitedSub = member.invitation?.csa_period?._id === activePeriodId;

  async function doInvite(e) {
    e.stopPropagation();
    setSendingInvite(true);
    await invite(member._id, member.csa_period._id, activePeriodId);
    setSendingInvite(false);
  }

  return (
    <>
      {(!isActiveSub && !isInvitedSub) ? (
        <button 
          className="border border-blue-500 rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
          onClick={doInvite}
        >
          {!sendingInvite ? (
            <span className="text-inherit">Invite</span>
          ) : (
            <span className="text-blue-500">Sending Invite...</span>
          )}
        </button>
      ) : (<></>)}
    </>
  )
}

function EditSubscription(props) {
  const {member, activePeriodId, periods, tiers, updateMember, saving} = props;

  const paymentTerms = [{val:'WEEKLY'}, {val:'THREE PAYMENTS'}, {val:'ONE OFF'}];
  const paymentTermLabels = {'WEEKLY':'Weekly','THREE PAYMENTS':'Three','ONE OFF':'Upfront'}
  const deliveryRuns = [{val:'WED 1'},{val:'WED 2'},{val:'WED 3'},{val:'WED 4'},{val:'WED 5'},{val:'WED 6'}];
  const deliveryRunLabels = {'WED 1':'WED 1', 'WED 2':'WED 2', 'WED 3':'WED 3', 'WED 4':'WED 4', 'WED 5':'WED 5', 'WED 6':'WED 6'};
  const paymentMethods = [{val:'GC'}, {val:'BANK'}];
  const paymentMethodLabels = {'GC':'GoCardless','BANK':'Bank Transfer'}
  const activePillClass = "text-[10px] text-white rounded py-0 px-1 bg-blue-500";
  const inactivePillClass = "text-[10px] text-blue-500 rounded py-0 px-1 bg-white";

  function _dsObjToSelectArr(obj, labelKey) {
    let optionArray = [];
    for (let [optionId, option] of Object.entries(obj)) {
      optionArray.push(option)
    }
    return optionArray;
  }
  function boolObj(bool) { return {val : bool}}

  function setTier(tier) {
    updateMember(member._id, { csa_tier : tier, subscription : { ...member.subscription, box_size : tier.tierCode }})
  }
  function setPeriod(period) {
    updateMember(member._id, { csa_period : period })
  }

  function toggleStatus(setting) {
    let newStatus = { ...member.status };
    newStatus[setting] = !newStatus[setting];
    updateMember(member._id, { status : newStatus });
  }

  return (
    <div className="relative grid grid-cols-2 gap-3">
      <div>
        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Share Period</label>
          <Select 
            value={member.csa_period}
            options={_dsObjToSelectArr(periods, "periodName")} 
            getOptionLabel={el => el.periodName}
            getOptionValue={el => el._id}
            onChange={setPeriod}
            isDisabled={saving}
          />
        </div>
        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Share Size</label>
          <Select 
            value={member.csa_tier}
            options={_dsObjToSelectArr(tiers, "tierName")} 
            getOptionLabel={el => el.tierName}
            getOptionValue={el => el._id}
            onChange={setTier}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Extra Salad</label>
          <Select 
            value={boolObj(member.subscription?.salad_extra)}
            options={[{ val : true }, { val : false }]} 
            getOptionLabel={el => ((el.val === true) ? "YES" : "NO")}
            getOptionValue={el => { return el.val }}
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, salad_extra : val.val}})}}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Delivered</label>
          <Select 
            value={boolObj(member.subscription?.delivered)}
            options={[{ val : true }, { val : false }]} 
            getOptionLabel={el => ((el.val === true) ? "YES" : "NO")}
            getOptionValue={el => { return el.val }}
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, delivered : val.val}})}}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Delivery Run</label>
          <Select 
            value={boolObj(member.subscription?.delivery_run)}
            options={deliveryRuns} 
            getOptionLabel={el => deliveryRunLabels[el.val] }
            getOptionValue={el => el.val }
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, delivery_run : val.val}})}}
            isDisabled={saving}
          />
        </div>
      </div>

      <div>
        
        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Discounted</label>
          <Select 
            value={boolObj(member.subscription?.is_discounted)}
            options={[{ val : true }, { val : false }]} 
            getOptionLabel={el => ((el.val === true) ? "YES" : "NO")}
            getOptionValue={el => { return el.val }}
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, is_discounted : val.val}})}}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Payment Terms</label>
          <Select 
            value={boolObj(member.subscription?.payment_terms)}
            options={paymentTerms} 
            getOptionLabel={el => paymentTermLabels[el.val] }
            getOptionValue={el => el.val }
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, payment_terms : val.val}})}}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Payment Method</label>
          <Select 
            value={boolObj(member.subscription?.payment_method)}
            options={paymentMethods} 
            getOptionLabel={el => paymentMethodLabels[el.val]}
            getOptionValue={el => el.val }
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, payment_method : val.val}})}}
            isDisabled={saving}
          />
        </div>

        <div className="mb-3">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Delivery Area</label>
          <Select 
            value={boolObj(member.subscription?.delivery_area)}
            options={[{ val : "WAIRARAPA", l : "Wairarapa - WED" }, { val : "WELLINGTON", l : "Wellington - WED" }, { val : "LOWER_HUTT", l : "Lower Hutt - WED"}]} 
            getOptionLabel={el => { return el.val }}
            getOptionValue={el => { return el.val }}
            onChange={(val) => {updateMember(member._id, { subscription : { ...member.subscription, delivery_area : val.val}})}}
            isDisabled={saving}
          />
        </div>
      </div>

      <div className="pt-3 col-span-2 border-t-2">
        <h2 className="font-bold mb-2">Subscription Status</h2>
        <div className="flex gap-1">
          <span 
            className={(member.status.active) ? activePillClass : inactivePillClass}
            onClick={e => {toggleStatus("active")}}
          >
            ACTIVE
          </span>

          <span 
            className={(member.status.subscribed) ? activePillClass : inactivePillClass}
            onClick={e => {toggleStatus("subscribed")}}
          >
            SUBSCRIBED
          </span>

          <span 
            className={(member.status.invited) ? activePillClass : inactivePillClass}
            onClick={e => {toggleStatus("invited")}}
          >
            INVITED
          </span>

          <span 
            className={(member.status.renewing) ? activePillClass : inactivePillClass}
            onClick={e => {toggleStatus("renewing")}}
          >
            RENEWING
          </span>

          <span 
            className={(member.status.waitlisted) ? activePillClass : inactivePillClass}
            onClick={e => {toggleStatus("waitlisted")}}
          >
            WAITLISTED
          </span>

          <span 
            className={(member.status.archived) ? activePillClass : inactivePillClass}
            onClick={e => {toggleStatus("archived")}}
          >
            ARCHIVED
          </span>

          <span 
            className={(member.status.canceled) ? activePillClass : inactivePillClass}
            onClick={e => {toggleStatus("canceled")}}
          >
            CANCELED
          </span>
        </div>
      </div>
    </div>
  )
}

function SubscriptionCard(props) {
  const {member, activePeriodId} = props;
  let isActiveSub = member.csa_period?._id === activePeriodId;
  let inviteMsg = "";
  if (member.invitation.invitation_send) inviteMsg = "Invited";
  if (member.invitation.invitation_opened) inviteMsg = "Opened";
  if (member.invitation.invitation_accepted) inviteMsg = "Accepted";

  return (
    <div className="relative self-center">
      <div className="grid grid-cols-2">
        <div className="flex gap-3 align-top">
          <div>
            {isActiveSub ? (
              <div className="rounded-full w-3 h-3 bg-green-600 mt-2" />
            ) : (
              <div className="rounded-full w-3 h-3 bg-red-600 mt-2" />
            )}
          </div>
          <div>
            <h3 className="text-md font-bold">{member.csa_period?.periodName} - {member.csa_tier.tierName}</h3>
            <p><b>{member.subscription.payment_terms}{member.subscription.is_discounted ? " DISCOUNT" : ""}</b>  {member.subscription.delivered ? `DELIVERED ${member.subscription.delivery_area}` : "PICKUP"} {member.subscription.salad_extra ? "+ SALAD" : ""}</p>
          </div>
        </div>
        {(!member.invitation || !member.invitation.csa_period || !member.status.invited) ? (<></>) : (
        <div className="bg-emerald-400">
          <h3 className="text-md font-bold">{member.invitation.csa_period.periodName}</h3>
          <p>{inviteMsg}</p>
        </div>)}
        {(!member.status.canceled) ? (<></>) : (
        <div className="bg-red-400">
          <h3 className="text-md font-bold">CANCELED {dayjs(member.cancelation?.canelation_date).format("MMM DD")}</h3>
          <p className=" whitespace-pre-wrap">{member.cancelation?.message}</p>
        </div>)}
      </div>
      <div className="flex gap-1">
        { (member.status.active) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">ACTIVE</span>) : (<></>) }
        { (member.status.subscribed) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">SUBSCRIBED</span>) : (<></>) }
        { (member.status.invited) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">INVITED</span>) : (<></>) }
        { (member.status.renewing) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">RENEWING</span>) : (<></>) }
        { (member.status.waitlisted) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">WAITLISTED</span>) : (<></>) }
        { (member.status.archived) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">ARCHIVED</span>) : (<></>) }
        { (member.status.canceled) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">CANCELED</span>) : (<></>) }
      </div>
    </div>
  )
}