import { create } from 'zustand'
import { produce } from 'immer';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/species`;
const cookies = new Cookies();

function newSpecies(family, common, binomial, genus, speciesName) {
  return {
    commonName: common,
    binomialName : binomial,
    speciesType : "PLANT",
    taxonomy : {
      family : family,
      genus : genus,
      species : speciesName
    }
  }
}

const useSpeciesStore = create((set, get) => ({
  species : {},
  loaded : false,
  addSpecies : (family, common, binomial, genus, speciesName) => {
    const species = newSpecies(family, common, binomial, genus, speciesName);
    get().createSpecies(species);
  },
  updateSpecies : (id, value) => {
    set(
      produce((state) => {
        state.species[id] = { ...get().species[id], ...value }
      })
    )
  },
  deleteSpecies : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "DELETE"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    let cL = get().species;
    delete cL[id];
    set({
      species : { ...cL }
    })
    return true
  },
  saveSpecies : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().species[id]),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  fetch : async () => {
    if (get().loaded) return true;
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);

    set({
      species : id_linked,
      loaded : true
    });
    return true;
  },
  createSpecies : async (species) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(species),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    let cL = get().species;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      species : { ...cL }
    })
    return true;
  }
}))

export {useSpeciesStore};