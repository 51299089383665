import React, { useState } from "react";
import { useAuthStore } from "../../dataStore/auth";
import FormCard from "../../components/formElements/formCard";
import FormField from "../../components/formElements/formField";
import { NavLink } from "react-router-dom";

export default function RegisterPage(props) {
    const [form, setForm] = useState({
        firstName : "",
        lastName : "",
        email : "",
        password : "",
        farmName : "",
        farmCountry : ""
    });
    const Register = useAuthStore((state) => state.register);

    function updateForm(value) {
        return setForm((prev) => {
            const _new = { ...prev, ...value };
            return _new;
        });
    }

    async function onSubmit(e) {
        e.preventDefault();
        const registerResponse = await Register(form);
    }

    return (
        <div className="flex flex-col items-center justify-center align-middle px-10">
            <div className="w-full">
                <FormCard cardName="Register">
                    <div className="grid grid-cols-2 gap-2">
                        <FormField
                            name="FNAME"
                            title="First Name"
                            placeholder=""
                            hint={false}
                            onChange={(e) => {updateForm({firstName:e.target.value})}}
                        />
                        <FormField
                            name="LNAME"
                            title="Last Name"
                            placeholder=""
                            hint={false}
                            onChange={(e) => {updateForm({lastName:e.target.value})}}
                        />
                    </div>
                    <FormField
                        name="Email"
                        title="Email"
                        placeholder=""
                        type="email"
                        hint={false}
                        onChange={(e) => {updateForm({email:e.target.value})}}
                    />
                    <FormField
                        name="PASSWORD"
                        title="Password"
                        placeholder=""
                        type="password"
                        hint={false}
                        onChange={(e) => {updateForm({password:e.target.value})}}
                    />
                    <FormField
                        name="FARM_NAME"
                        title="Farm Name"
                        placeholder=""
                        hint={false}
                        onChange={(e) => {updateForm({farmName:e.target.value})}}
                    />
                    <FormField
                        name="COUNTRY"
                        title="Farm Country"
                        placeholder=""
                        hint={false}
                        onChange={(e) => {updateForm({farmCountry:e.target.value})}}
                    />
                    <div className="flex p-0 items-center">
                        <div className="flex-1">
                            <NavLink className="" to="/auth/login">
                                <p className="text-sm text-blue-500 hover:text-blue-600">Have an account? Login</p>
                            </NavLink>
                        </div>
                        <button
                            className="flex-0 inline-flex justify-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 w-1/3 2xl:w-1/4 text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-600"
                            type="submit"
                            onClick={onSubmit}
                        >
                            Register
                        </button>
                    </div>
                </FormCard>
            </div>
        </div>
    );
}
