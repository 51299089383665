import React, { useState, useEffect } from "react";
import { useFarmStore } from "../../dataStore/FarmStore";
import FormCard from "../../components/formElements/formCard";
import { FormField, FormSelect } from "../../components/formElements/formField";

export default function SalesRetailPage() {
  const farm = useFarmStore((state) => state.farm);
  const updateFarm = useFarmStore((state) => state.updateFarm);
  const saveFarm = useFarmStore((state) => state.saveFarm);

  return (
    <div className="absolute inset-0">
      <div className="grid grid-rows-planning-contain h-full">
        <div className="border-b-black border border-b-solid">
          <div className="flex">
            <div className="px-3 py-3">
              <h1 className="text-3xl text-blue-600">Farm Settings</h1>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 relative px-12 py-8 overflow-scroll">
          <div className="w-full lg:w-1/2 4xl:w-1/3">
            <form action="#">
              <div className="space-y-10">
                <FormCard cardName="Farm Details">
                  <FormField
                    name="FARM_NAME"
                    title="Farm Name"
                    placeholder="Johnny's Apple Farm"
                    value={farm?.details?.name}
                    onChange={(e) => {updateFarm({details : { name : e.target.value }})}}
                    hint={true}
                  />
                  <FormField
                    name="FARM_ADDRESS"
                    title="Farm Address"
                    placeholder="123 Farm Lane"
                    hint={false}
                  />
                </FormCard>

                <FormCard cardName="Units & Timezones">
                  <FormSelect
                    name="FARM_UNITS"
                    title="Farm Units"
                    placeholder="Unit Systems"
                    options={['Metric', 'Imperial']}
                    value={farm.units || ""}
                    hint={true}
                  />
                  <FormField
                    name="FARM_ADDRESS"
                    title="Farm Address"
                    placeholder="123 Farm Lane"
                    hint={false}
                  />
                </FormCard>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
