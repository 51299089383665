import React from 'react';
import { FlowCard } from './flowCardElements';

export default function CoverNode(props) {
  const task = props.task;

  return (
    <FlowCard title="CROP COVERS">
      <div>
        <div className='grid grid-cols-2 gap-2'>
          <div>
            <label className='text-xs font-bold pl-1'>COVER CHANGE TYPE</label>
            <select 
              className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
              value={task.movement}
              onChange={(e) => props.updateTask({ ...task, movement : e.target.value })}
            >
              <option value="ON">Put Cover On</option>
              <option value="OFF">Take Cover Off</option>
            </select>
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>COVER</label>
            <select 
              className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
              value={task.cover}
              onChange={(e) => props.updateTask({ ...task, cover : e.target.value })}
            >
              <option value="NONE">None</option>
              <option value="SHADE">Shade</option>
              <option value="MIKRO">Mikroclima</option>
              <option value="INSECT">Insect</option>
              <option value="FLEECE">Fleece</option>
              <option value="WEEDMAT">Weedmat</option>
              <option value="TARP">Tarp</option>
            </select>
          </div>
        </div>
      </div>
    </FlowCard>
  );
}