import React, {useState, useEffect} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormCard from "../../components/formElements/formCard";
import { useGoogleStore } from "../../dataStore/GoogleStore";

export default function ConnectGoogle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [code, setCode] = useState();
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();
  const returnCode = useGoogleStore((state) => state.OAuthCodeResponse);

  async function sendCode(code) {
    const success = await returnCode(code);
    if (success) {
      navigate("/app/sales/settings");
    } else {
      alert("an error has occured, you may need to try again");
      navigate("/app/sales/settings");
    }
  }

  useEffect(() => {
    if (sending) return;
    const _c = searchParams.get("code");
    if (_c && _c.length > 10) {
      setSending(true);
      sendCode(_c);
    }
  }, [searchParams])

  return (
    <>
      <div className="fixed top-0 w-full z-50"><div className="barLoader"></div></div>

      <div className="flex flex-col items-center justify-center align-middle px-10">
        <div className="w-full">
          <FormCard cardName="">
            <h2 className="text-2xl font-semibold mb-3">Connecting your gmail account to radicchio</h2>
            <p>Please wait to be redirected</p>
          </FormCard>
        </div>
      </div>
    </>
  )
}