import React, { useState } from 'react';

export default function HarvestCard(props) {
  const harvestData = props.profile.harvest;

  return (
    <div>
      <div>

        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <label className='text-xs font-bold pl-1'>HARVEST UNIT</label>
            <select 
              onChange={(e) => props.onUpdate({ harvest : { harvestUnit : e.target.value }})}
              className='py-2 px-2 pr-9 block w-full border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500'
            >
              <option value="BUNCH">Bunch</option>
              <option value="EACH">Each</option>
              <option value="KG">Weight</option>
            </select>
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>UNITS / M</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={harvestData.harvestFrequency}
              onChange={(e) => props.onUpdate({ harvest : { harvestFrequency : e.target.value }})}
            />
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>HARVEST PERIOD</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={harvestData.harvestPeriod}
              onChange={(e) => props.onUpdate({ harvest : { harvestPeriod : e.target.value }})}
            />
          </div>
          <div>
            <label className='text-xs font-bold pl-1'>HARVEST FREQUENCY</label>
            <input
              className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
              value={harvestData.harvestFrequency}
              onChange={(e) => props.onUpdate({ harvest : { harvestFrequency : e.target.value }})}
            />
          </div>
        </div>
      </div>
    </div>
  );
}