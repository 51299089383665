import React, { useState } from "react";
import { useAuthStore } from "../../dataStore/auth";
import { useNavigate } from "react-router";

import FormCard from "../../components/formElements/formCard";
import FormField from "../../components/formElements/formField";
import { NavLink } from "react-router-dom";

export default function LoginPage(props) {
    const navigate = useNavigate();
    const Login = useAuthStore((state) => state.login);

    const [form, setForm] = useState({
        email : "",
        password : ""
    });

    function updateForm(value) {
        return setForm((prev) => {
            const _new = { ...prev, ...value };
            return _new;
        });
    }

    async function onSubmit(e) {
        if (e) e.preventDefault();
        const loginResponse = await Login(form);
        if (loginResponse.token && loginResponse.user) {
            navigate("/app/sales/csa");
        } else {
            alert("Error loggin in");
        }
    }

    return (
        <div className="flex flex-col items-center justify-center align-middle px-10">
            <div className="w-full">
                <FormCard cardName="Login">
                    <FormField
                        name="EMAIL"
                        title="Email"
                        placeholder=""
                        type="email"
                        hint={false}
                        label={true}
                        onChange={(e) => {updateForm({email : e.target.value})}}
                    />
                    <FormField
                        name="PASSWORD"
                        title="Password"
                        placeholder=""
                        type="password"
                        hint={false}
                        label={true}
                        onChange={(e) => {updateForm({password : e.target.value})}}
                    />
                    <div className="flex items-center gap-3 p-0">
                        <NavLink to="/auth/register">
                            <a className="text-sm text-blue-500 hover:text-blue-600 cursor-pointer pr-6">Register</a>
                        </NavLink>
                        <NavLink to="/auth/forgot">
                            <a className="text-sm text-blue-500 hover:text-blue-600 cursor-pointer">Forgot?</a>
                        </NavLink>
                        <div className="flex-1"></div>
                        <button
                            className="inline-flex justify-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 w-1/3 2xl:w-1/4 text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-600"
                            type="submit"
                            onClick={onSubmit}
                        >
                            Login
                        </button>
                    </div>
                </FormCard>
            </div>
        </div>
    );
}
