import React, { useState } from 'react';
import { FlowCard } from './flowCardElements';

export default function TaskNode(props) {
  const taskData = props.taskData || {};

  return (
    <FlowCard title="CROP TASK" task={taskData} updateTask={props.updateTask}>
      <div>
        <label className='text-xs font-bold pl-1'>Task Title</label>
        <input
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={taskData.taskTitle}
          // onChange={(e) => props.onUpdate({ harvest : { harvestPeriod : e.target.value }})}
        />
        <label className='text-xs font-bold pl-1'>Task Description</label>
        <textarea
          className='py-2 px-2 pr-9 block w-full border border-gray-200 rounded-md text-xs focus:border-emerald-500 focus:ring-emerald-500' 
          value={taskData.taskDescription}
          // onChange={(e) => props.onUpdate({ harvest : { harvestPeriod : e.target.value }})}
        />
      </div>
    </FlowCard>
  );
}