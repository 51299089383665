import React, { CSSProperties } from 'react';

import { useCSVReader } from 'react-papaparse';

export default function CSVReader(props) {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={props.onFinish}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }) => (
        <>
          <div className="border border-dashed rounded px-2 py-2">
            <button className="text-white px-2 rounded bg-blue-500 hover:bg-blue-600 cursor-pointer flex items-center justify-center" type='button' {...getRootProps()}>
              Browse file
            </button>
            <div>
              {acceptedFile && acceptedFile.name}
            </div>
            <button className="text-sm text-sky-600 hover:text-sky-700" {...getRemoveFileProps()}>
              Remove
            </button>
          </div>
          <ProgressBar />
        </>
      )}
    </CSVReader>
  )
}