import React, { useState, useCallback, useEffect } from "react";
import {useCropProfileStore} from "../../dataStore/ProfileStore";
import {useSpeciesStore} from "../../dataStore/SpeciesStore";
import { useCellTrayStore } from "../../dataStore/CellTrays";
import CSVReader from "../csvUploader";
import FormCard from "../formElements/formCard";

export default function ProfileImportModal(props) {
  const loadSpecies = useSpeciesStore((state) => state.fetch);
  const species = useSpeciesStore((state) => state.species);
  const [flatSpecies, setFlatSpecies] = useState([]);

  const loadCellTrays = useCellTrayStore((state) => state.fetch);
  const cellTrays = useCellTrayStore((state) => state.cellTrays);
  const [flatTrays, setFlatTrays] = useState([]);
  
  const addProfile = useCropProfileStore((state) => state.addProfile);
  const [loaded, setLoaded] = useState(false);
  const [view, setView] = useState("UPLOAD");
  const [csvData, setCSVData] = useState([]);
  const [csvHeader, setCSVHeader] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadCellTrays();
      await loadSpecies();
      setFlatSpecies(flatten(species));
      setFlatTrays(flatten(cellTrays));
      setLoaded(true)
    }
    if (!loaded) fetchData();
  }, [loaded, species, cellTrays]);

  async function createProfile() {
    let newId = await addProfile();
  }
  function flatten(options) {
    let list = [];
    for (let [optionId, option] of Object.entries(options)) {list.push(option)}
    return list;
  }

  function ep(d) {
    let a = [];
    for (let i = 27;i!==51;i++) a.push(parseInt(d[i])||0);
    return a;
  }
  function findSpecies(f) {
    let j = false;
    flatSpecies.forEach(s => {if (s.commonName === f) j = s;})
    if (j === null) {
      console.log("FIND SPECIES:", f);
    } else {
      console.log("SF");
    }
    return j || null;
  }
  function findTray(f) {
    let j = false;
    flatTrays.forEach(t => {if (t.trayName === f) j = t;});
    return j || null;
  }

  function arrayToProfile(dataArray) {
    let speciesName = dataArray[0];
    let trayName = dataArray[19];

    let species = findSpecies(speciesName);
    let tray = findTray(trayName);
    return {
      species : species || null,
      variationName : dataArray[1],
  
      establishmentType : dataArray[2].toUpperCase(),
      cropType : dataArray[3].toUpperCase(),
  
      directSow : {
        seeder : dataArray[13],
        seederPlate : dataArray[14],
        seederGears : dataArray[15],
        seederOther : dataArray[16],
        cover : dataArray[17],
        desiredGrams : parseInt(dataArray[18]) || 0
      },
  
      transplant : {
        cellTray : tray || null,
        seedPerCell : parseInt(dataArray[20]) || 0,
        oversow : parseInt(dataArray[21]) || 0,
        cover : dataArray[22]
      },
  
      spacing : {
        rows : parseInt(dataArray[4]) || 0,
        onRowSpacing : parseInt(dataArray[5]) || 0,
        betweenRowSpacing : parseInt(dataArray[6]) || 0,
        plantsPerMeter : parseInt(dataArray[7]) || 0,
      },
  
      harvest : {
        harvestUnit : dataArray[23],
        harvestPeriod : parseInt(dataArray[24]) || 0,
        harvestFrequency : parseInt(dataArray[25]) || 0,
        unitsPerLength : parseInt(dataArray[26]) || 0,
        yeildLength : 1,
      },
  
      timing : {
        DTG : parseInt(dataArray[8]) || 7,
        DIC : parseInt(dataArray[9]) || 0,
        DTM : parseInt(dataArray[10]) || 56,
        DIF : parseInt(dataArray[12]) || 63,
        DOM : parseInt(dataArray[11]) || 14,
      },
  
      establishmentPeriod : ep(dataArray),
  
      tasks : []
    }
  }

  function updateData(results) {
    setCSVHeader(results.data.shift(1));
    setCSVData(results.data);
    
    let __profiles = results.data.map(row => arrayToProfile(row));

    setView("SAVING");

    __profiles.forEach(profile => {
      addProfile(profile);
    })
  }

  function csvUploader() {
    return (
      <CSVReader onFinish={updateData} />
    )
  }

  function getView() {
    switch (view) {
      case "UPLOAD":
        return (csvUploader());
      case "SAVING":
        return (<p>SAVING...</p>)
    }
  }

  return (
    <div className="absolute inset-0 z-50 px-20 py-16">
      <div className="fixed inset-0 bg-slate-900 opacity-50 z-40"></div>

      <div className="relative rounded bg-white px-4 py-2 z-50 h-full overflow-x-hidden overflow-y-scroll">
        <h1>Import Crop Profiles</h1>

        {getView()}
      </div>      
    </div>
  );
}
