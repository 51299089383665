import React from "react"
import ContentLoader from "react-content-loader"

const SmallSectionLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={340}
    height={84}
    viewBox="0 0 340 84"
    backgroundColor="#f3f3f3"
    foregroundColor="#6ee7b7"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" /> 
    <rect x="76" y="0" rx="3" ry="3" width="140" height="11" /> 
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" /> 
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" /> 
    <rect x="18" y="48" rx="3" ry="3" width="100" height="11" /> 
    <rect x="0" y="71" rx="3" ry="3" width="37" height="11" /> 
    <rect x="18" y="23" rx="3" ry="3" width="140" height="11" /> 
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
  </ContentLoader>
)

const MediumFormLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={520}
    height={150}
    viewBox="0 0 520 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#6ee7b7"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="67" height="11" /> 
    <rect x="0" y="20" rx="3" ry="3" width="163" height="15" /> 
    <rect x="190" y="0" rx="3" ry="3" width="67" height="11" /> 
    <rect x="190" y="55" rx="3" ry="3" width="67" height="11" /> 
    <rect x="0" y="75" rx="3" ry="3" width="163" height="15" /> 
    <rect x="0" y="55" rx="3" ry="3" width="67" height="11" /> 
    <rect x="190" y="20" rx="3" ry="3" width="163" height="15" /> 
    <rect x="190" y="75" rx="0" ry="0" width="163" height="15" /> 
    <rect x="193" y="110" rx="0" ry="0" width="67" height="11" /> 
    <rect x="190" y="130" rx="0" ry="0" width="163" height="15" /> 
    <rect x="0" y="130" rx="0" ry="0" width="163" height="15" /> 
    <rect x="0" y="110" rx="0" ry="0" width="67" height="11" />
  </ContentLoader>
)

export { SmallSectionLoader, MediumFormLoader }

