import { create } from 'zustand'
import { produce } from 'immer';
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/croppingLocation`;
const cookies = new Cookies();

function newCroppingLocation(locationType, parent, isPlantable) {
  return {
    presave : Math.random().toString(12).substring(12),
    locationType : locationType,
    parent : parent ? parent : null,
    isPlantable : isPlantable,
    name : `New ${locationType}`,
    size : {
      length : 20,
      width: 0.75,
      sizeUnit : 'm'
    }
  }
}

const useCroppingLocationStore = create((set, get) => ({
  croppingLocations : {},
  addField : () => {
    const newField = newCroppingLocation("FIELD", '', false);
    get().createLocation(newField);
  },
  updateField : (id, value) => {
    set(
      produce((state) => {
        state.croppingLocations[id] = { ...get().croppingLocations[id], ...value }
      })
    )
  },
  addBlock : (parent) => {
    const newBlock = newCroppingLocation("BLOCK", parent, false);
    get().createLocation(newBlock);
  },
  addBed : (parent) => {
    const newBed = newCroppingLocation("BED", parent, false);
    get().createLocation(newBed);
  },
  deleteLocation : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "DELETE"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    let cL = get().croppingLocations;
    delete cL[id];
    set({
      croppingLocations : { ...cL }
    })
  },
  saveLocation : async (id) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}/${id}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(get().croppingLocations[id]),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    return true;
  },
  fetch : async () => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "GET"
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
  
    const data = await response.json();
    let id_linked = {};
    data.forEach(node => id_linked[node._id]=node);

    set({
      croppingLocations : id_linked
    });
  },
  createLocation : async (location) => {
    const token = cookies.get('token');
    const response = await fetch(`${apiEndpoint}?access_token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(location),
    }).catch(err => {

    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    let cL = get().croppingLocations;
    cL = JSON.parse(JSON.stringify(cL));
    cL[data._id] = data;
    set({
      croppingLocations : { ...cL }
    })
  }
}))

export {useCroppingLocationStore};