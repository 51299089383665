import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { FormField } from "../../../components/formElements/formField";
import { useFarmStore } from "../../../dataStore/FarmStore";
import { useCSAMemberStore } from "../../../dataStore/CSAMemberStore";
import { useCSATierStore } from "../../../dataStore/CSATierStore";
import { useCSAPeriodStore } from "../../../dataStore/CSAPeriodStore";
import { SmallSectionLoader, MediumFormLoader } from "../../../components/loaders";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { CheckBadgeIcon, BackspaceIcon } from "@heroicons/react/24/outline";
import { useOneOffStore } from "../../../dataStore/OneOffStore";

export default function NewOneOffForm(props) {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const getFarms = useFarmStore((state) => state.fetchCSA);
  const farms = useFarmStore((state) => state.farms);
  
  const fetchTiers = useCSATierStore((state) => state.fetch);
  const tiers = useCSATierStore((state) => state.objects);
  const registerMember = useOneOffStore((state) => state.add);
  
  const [address, setAddress] = useState('');
  const [registration, setRegistration] = useState({
    email: "",
    name: "",
    address : "",
    phone : "",
    csa_tier : null,
    csa_period : null,
    subscription : {
      box_size : "SMALL",
      delivered : false,
      salad_extra : false,
      is_discounted : false,
      payment_terms : "WEEKLY",
      payment_method : "GC",
    }
  });

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      await fetchTiers();

      setLoaded(true);
      setLoading(false);
    }

    if (!loaded) fetchData();
  })

  function dsObjToSelectArr(obj, labelKey) {
    let optionArray = [];
    for (let [optionId, option] of Object.entries(obj)) {
      optionArray.push({
        value : option,
        label : option[labelKey],
        __id__ : optionId
      })
    }
    return optionArray;
  }
  function _dsObjToSelectArr(obj, labelKey) {
    let optionArray = [];
    for (let [optionId, option] of Object.entries(obj)) {
      optionArray.push(option)
    }
    return optionArray;
  }
  function boolObj(bool) { return {val : bool}}

  async function register() {
    setSaving(true);
    await registerMember(registration);
    props.onClose(false);
  }

  function onAddressChange(address) {
    setAddress(address);
    setRegistration({ ...registration, address : address.value.description });
  }

  function setTier(tier) {
    setRegistration({ ...registration, csa_tier : tier, subscription : { ...registration.subscription, box_size : tier.tierCode }})
  }

  return (
    <div className="absolute inset-0 z-50 px-20 py-16">
      <div className="fixed inset-0 bg-slate-900 opacity-50 z-40"></div>

      <div className="relative rounded bg-white px-4 py-2 z-50 h-full overflow-x-hidden overflow-y-scroll">
        <div className="grid grid-cols-1 gap-10">
          <div className="fixed top-12 left-2 cursor-pointer text-blue-400 hover:text-blue-500">
            <BackspaceIcon onClick={e => props.onClose(false)} width="24" height="24" />
          </div>
        <div className="w-[700px] relative place-self-center">
          <h1 className="font-bold text-xl my-3">Create New CSA Member</h1>
          <h1 className="font-serif text-sm ml-6 mb-1 font-bold text-gray-500">SHARE DETAILS</h1>
          <div className="relative card border border-emerald-400 shadow-md bg-white px-5 py-1">
            {(!loaded || loading) ? (
            <div className="mx-2 my-4">
              <SmallSectionLoader />
            </div>) : (
            <>
            <div>
              <label className="inline-block mb-1 text-sm font-medium text-gray-700">Share Size</label>
              <Select 
                value={registration.csa_tier}
                options={_dsObjToSelectArr(tiers, "tierName")} 
                getOptionLabel={el => el.tierName}
                getOptionValue={el => el._id}
                onChange={setTier}
                isDisabled={saving}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 my-3">
              <div>
                <label className="inline-block mb-1 text-sm font-medium text-gray-700">Have share delivered ($7/week)</label>
                <Select 
                  value={boolObj(registration.subscription?.delivered)}
                  options={[{ val : true }, { val : false }]} 
                  getOptionLabel={el => ((el.val === true) ? "YES" : "NO")}
                  getOptionValue={el => { console.log(el); return el.val }}
                  onChange={(val) => {setRegistration({ ...registration, subscription : { ...registration.subscription, delivered : val.val}})}}
                  isDisabled={saving}
                />
              </div>

            </div>
            </>
            )}
          </div>
        </div>

        <div className="w-[700px] relative place-self-center">
          <h1 className="font-serif text-sm ml-6 mb-1 font-bold text-gray-500">YOUR DETAILS</h1>
          <div className="relative card border border-emerald-400 shadow-md bg-white px-5 py-1">
            {(loading) ? (
            <div className="mx-2 my-4">
              <MediumFormLoader />
            </div>
            ) : (
            <>
            <div className="grid grid-cols-2 gap-4">
              <FormField 
                name="CSA_NAME"
                placeholder="Joe Bloggs"
                title="Your Name"
                value={registration.name}
                label={true}
                onChange={(e) => setRegistration({ ...registration, name : e.target.value})}
                disabled={saving}
              />
              <FormField 
                name="CSA_EMAIL"
                placeholder="Joe Bloggs"
                title="Your Email"
                value={registration.email}
                label={true}
                onChange={(e) => setRegistration({ ...registration, email : e.target.value})}
                disabled={saving}
              />
              <FormField 
                name="CSA_PHONE"
                placeholder="Joe Bloggs"
                title="Your Phone Number"
                value={registration.phone}
                label={true}
                onChange={(e) => setRegistration({ ...registration, phone : e.target.value})}
                disabled={saving}
              />
              <></>
              <div className="col-span-2">
                <label className="inline-block mb-1 text-sm font-medium text-gray-700">Address</label>
                <GooglePlacesAutocomplete
                  selectProps={{
                    defaultInputValue: registration.address,
                    address,
                    onChange: onAddressChange,
                    isDisabled: saving
                  }}
                />
              </div>
            </div>
            <div className="mt-6 mb-3 flex items-center gap-3 p-0">
              <div className="flex-1"></div>
              <button
                className="inline-flex justify-center items-center py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 text-white bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus:ring-blue-600"
                disabled={loading || saving}
                onClick={register}
              >
                Create Member
                <CheckBadgeIcon className="text-white w-5 h-5 ml-4"></CheckBadgeIcon>
              </button>
            </div>
            </>)}
          </div>
        </div>
      </div>
      </div>      
    </div>
  );
}
