import React, { useState, useEffect } from "react";
import { useCSAPeriodStore } from "../../../dataStore/CSAPeriodStore";
import Select from 'react-select'
import dayjs from "dayjs";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

export default function CSAActivePeriodCard(props) {
  const period = props.period;
  const periods = useCSAPeriodStore((state) => state.objects);
  const transition = useCSAPeriodStore((state) => state.transition);
  const [loaded, setLoaded] = useState(false);
  const [newPeriod, setNewPeriod] = useState(null);

  const _NOW_ = dayjs();
  let meta = {
    daysLeftToEnd : dayjs(period.periodEnd).diff(_NOW_, 'days'),
    daysLeftToStart : dayjs(period.periodStart).diff(_NOW_, 'days'),
    startInFuture : dayjs().isBefore(dayjs(period.periodStart)),
    endInFuture : dayjs().isBefore(dayjs(period.periodEnd))
  }
  let needsUpdate = false;
  let isStarting = false;
  let isRunning = false;

  let mainBox = "relative border-t-8 px-3 py-2 mb-3 ";
  if (!meta.startInFuture && meta.daysLeftToEnd < 7) {
    mainBox += "border-red-500 bg-red-400";
    needsUpdate = true;
  }

  if (meta.startInFuture) {
    mainBox += "border-amber-500 bg-amber-400";
    isStarting = true;
  } 

  if (!meta.startInFuture && meta.endInFuture && meta.daysLeftToEnd > 14) {
    mainBox += "border-sky-500 bg-sky-400";
    isRunning = true;
  }

  function dsObjToSelectArr(obj, labelKey) {
    let optionArray = [];
    for (let [optionId, option] of Object.entries(obj)) {
      optionArray.push({
        value : option,
        label : option[labelKey],
        __id__ : optionId
      })
    }
    return optionArray;
  }

  async function doTransition() {
    await transition(period, newPeriod.value);
    window.location.reload();
  }

  function updateView() {
    return (
      <div>
        <p className="text-white text-lg">The current CSA Period is about to expire, select a new period to transition to</p>
        <div>
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Transition To:</label>
          <Select 
            value={newPeriod}
            options={dsObjToSelectArr(periods, "periodName")} 
            onChange={setNewPeriod}
          />

          <button
            className="inline-flex justify-center items-center my-3 py-2 px-4 opacity-100 bg-opacity-100 border border-transparent shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-1 focus:ring-offset-2 disabled:opacity-50 text-white bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus:ring-blue-600"
            onClick={doTransition}
          >
            Change Subscription Period
            <CheckBadgeIcon className="text-white w-5 h-5 ml-4"></CheckBadgeIcon>
          </button>
          <p className="text-white">This will change the current subscription period</p>
        </div>
      </div>
    )
  }

  function startingView() {
    return (
      <p>Your current members have been transitioned and emailed</p>
    )
  }

  function runningView() {
    return (
      <p>CSA Period is active</p>
    )
  }

  return (
    <div className={mainBox}>
      <h2>Current CSA Period: {period.periodName}</h2>
      {(needsUpdate) ? (updateView()) : (<></>)}
      {(isStarting) ? (startingView()) : (<></>)}
      {(isRunning) ? (runningView()) : (<></>)}
    </div>
  );
}
