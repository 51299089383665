import React, { useState, useEffect } from "react";
import { useCSAMemberStore } from "../../../dataStore/CSAMemberStore";
import { useCSAPeriodStore } from "../../../dataStore/CSAPeriodStore";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
const Cookies = require('universal-cookie').default;
const apiEndpoint = `${process.env.REACT_APP_API_URI}/gc`;
const cookies = new Cookies();

export default function SalesCSAAccountingPage() {
  const members = useCSAMemberStore((state) => state.objects);
  const fetchMembers = useCSAMemberStore((state) => state.fetch);
  const periods = useCSAPeriodStore((state) => state.objects);
  const fetchPeriods = useCSAPeriodStore((state) => state.fetch);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activePeriod, setActivePeriod] = useState({});
  const [activePeriodId, setActivePeriodId] = useState({});

  const [customers, setCustomers] = useState([]);
  const [mandates, setMandates] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [gcLoaded, setGCLoaded] = useState(false);

  function checkCurrentPeriod() {
    for (let [periodId, period] of Object.entries(periods)) {
      if (period.status.active) {
        setActivePeriod(period);
        setActivePeriodId(periodId);
      }
    }
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await fetchPeriods();
      await fetchMembers();
      checkCurrentPeriod();
      loadGC();
      setLoaded(true);
    }

    if (!loaded && !loading) loadData();
  });

  async function loadGC() {
    const token = cookies.get('token');
    const cust_res = await fetch(`${apiEndpoint}/customers?access_token=${token}`, {
        method: "GET"
    }).catch(err => {});
    const subs_res = await fetch(`${apiEndpoint}/subscriptions?access_token=${token}`, {
      method: "GET"
    }).catch(err => {});
    const mans_res = await fetch(`${apiEndpoint}/mandates?access_token=${token}`, {
      method: "GET"
    }).catch(err => {});
  

    const _customers = await cust_res.json();
    const _subscriptions = await subs_res.json();
    const _mandates = await mans_res.json();
    
    setCustomers(_customers.customers);
    setSubscriptions(_subscriptions.subscriptions);
    setMandates(_mandates.mandates);
    setGCLoaded(true);
  }

  function csaRows() {
    let rows = [];
    for (let [memberId, member] of Object.entries(members)) {
      if (member.status.active || member.status.subscribed || member.status.invited)
        rows.push(tableRow(memberId, member));
    }
    return rows;
  }

  function subscriptionCard(member) {
    let isActiveSub = member.csa_period?._id === activePeriodId;
    return (
      <>
        <div className="flex gap-3 align-top">
          <div>
            {isActiveSub ? (
              <div className="rounded-full w-3 h-3 bg-green-600 mt-2" />
            ) : (
              <div className="rounded-full w-3 h-3 bg-red-600 mt-2" />
            )}
          </div>
          <div>
            <h3 className="text-md font-bold">{member.csa_period?.periodName} - {member.csa_tier.tierName}</h3>
            <p>{member.subscription.delivered ? "DELIVERED" : "PICKUP"} {member.subscription.salad_extra ? "+ SALAD" : ""}</p>
          </div>
        </div>
        <div className="flex gap-1">
          { (member.status.active) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">ACTIVE</span>) : (<></>) }
          { (member.status.subscribed) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">SUBSCRIBED</span>) : (<></>) }
          { (member.status.invited) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">INVITED</span>) : (<></>) }
          { (member.status.renewing) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">RENEWING</span>) : (<></>) }
          { (member.status.waitlisted) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">WAITLISTED</span>) : (<></>) }
          { (member.status.archived) ? (<span className="text-[10px] text-white rounded py-0 px-1 bg-blue-500">ARCHIVED</span>) : (<></>) }
        </div>
      </>
    )
  }

  function tableRow(id, member) {
    return (
      <tr className="hover:bg-gray-100" key={id}>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <h3 className="text-md font-bold">{member.name}</h3>
          <p>{member.email}</p>
          <p className="font-bold">{member.subscription.payment_terms}</p>
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          {subscriptionCard(member)}
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <PaymentCard 
            member={member}
            gcLoaded={gcLoaded}
            customers={customers}
            subscriptions={subscriptions}
            mandates={mandates}
          />
        </td>
      </tr>
    )
  }

  function memberTable() {
    return (
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Details</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Current Subscription</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">GoCardless</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {csaRows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
    
  }

  return (
    <div className="">
      <div className="flex gap-4 items-center mb-3">
        <h2 className="text-lg flex-1">CSA MEMBER ACCOUNTING</h2>
      </div>

      <div>
        {memberTable()}
      </div>
    </div>
  );
}

function PaymentCard(props) {
  const { member, gcLoaded, customers, mandates, subscriptions } = props;
  const [fixing, setFixing] = useState(false);
  const [custId, setCustId] = useState("");
  const updateMember = useCSAMemberStore((state) => state.update);
  const saveMember = useCSAMemberStore((state) => state.save);

  let gcOK = false;
  let isBank = member.subscription.payment_method === "BANK";
  
  if (member.gocardless && member.gocardless?.customer_id?.length > 6
    && member.gocardless?.subscription_id?.length > 6
    && member.gocardless?.mandate_id?.length > 6
  ) gcOK = true;

  function findCustomer() {
    let id;
    customers.forEach(c => {if (c.email.toLowerCase() === member.email.toLowerCase()) id = c.id;})
    return id;
  }
  function findMandate(customerId) {
    let ma,ba;
    mandates.forEach(m => {
      if (m.links.customer === customerId) {
        ma = m.id;
        ba = m.links.customer_bank_account;
      }
    })
    return [ma,ba];
  }
  function findSubscription(mandateId) {
    let sub;
    subscriptions.forEach(s => {
      if (s.links.mandate === mandateId) sub = s.id;
    })
    return sub;
  }

  async function findGC() {
    if (fixing || !gcLoaded) return;
    setFixing(true);

    let __c = null;
    if (!member.gocardless?.customer_id?.length < 6) {
      if (custId.length > 6) __c = custId;
      else __c = findCustomer();
    } else {
      __c = member.gocardless.customer_id
    }

    if (!__c) {
      alert('no customer found');
      return;
    }
    
    let __m = null;
    let __ba = null
    if (!member.gocardless?.mandate_id?.length < 6) {
      [__m, __ba] = findMandate(__c);
    } else {
      __m = member.gocardless.mandate_id;
      __ba = member.gocardless.customer_bank_account;
    }

    if (!__m || !__ba) {
      alert('no mandate found');
      return;
    }

    let __s = null;
    __s = findSubscription(__m);

    if (!__s) {
      __s = "        _";
    }

    let gocardless = {
      mandate_id : __m,
      customer_id : __c,
      subscription_id : __s,
      customer_bank_account : __ba
    };

    updateMember(member._id, {gocardless : gocardless});
    await saveMember(member._id);
    setFixing(false);
  }

  if (isBank) {
    return (
      <div>
        <p>Bank Transfer Payments</p>
      </div>
    )
  }

  if (!gcOK) {
    return (
      <div>
        <p>Errors with GoCardless</p>

        {fixing ? (<></>) : (
          <>
            <button 
              className="flex justify-center border border-blue-500 rounded px-2 py-1 hover:border-blue-600 hover:text-blue-500"
              onClick={findGC}
            >
              {gcLoaded ? "Attempt to fix" : "Loading GoCardless"}
            </button>
            <input
              type="text"
              className="relative text-sm mb-2 z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
              placeholder="Customer ID"
              value={custId}
              onChange={e => setCustId(e.target.value)}
            />
          </>
        )}
      </div>
    )
  }
  
  return (
    <div>
      <p>GoCardless Customer: {member.gocardless?.customer_id?.length > 6 ? member.gocardless.customer_id : "No Customer"}</p>
      <p>Debit Mandate: {member.gocardless?.mandate_id?.length > 6 ? member.gocardless.mandate_id : "No Mandate"}</p>
      <p>GoCardless Subscription: {member.gocardless?.subscription_id?.length > 6 ? member.gocardless.subscription_id : "No Subscription"}</p>
      <p>Bank Account: {member.gocardless?.customer_bank_account?.length > 6 ? member.gocardless.customer_bank_account : "No Bank Account"}</p>
      <p>Mandate Request: {member.gocardless?.mandate_request_mandate?.length > 6 ? member.gocardless.mandate_request_mandate : "No Mandate Request"}</p>
    </div>
  )
}