import React, { useState, useCallback, useEffect } from "react";
import {useSpeciesStore} from "../../dataStore/SpeciesStore";
import { ArrowUpOnSquareStackIcon, CheckIcon } from "@heroicons/react/24/solid";
import FormCard from "../../components/formElements/formCard";
import CSVReader from "../../components/csvUploader";

export default function SpeciesPage() {
  const loadSpecies = useSpeciesStore((state) => state.fetch);
  const species = useSpeciesStore((state) => state.species);
  const addSpecies = useSpeciesStore((state) => state.addSpecies);
  const [loaded, setLoaded] = useState(false);
  const [view, setView] = useState("TABLE");
  const [csvData, setCSVData] = useState([]);
  const [csvHeader, setCSVHeader] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (loaded) return;
      await loadSpecies();
      setLoaded(true)
    }
    fetchData();
  }, [loaded, loadSpecies])

  function createSpecies() {
    csvData.forEach(async row => {
      await addSpecies(...row)
    })

    setView("TABLE");
  }

  function speciesRows() {
    let rows = [];
    for (let [speciesId, _species] of Object.entries(species)) {
      rows.push((
        <tr className="hover:bg-gray-100" key={speciesId}>
          <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_species.commonName}</td>
          <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_species.binomialName}</td>
          <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_species.taxonomy.genus}</td>     
        </tr>
      ))
    }

    return rows;
  }

  function speciesTable() {
    return (<div>
      <FormCard cardName="All Species">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead>
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Common Name</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Family</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Genus</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {speciesRows()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </FormCard>
    </div>)
  }

  function updateData(results) {
    setCSVHeader(results.data.shift(1));
    setCSVData(results.data);
    setView("CSV_VIEW");
  }

  function csvUploader() {
    return (
      <div className="w-full md:w-1/2">
        <FormCard cardName="CSV Upload">
          <CSVReader onFinish={updateData} />
        </FormCard>
      </div>
    )
  }

  function tableHeader() {
    let rows = csvHeader.map(el => {
      return (<th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{el}</th>)
                        
    })
    rows.push((<th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Action</th>));
    return rows;
  }

  function tableRow(data) {
    let rows = data.map(el => {
      return (<td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{el}</td>);
    })

    return (
      <tr className="hover:bg-gray-100">
        {rows}
        <td className="px-6 py-2 whitespace-nowrap text-right text-sm font-medium">
          <a className="text-blue-500 hover:text-blue-700" href="#">Delete</a>
        </td>
      </tr>
    )
  }

  function csvView() {
    return (
      <div>
        <FormCard cardName="CSV Data">
          <div className="absolute flex gap-2 top-6 right-6">
            <button
              type="button"
              onClick={createSpecies}
            >
              <CheckIcon className="text-blue-500 w-4 h-4 inline-block"/>
            </button>
          </div>
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead>
                      <tr>
                        {tableHeader()}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                      {csvData.map(el => { return tableRow(el) })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </FormCard>
      </div>
    )
  }

  function getView() {
    switch (view) {
      case "TABLE":
        return speciesTable();
      case "CSV_UPLOAD":
        return csvUploader();
      case "CSV_VIEW":
        return csvView();
      default:
        return csvUploader();
    }
  }

  return (
    <div className="absolute inset-0">
      <svg className="absolute z-0 inset-0 w-full h-full" data-testid="rf__background">
        <pattern id="pattern-1undefined" x="0.5" y="0" width="20" height="20" patternUnits="userSpaceOnUse" patternTransform="translate(-0.5,-0.5)">
          <circle cx="0.5" cy="0.5" r="0.5" fill="#91919a"></circle>
        </pattern>
        <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-1undefined)"></rect>
      </svg>
      <div className="relative grid grid-rows-planning-contain h-full z-10">
        <div className="border-b-black border border-b-solid bg-white">
          <div className="flex">
            <div className="px-3 py-3 flex-1">
              <h1 className="text-3xl text-blue-600">Species</h1>
            </div>
            <div>
              <button
                type="button"
                onClick={() => {setView("CSV_UPLOAD")}}
                className="py-3 px-3"
              >
                <ArrowUpOnSquareStackIcon className="text-blue-500 w-6 h-6 inline-block"/>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-transparent relative px-12 py-8 overflow-scroll">
          {getView()}
        </div>
      </div>
    </div>
  );
}
