import React, { useState, useEffect } from "react";
import { useFarmStore } from "../../dataStore/FarmStore";
import { useCSAMemberStore } from "../../dataStore/CSAMemberStore";
import { useCSAPeriodStore } from "../../dataStore/CSAPeriodStore";
import { useCSATierStore } from "../../dataStore/CSATierStore";
import { PlusIcon, FolderPlusIcon, FolderArrowDownIcon, CloudArrowDownIcon } from "@heroicons/react/24/solid";
import NewMemberForm from "./csa/newMemberForm";
import CSASettingsPage from "./csa/csaSettings";
import CSAMemberImport from "./csa/memberImport";
import CSAActivePeriodCard from "./csa/currentPeriod";
import SalesCSAAccountingPage from "./csa/csaAccounting";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import CSAMemberCard from "./csa/memberRow";
import CSAHolidayView from "./csa/csaHolidays";
import Select from 'react-select'
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useOneOffStore } from "../../dataStore/OneOffStore";
import OneOffCard from "./csa/oneOffRow";
import NewOneOffForm from "./csa/newOneOffForm";
import dayjs from "dayjs";
import swal from "sweetalert";
import { debounce } from "lodash";

function DeliveryView(props) {
  const { members } = props;
  const [area, setArea] = useState("ALL");

  function boolObj(bool) { return {val : bool}}
  function tableRow(id, member) {
    return (
      <tr className="hover:bg-gray-100" key={id}>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <h3 className="text-md font-bold">{member.name}</h3>
          <p>{member.phone}</p>
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <div className="flex gap-3 align-top">
            <div>
              <h3 className="text-md font-bold">{member.csa_tier.tierName}</h3>
              <p>{member.subscription.delivered ? "DELIVERED" : "PICKUP"} {member.subscription.salad_extra ? "+ SALAD" : ""}</p>
            </div>
          </div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <h3 className="text-md font-bold">{member.address}</h3>
          <p className="italic">{member.subscription.delivery_area}</p>
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
          <h3 className="text-md font-bold">{member.subscription.delivery_instructions}</h3>
        </td>
      </tr>
    )
  }

  function makeCSV() {
    let data = [];
    for (let [memberId, member] of Object.entries(members)) {
      if (member.status.active && member.status.subscribed && !member.status.renewing && !member.status.invited) {
        if (member.subscription.delivered) {
          if (area === "ALL" || member.subscription.delivery_area === area) {
            data.push({
              name: member.name,
              address: member.address,
              email: member.email,
              phone: member.phone,
              product : `${member.csa_tier.tierName} - Vagabond Vege`,
              box : member.csa_tier.tierName.toUpperCase(),
              run : member.subscription.delivery_run,
              instruction: member.subscription.delivery_instructions
            });
          }
        }
      }
    }
    const csvConfig = mkConfig({ useKeysAsHeaders: true });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  }

  let delivered = [];
  for (let [memberId, member] of Object.entries(members)) {
    if (member.status.active && member.status.subscribed && !member.status.renewing && !member.status.invited) {
      if (member.subscription.delivered) {
        if (area === "ALL" || member.subscription.delivery_area === area) {
          delivered.push(tableRow(memberId, member));
        }
      }
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex pl-4 gap-5 justify-between items-center">
        <div className="mb-3 min-w-[200px]">
          <label className="inline-block mb-1 text-sm font-medium text-gray-700">Delivery Area</label>
          <Select 
            value={boolObj(area)}
            options={[{ val : "ALL" }, { val : "WAIRARAPA" }, { val : "WELLINGTON" }, { val : "LOWER_HUTT"} ]} 
            getOptionLabel={el => ((el.val === "ALL") ? "ALL" : el.val)}
            getOptionValue={el => { return el.val }}
            onChange={(val) => {setArea(val.val)}}
          />
        </div>

        <div>
          <button
            type="button"
            className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={makeCSV}
          >
            Download Addresses
            <FolderArrowDownIcon className="text-white w-4 h-4 ml-2 inline-block"/>
          </button>
        </div>
      </div>
      
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Subscription</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Address</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Instructions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {delivered}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

function SummaryTable(props) {
  const { oneOffs, members, farm } = props;
  const updateFarm = useFarmStore(state => state.updateFarm);
  const saveFarm = useFarmStore(state => state.saveFarm);
  const [savingFarm, setSavingFarm] = useState(false);

  let _summary = {
    small : {
      pickup: 0,
      delivered: 0,
      wellington: 0,
      wairarapa: 0,
      lower_hutt: 0,
      total: 0
    },
    large: {
      pickup: 0,
      delivered: 0,
      wellington: 0,
      wairarapa: 0,
      lower_hutt: 0,
      total: 0
    },
    salad: {
      total: 0,
      extra: 0,
      pickup_extra: 0,
      delivery_extra: 0,
      bagged: 0,
      weight: 0
    }
  }
  let _oneoff = {
    small : {
      pickup: 0,
      wellington: 0,
      wairarapa: 0,
      lower_hutt: 0
    },
    large: {
      pickup: 0,
      wellington: 0,
      wairarapa: 0,
      lower_hutt: 0
    }
  }

  useEffect(() => {
    const doSave = setTimeout(async () => {
      if (savingFarm) return;
      setSavingFarm(true);
      await saveFarm();
      setSavingFarm(false);
    }, 500)

    return () => clearTimeout(doSave)
  }, [farm]);

  function updateAdditional(add_type, new_value) {
    let _addSalad = { ...farm.additionalSalad };
    _addSalad[add_type] = parseInt(new_value);
    _addSalad.total = _addSalad.pickup + _addSalad.delivery;

    updateFarm({ additionalSalad : { ..._addSalad } });
  }

  for (let [memberId, member] of Object.entries(members)) {
    if (member.status.active && member.status.subscribed && !member.status.renewing && !member.status.invited && !member.status.canceled) {
      let s = member.subscription;
      let t = member.csa_tier;
      if (t.tierCode === "SMALL") {
        if (s.delivered) {
          _summary.small.delivered++;
          _summary.salad.bagged++;
          _summary.small[s.delivery_area.toLowerCase()]++;
        } else {_summary.small.pickup++;}
        _summary.small.total++;
      } else if (t.tierCode === "LARGE") {
        if (s.delivered) {
          _summary.large.delivered++;
          _summary.salad.bagged++;
          _summary.large[s.delivery_area.toLowerCase()]++;
        } else {_summary.large.pickup++;}
        _summary.large.total++;
      }
      _summary.salad.total++;
      if (s.salad_extra) {
        _summary.salad.extra++;
        if (s.delivered) {
          _summary.salad.bagged++;
          _summary.salad.delivery_extra++;
        } else {_summary.salad.pickup_extra++;}
      }
    }
  }

  for (let [memberId, member] of Object.entries(oneOffs)) {
    if (member.status.archived === false && member.status.delivered === true) {
      let s = member.subscription;
      let t = member.csa_tier;

      if (t.tierCode === "SMALL") {
        if (s.delivered) {
          _summary.small.delivered++;
          _summary.salad.bagged++;
          _summary.small[s.delivery_area.toLowerCase()]++;
          _oneoff.small[s.delivery_area.toLowerCase()]++;
        } else {
          _summary.small.pickup++;
          _oneoff.small.pickup++;
        }
        _summary.small.total++;
      } else if (t.tierCode === "LARGE") {
        if (s.delivered) {
          _summary.large.delivered++;
          _summary.salad.bagged++;
          _summary.large[s.delivery_area.toLowerCase()]++;
          _oneoff.large[s.delivery_area.toLowerCase()]++;
        } else {
          _summary.large.pickup++;
          _oneoff.large.pickup++;
        }
        _summary.large.total++;
      }
      _summary.salad.total++;
    }
  }

  _summary.salad.total += farm.additionalSalad.total;
  _summary.salad.bagged += farm.additionalSalad.delivery;
  _summary.salad.weight = Math.round(((_summary.salad.total + _summary.salad.extra) * 0.17)*100)/100;
      
  return (
    <div className="flex flex-col mb-6">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"></th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">small</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">large</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">total</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">salad extra</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">pickup</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.small.pickup}
                      {_oneoff.small.pickup > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.small.pickup} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.large.pickup}
                      {_oneoff.large.pickup > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.large.pickup} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.pickup + _summary.small.pickup}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 flex gap-2 items-center">
                    {_summary.salad.pickup_extra}
                    <input 
                      className="rounded text-sm px-1 py-1 max-w-fit"
                      type="number" 
                      min={0} 
                      max={99}
                      value={farm.additionalSalad?.pickup}
                      onChange={e => updateAdditional("pickup", e.target.value)}
                    />
                  </td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">delivery</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.small.delivered}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.delivered}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.delivered + _summary.small.delivered}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 flex gap-2 items-center">
                    <span>{_summary.salad.delivery_extra}</span>
                    <input 
                      className="rounded text-sm px-1 py-1 max-w-fit"
                      type="number" 
                      min={0} 
                      max={99}
                      value={farm.additionalSalad?.delivery}
                      onChange={e => updateAdditional("delivery", e.target.value)}
                    />
                  </td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">wellington</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.small.wellington}
                      {_oneoff.small.wellington > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.small.wellington} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.large.wellington}
                      {_oneoff.large.wellington > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.large.wellington} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.wellington + _summary.small.wellington}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800"></td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">wairarapa</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.small.wairarapa}
                      {_oneoff.small.wairarapa > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.small.wairarapa} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.large.wairarapa}
                      {_oneoff.large.wairarapa > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.large.wairarapa} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.wairarapa + _summary.small.wairarapa}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800"></td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">lower hutt</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.small.lower_hutt}
                      {_oneoff.small.lower_hutt > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.small.lower_hutt} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">
                    <span>
                      {_summary.large.lower_hutt}
                      {_oneoff.large.lower_hutt > 0 ? (
                      <span className="text-white ml-1 px-1 text-sm bg-orange-300 rounded">
                        {_oneoff.large.lower_hutt} oneoff's
                      </span>
                      ) : (<></>)}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.lower_hutt + _summary.small.lower_hutt}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800"></td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">totals</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.small.total}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.total}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800">{_summary.large.total + _summary.small.total}</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800 flex gap-2 items-center">
                    {_summary.salad.extra}
                    <input 
                      className="rounded text-sm px-1 py-1 max-w-fit"
                      type="number" 
                      disabled={true}
                      value={farm.additionalSalad?.total}
                    />
                    <CloudArrowDownIcon className={savingFarm ? "text-blue-400" : "text-white"} width="24" height="24" />
                  </td>
                </tr>
                <tr className="hover:bg-gray-100">
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-bold text-right text-gray-800 uppercase">salad</td>
                  <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-800" colSpan="3">{_summary.salad.total + _summary.salad.extra} salads - {_summary.salad.weight}kg ({_summary.salad.bagged} bagged)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function SalesCSAPage() {
  const farm = useFarmStore((state) => state.farm);
  const fetchFarms = useFarmStore(state => state.fetch);
  const members = useCSAMemberStore((state) => state.objects);
  const fetchMembers = useCSAMemberStore((state) => state.fetch);
  const oneOffs = useOneOffStore((state) => state.objects);
  const fetchOneOffs = useOneOffStore((state) => state.fetch);
  const periods = useCSAPeriodStore((state) => state.objects);
  const fetchPeriods = useCSAPeriodStore((state) => state.fetch);
  const tiers = useCSATierStore((state) => state.objects);
  const fetchTiers = useCSATierStore((state) => state.fetch);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("MEMBERS");
  const [addingNew, setAddingNew] = useState(false);
  const [addingNewOneOff, setAddingNewOneOff] = useState(false);
  const [importingNew, setImportingNew] = useState(false);
  const [activePeriod, setActivePeriod] = useState({});
  const [activePeriodId, setActivePeriodId] = useState({});
  const [memberFilter, setMemberFilter] = useState("SUBSCRIBED"); //SUBSCRIBED, OTHER, ALL

  function checkCurrentPeriod() {
    for (let [periodId, period] of Object.entries(periods)) {
      if (period.status.active) {
        setActivePeriod(period);
        setActivePeriodId(periodId);
      }
    }
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await fetchPeriods();
      await fetchTiers();
      await fetchMembers();
      await fetchOneOffs();
      await fetchFarms();
      setLoaded(true);
    }

    if (!loaded && !loading) loadData();
  });

  useEffect(() => {
    if (periods && Object.keys(periods).length > 0 && loaded) {
      checkCurrentPeriod();
    }
  }, [periods, loaded])

  function oneOffRows() {
    let rows = [];
    for (let [memberId, member] of Object.entries(oneOffs)) {
      if (member.status.archived === false) {
        rows.push((
          <OneOffCard 
            id={memberId} 
            key={memberId}
            member={member} 
            activePeriod={activePeriod}
            activePeriodId={activePeriodId}
          />
        ));
      }
    }
    return rows;
  }

  function csaRows() {
    let rows = [];
    for (let [memberId, member] of Object.entries(members)) {
      let add = false;
      if (memberFilter === "SUBSCRIBED") {
        if (member.status.active && member.status.subscribed) { add = true; }
      } else if (memberFilter === "OTHER") {
        if (!member.status.active || !member.status.subscribed) { add = true; }
      } else if (memberFilter === "ALL") { add  = true; }
      
      if (add) {
        rows.push((
          <CSAMemberCard 
            id={memberId} 
            key={memberId}
            member={member} 
            activePeriod={activePeriod}
            activePeriodId={activePeriodId}
          />
        ));
      }
    }
    return rows;
  }

  function copyEmails() {
    let emailString = "";
    for (let [memberId, member] of Object.entries(members)) {
      if (member.status.active && member.status.subscribed && !member.status.renewing && !member.status.invited) {
        emailString += member.email + ';';
      }
    }
    navigator.clipboard.writeText(emailString);
    alert("Emails copied to clipboard");
  }

  function oneOffTable() {
    return (
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Details</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Box Size</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {oneOffRows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function memberTable() {
    return (
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead>
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Details</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Subscription</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Access Code</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Invitation</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {csaRows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function membersView() {
    return (
      <div className="">
        <SummaryTable oneOffs={oneOffs} members={members} farm={farm} />
        <CSAActivePeriodCard
          period={activePeriod}
        />

        <div className="flex gap-4 items-center mb-3">
          <h2 className="text-lg flex-1">ONE-OFF ORDERS</h2>
          
          <button
            type="button"
            className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={() => {setAddingNewOneOff(true)}}
          >
            Add Order
            <PlusIcon className="text-white w-4 h-4 ml-2 inline-block"/>
          </button>
        </div>

        <div>
          {oneOffTable()}
        </div>


        <div className="flex gap-4 items-center mb-3">
          <h2 className="text-lg flex-1">CSA MEMBERS</h2>
          <div className="flex">
            <button
              type="button"
              className={`rounded-l text-white text-xs px-4 py-1.5 cursor-pointer ${(memberFilter === "SUBSCRIBED") ? "bg-blue-500 hover:bg-blue-600" : "bg-slate-400 hover:bg-slate-500"}`}
              onClick={() => setMemberFilter("SUBSCRIBED")}
            >
              SUBSCRIBED
            </button>
            <button
              type="button"
              className={`text-white text-xs px-4 py-1.5 cursor-pointer ${(memberFilter === "OTHER") ? "bg-blue-500 hover:bg-blue-600" : "bg-slate-400 hover:bg-slate-500"}`}
              onClick={() => setMemberFilter("OTHER")}
            >
              OTHER
            </button>
            <button
              type="button"
              className={`rounded-r text-white text-xs px-4 py-1.5 cursor-pointer ${(memberFilter === "ALL") ? "bg-blue-500 hover:bg-blue-600" : "bg-slate-400 hover:bg-slate-500"}`}
              onClick={() => setMemberFilter("ALL")}
            >
              ALL
            </button>
          </div>
          <button
            type="button"
            className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={copyEmails}
          >
            Copy Active Emails
            <PaperClipIcon className="text-white w-4 h-4 ml-2 inline-block"/>
          </button>
          <button
            type="button"
            className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={() => {setAddingNew(true)}}
          >
            Add Member
            <PlusIcon className="text-white w-4 h-4 ml-2 inline-block"/>
          </button>
          {/* <button
            type="button"
            className="rounded text-white text-sm bg-blue-500 hover:bg-blue-600 px-2 py-1 cursor-pointer flex items-center justify-center"
            onClick={() => {setImportingNew(true)}}
          >
            Import Members
            <FolderPlusIcon className="text-white w-4 h-4 ml-2 inline-block"/>
          </button> */}
        </div>

        <div>
          {memberTable()}
        </div>
      </div>
    )
  }

  function getView() {
    switch (view) {
      case "MEMBERS":
        return membersView();
      case "SETTINGS":
        return (<CSASettingsPage />);
      case "ACCOUNTING":
        return (<SalesCSAAccountingPage />)
      case "DELIVERY":
        return (<DeliveryView members={members} />)
      case "HOLIDAYS":
        return (<CSAHolidayView members={members} />)
      default:
        return membersView();
    }
  }

  return (
    <div className="absolute inset-0">
      {(addingNew) ? (<NewMemberForm onClose={setAddingNew} />) : (<></>)}
      {(addingNewOneOff) ? (<NewOneOffForm onClose={setAddingNewOneOff} />) : (<></>)}
      {(importingNew) ? (<CSAMemberImport />) : (<></>)}
      <div className="grid grid-rows-planning-contain h-full">
        <div className="border-b-black border border-b-solid">
          <div className="flex gap-4 align-middle">
            <div className="px-3 py-2">
              <h1 className="text-3xl text-blue-600">Sales - Farm CSA</h1>
            </div>
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("MEMBERS")}}
              >
                CSA Members
              </button>
            </div>
            
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("SETTINGS")}}
              >
                CSA Settings
              </button>
            </div>
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("ACCOUNTING")}}
              >
                CSA Accounting
              </button>
            </div>
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("DELIVERY")}}
              >
                Delivery
              </button>
            </div>
            <div className="py-3">
              <button 
                type="button" 
                className="py-1.5 px-3.5 justify-center gap-2 rounded-md border-2 border-transparent font-semibold text-blue-500 hover:bg-slate-200 focus:outline-none transition-all text-xs"
                onClick={() => {setView("HOLIDAYS")}}
              >
                Holidays
              </button>
            </div>
          </div>
        </div>
        <div className="bg-transparent relative px-12 py-8 overflow-scroll">
          {loaded ? getView() : (<><p>loading</p></>)}
        </div>
      </div>
    </div>
  );
}
