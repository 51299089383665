import { useEffect, useRef, useState } from "react"
import { ChevronDownIcon } from '@heroicons/react/24/solid';

export function FormSelect(props) {
  const ref = useRef(null);
  const [showList, setShowList] = useState(false);
  const [option, setOption] = useState(props.value || false);
  const [value, setValue] = useState(props.value || "");
  const [options, setOptions] = useState(props.options);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ onClickOutside ]);

  function onClickOutside() {
    setShowList(false);
  }

  function flatten() {
    let list = [];
    for (let [optionId, option] of Object.entries(options)) {list.push(option)}
    return list;
  }
  function bulge(arr) {
    let opt = {};
    arr.forEach(a => {opt[a._id] = a});
    return opt;
  }

  function onChange(e) {
    setValue(e.target.value);
    if (e.target.value !== '') {
      let _options = props.options;
      let results;
      if (!Array.isArray(options)) {
        _options = flatten();
        results = _options.filter((option) => {
          return option[props.selector].toLowerCase().startsWith(value.toLowerCase());
        });
        results = bulge(results);
      } else {
        results = _options.filter((option) => {
          return option.toLowerCase().startsWith(value.toLowerCase());
        });
      }
      setOptions(results);
    } else {
      setOptions(props.options);
    }
    setShowList(true);
  }

  function setInputOption(option) {
    if (Array.isArray(options)) {
      setValue(option);
    } else {
      setValue(option[props.selector]);
    }
    
    setOption(option);
    setShowList(false);
    props.onSetValue(option);
  }
  
  function dropDown() {
    const activeClass = "inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-blue-600 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600";
    const mainClass = "inline-flex items-center gap-x-3.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg hover:text-blue-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600";

    let list = [];
    if (Array.isArray(options)) {
      list = options.map((opt, idx) => {
        return (
          <a 
            className={(opt === option) ? activeClass : mainClass} 
            onClick={() => {setInputOption(opt)}} 
            href="#" 
            key={idx}
          >
            {opt}
          </a>
        )
      })
    } else if (!Array.isArray(options)) {
      for (let [optionId, opt] of Object.entries(options)) {
        list.push(
          <a 
            className={(optionId === option._id) ? activeClass : mainClass} 
            onClick={() => {setInputOption(opt)}} 
            href="#"
            key={optionId}
          >
            {opt[props.selector]}
          </a>
        )
      }
    }
    
    return (
      <div className="absolute top-10 w-full max-h-96 overflow-scroll flex flex-col left-0 z-20">
        {list}
      </div>
    )
  }

  return (
    <div className="relative grid grid-cols-2 gap-y-4 gap-x-4 mb-4" ref={ref}>
      <div className="col-span-2">
        <label
          className="inline-block mb-1 text-sm font-medium text-gray-700"
          htmlFor={props.name}
        >
          {props.title}
        </label>
        
        <div className="group relative cursor-pointer">
          <input
            name={props.name}
            type={(props.type) ? props.type : "text"}
            placeholder={props.placeholder}
            value={value}
            onChange={onChange}
            onFocus={() => {setShowList(true)}}
            autoComplete="false"
            className="leading-none cursor-pointer focus:shadow-none focus:ring-blue-500 focus:border-blue-500 group-hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded"
            style={{ paddingTop: 9, paddingBottom: 9, boxShadow: "none" }}
          />

          <div className="absolute right-3 top-2.5">
            <ChevronDownIcon className="text-blue-500 h-5 w-5 group-hover:text-blue-700" />
          </div>
          { (showList) ? dropDown() : <></> }
        </div>
      </div>
    </div>
  )
}

export function FormField(props) {
  function hint() {
    return (
      <>
        <div
          className="text-sm text-gray-800 absolute pl-6 py-4 -ml-4 border-l border-gray-300 hidden 2xl:block"
          style={{ right: "-40rem", width: "35rem" }}
        >
        <div className="grid grid-cols-12">
          <div className="col-span-1">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="lightbulb-on"
              className="svg-inline--fa fa-lightbulb-on fa-w-20 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M320,64A112.14,112.14,0,0,0,208,176a16,16,0,0,0,32,0,80.09,80.09,0,0,1,80-80,16,16,0,0,0,0-32Zm0-64C217.06,0,143.88,83.55,144,176.23a175,175,0,0,0,43.56,115.55C213.22,321,237.84,368.69,240,384l.06,75.19a15.88,15.88,0,0,0,2.69,8.83l24.5,36.84A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.17,16.17,0,0,0,2.69-8.83L400,384c2.25-15.72,27-63.19,52.44-92.22A175.9,175.9,0,0,0,320,0Zm47.94,454.31L350.84,480H289.12l-17.06-25.69,0-6.31h95.91ZM368,416H272l-.06-32H368Zm60.41-145.31c-14,15.95-36.32,48.09-50.57,81.29H262.22c-14.28-33.21-36.6-65.34-50.6-81.29A143.47,143.47,0,0,1,176.06,176C175.88,99,236.44,32,320,32c79.41,0,144,64.59,144,144A143.69,143.69,0,0,1,428.38,270.69ZM96,176a16,16,0,0,0-16-16H16a16,16,0,0,0,0,32H80A16,16,0,0,0,96,176ZM528,64a16.17,16.17,0,0,0,7.16-1.69l64-32A16,16,0,0,0,584.84,1.69l-64,32A16,16,0,0,0,528,64Zm96,96H560a16,16,0,0,0,0,32h64a16,16,0,0,0,0-32ZM119.16,33.69l-64-32A16,16,0,0,0,40.84,30.31l64,32A16.17,16.17,0,0,0,112,64a16,16,0,0,0,7.16-30.31Zm480,288-64-32a16,16,0,0,0-14.32,28.63l64,32a16,16,0,0,0,14.32-28.63ZM112,288a16.17,16.17,0,0,0-7.16,1.69l-64,32a16,16,0,0,0,14.32,28.63l64-32A16,16,0,0,0,112,288Z"
              />
            </svg>
          </div>
          <div className="col-span-11">
            <p>
              You can pick any name to recognize your site later. The Client
              Key tells your SDK to which site to send the events to.
            </p>
          </div>
        </div>
        </div>
        <div
        className="text-sm text-gray-800 absolute pl-6 py-4 -ml-4 border-l border-gray-300 hidden xl:block 2xl:hidden"
        style={{ right: "-25rem", width: "21rem" }}
      >
        <div className="grid grid-cols-12">
          <div className="col-span-1">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="lightbulb-on"
              className="svg-inline--fa fa-lightbulb-on fa-w-20 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M320,64A112.14,112.14,0,0,0,208,176a16,16,0,0,0,32,0,80.09,80.09,0,0,1,80-80,16,16,0,0,0,0-32Zm0-64C217.06,0,143.88,83.55,144,176.23a175,175,0,0,0,43.56,115.55C213.22,321,237.84,368.69,240,384l.06,75.19a15.88,15.88,0,0,0,2.69,8.83l24.5,36.84A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.17,16.17,0,0,0,2.69-8.83L400,384c2.25-15.72,27-63.19,52.44-92.22A175.9,175.9,0,0,0,320,0Zm47.94,454.31L350.84,480H289.12l-17.06-25.69,0-6.31h95.91ZM368,416H272l-.06-32H368Zm60.41-145.31c-14,15.95-36.32,48.09-50.57,81.29H262.22c-14.28-33.21-36.6-65.34-50.6-81.29A143.47,143.47,0,0,1,176.06,176C175.88,99,236.44,32,320,32c79.41,0,144,64.59,144,144A143.69,143.69,0,0,1,428.38,270.69ZM96,176a16,16,0,0,0-16-16H16a16,16,0,0,0,0,32H80A16,16,0,0,0,96,176ZM528,64a16.17,16.17,0,0,0,7.16-1.69l64-32A16,16,0,0,0,584.84,1.69l-64,32A16,16,0,0,0,528,64Zm96,96H560a16,16,0,0,0,0,32h64a16,16,0,0,0,0-32ZM119.16,33.69l-64-32A16,16,0,0,0,40.84,30.31l64,32A16.17,16.17,0,0,0,112,64a16,16,0,0,0,7.16-30.31Zm480,288-64-32a16,16,0,0,0-14.32,28.63l64,32a16,16,0,0,0,14.32-28.63ZM112,288a16.17,16.17,0,0,0-7.16,1.69l-64,32a16,16,0,0,0,14.32,28.63l64-32A16,16,0,0,0,112,288Z"
              />
            </svg>
          </div>
          <div className="col-span-11">
            <p>
              You can pick any name to recognize your site later. The Client
              Key tells your SDK to which site to send the events to.
            </p>
          </div>
        </div>
        </div>
        <div
        className="text-sm text-gray-800 absolute pl-6 py-4 -ml-4 border-l border-gray-300 hidden sm:block xl:hidden"
        style={{ right: "-20rem", width: "15rem" }}
      >
        <div className="grid grid-cols-12">
          <div className="col-span-1">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="lightbulb-on"
              className="svg-inline--fa fa-lightbulb-on fa-w-20 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M320,64A112.14,112.14,0,0,0,208,176a16,16,0,0,0,32,0,80.09,80.09,0,0,1,80-80,16,16,0,0,0,0-32Zm0-64C217.06,0,143.88,83.55,144,176.23a175,175,0,0,0,43.56,115.55C213.22,321,237.84,368.69,240,384l.06,75.19a15.88,15.88,0,0,0,2.69,8.83l24.5,36.84A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.17,16.17,0,0,0,2.69-8.83L400,384c2.25-15.72,27-63.19,52.44-92.22A175.9,175.9,0,0,0,320,0Zm47.94,454.31L350.84,480H289.12l-17.06-25.69,0-6.31h95.91ZM368,416H272l-.06-32H368Zm60.41-145.31c-14,15.95-36.32,48.09-50.57,81.29H262.22c-14.28-33.21-36.6-65.34-50.6-81.29A143.47,143.47,0,0,1,176.06,176C175.88,99,236.44,32,320,32c79.41,0,144,64.59,144,144A143.69,143.69,0,0,1,428.38,270.69ZM96,176a16,16,0,0,0-16-16H16a16,16,0,0,0,0,32H80A16,16,0,0,0,96,176ZM528,64a16.17,16.17,0,0,0,7.16-1.69l64-32A16,16,0,0,0,584.84,1.69l-64,32A16,16,0,0,0,528,64Zm96,96H560a16,16,0,0,0,0,32h64a16,16,0,0,0,0-32ZM119.16,33.69l-64-32A16,16,0,0,0,40.84,30.31l64,32A16.17,16.17,0,0,0,112,64a16,16,0,0,0,7.16-30.31Zm480,288-64-32a16,16,0,0,0-14.32,28.63l64,32a16,16,0,0,0,14.32-28.63ZM112,288a16.17,16.17,0,0,0-7.16,1.69l-64,32a16,16,0,0,0,14.32,28.63l64-32A16,16,0,0,0,112,288Z"
              />
            </svg>
          </div>
          <div className="col-span-11">
            <p>
              You can pick any name to recognize your site later. The Client
              Key tells your SDK to which site to send the events to.
            </p>
          </div>
        </div>
        </div>
      </>
    )
  }

  let errorClass;
  let inputClass;
  if (props.showError) {
    errorClass = "z-0 absolute right-1 top-1.5 font-bold text-xs text-red-600 transition";
    inputClass = "relative z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-red-500 rounded"
  } else {
    errorClass = "z-0 absolute translate-y-6 right-1 top-1.5 font-bold text-xs text-red-600 transition";
    inputClass = "relative z-10 leading-none focus:shadow-none focus:ring-blue-500 focus:border-blue-500 hover:border-gray-400 text-gray-900 block w-full border-gray-300 rounded";
  }

  return (
    <div className="grid grid-cols-2 gap-y-4 gap-x-4 relative mb-0">
      <div className="col-span-2">
        {props.label ? (
          <label
            className="inline-block mb-1 text-sm font-medium text-gray-700"
            htmlFor={props.name}
          >
            {props.title}
          </label>
        ) : (<></>)}

        <label
          className={errorClass}
        >{props.errorMessage}</label>
        
        <input
          name={props.name}
          required=""
          type={(props.type) ? props.type : "text"}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          className={inputClass}
          style={{ paddingTop: 9, paddingBottom: 9, boxShadow: "none" }}
        />

      </div>
      
      { (props.hint) ? hint() : <></> }
    </div>
  )
}

export default FormField